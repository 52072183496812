import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Job } from '../job'
import { JobEdit } from '../edit'
import { CandidatesRoot } from '../candidates'

export const JobRoot = () => {
  return (
    <Routes>
      <Route index element={<Job />} />
      <Route path={'edit'} element={<JobEdit />} />
      <Route path="candidates/*" element={<CandidatesRoot />} />
      <Route path={'*'} element={<Navigate to={'..'} replace={true} />} />;
    </Routes>
  )
}

JobRoot.propTypes = {}
