import React from 'react'
import PropTypes from 'prop-types'
import { FormProvider } from 'react-hook-form'

import { useFilterForm } from 'features/filter/hooks'
import { FilterForm } from '../FilterForm'

export const GeneralStatisticsFilter = ({ defaultValues, onFiltersChange }) => {
  const { methods, isApplied, onReset } = useFilterForm({
    config: {
      defaultValues: {
        ...defaultValues,
      },
    },
    onFiltersChange,
    initialValues: {
      period: {
        type: 'month',
        range: [],
      },
    },
  })

  return (
    <FormProvider {...methods}>
      <FilterForm />
    </FormProvider>
  )
}

GeneralStatisticsFilter.propTypes = {
  onFiltersChange: PropTypes.func,
  defaultValues: PropTypes.object,
}
