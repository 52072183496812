import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: null,
  loading: false,
  error: null,
}

const jobJobStatisticsDataSlice = createSlice({
  name: 'jobJobStatisticsData',
  initialState,
  reducers: {
    getData(state) {
      state.loading = true
      state.error = null
    },
    getDataSuccess(state, action) {
      state.loading = false
      state.data = action.payload.data
    },
    getDataError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    updateData(state, action) {
      state.data = { ...state.data, ...action.payload }
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getData
 * @property {function} getDataSuccess
 * @property {function} getDataError
 * @property {function} updateData
 * @property {function} cleanState
 */
export const jobJobStatisticsData = jobJobStatisticsDataSlice.actions

export default jobJobStatisticsDataSlice.reducer
