import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { APP_NAME } from 'consts/core'

export const MegaTagTitle = ({ title }) => {
  const { t } = useTranslation()

  return (
    <Helmet>
      <title>{`${t(title)} | ${APP_NAME}`}</title>
    </Helmet>
  )
}

MegaTagTitle.propTypes = {
  title: PropTypes.string,
}
