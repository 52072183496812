import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './InfoBlock.module.scss'
import { TagBadge } from 'components/Elements'

export const InfoBlock = ({ data = {}, ...props }) => {
  const { t } = useTranslation()

  const { student_profile } = data

  const { skills } = student_profile || {}

  return skills && !!skills.length ? (
    <div className={'mt-2'}>
      <div className={'d-flex flex-wrap gap-1'}>
        {skills.map((item, i) => (
          <TagBadge key={i} size={'md'}>
            {item?.value}
          </TagBadge>
        ))}
      </div>
    </div>
  ) : null
}

InfoBlock.propTypes = {
  data: PropTypes.object,
}
