import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import s from './Bubble.module.scss'
import { Icon } from './Icon'

export const Bubble = ({ isQuestion, ...props }) => {
  const { t } = useTranslation()

  return (
    <div
      className={classNames('', {
        [`${s.block}`]: true,
        [`${s.primary}`]: isQuestion,
        [`${s.success}`]: !isQuestion,
      })}
    >
      <Icon>{isQuestion ? 'Q' : 'A'}</Icon>
    </div>
  )
}

Bubble.propTypes = {
  isQuestion: PropTypes.bool,
}
