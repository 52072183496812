import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { NoDataTextMessage } from 'components/Elements'

export const Education = ({ data = {} }) => {
  const { t } = useTranslation()

  const { school, school_year, profile_study_program, profile_study_area, profile_study_year } =
    data

  const renderItem = ({ label, value }) => (
    <div className={'d-flex flex-wrap'}>
      <span className={'fw-medium me-1'}>{label}:</span>
      {value ? value : <NoDataTextMessage variant={'fill'} />}
    </div>
  )

  const items = [
    {
      label: t('education_institution'),
      value: school?.value,
    },
    {
      label: t('graduate_year'),
      value: school_year,
    },
    {
      label: t('study_program'),
      value: profile_study_program?.value,
    },
    {
      label: t('area_of_study'),
      value: profile_study_area?.value,
    },
    {
      label: t('year_of_study'),
      value: profile_study_year?.value,
    },
  ]

  return (
    <>
      <div className={''}>
        {items.map((item, index) => (
          <React.Fragment key={index}>{renderItem(item)}</React.Fragment>
        ))}
      </div>
    </>
  )
}

Education.propTypes = {
  data: PropTypes.object,
}
