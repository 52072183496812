import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { jobJobsList } from 'features/job/store'
import { NoDataTextMessage, OverlayDataShower, Pagination, Modal } from 'components/Elements'
import { JOB_STATUS } from 'features/job/consts/general'
import { JobsTable } from './JobsTable'
import { usePopup } from 'hooks'
import { TargetCandidatesContainer } from 'features/job/components/Candidates/TargetCandidates'
import { GeneralJobsFilter } from 'features/job/components/Jobs/Filters'
import { KEYWORDS_MIN_LENGTH } from 'consts/form'

const GeneralJobsContainerComponent = ({ ...props }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(jobJobsList.resetDataState({}))
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page, props.filters])

  const fetchList = () => {
    const { keywords, ...restFilters } = props.filters

    let searchFilters = {}
    if (keywords && keywords.trim().length >= KEYWORDS_MIN_LENGTH) {
      searchFilters = {
        keywords,
      }
    }

    let params = {
      page: props.page,
      per_page: 20,
      filters: {
        ...restFilters,
        ...searchFilters,
        statuses: [JOB_STATUS.ACTIVE],
      },
      sort: [{ id: 'created_at', desc: true }],
    }

    dispatch(jobJobsList.getList({ params }))
  }

  const onPageChange = item => {
    dispatch(jobJobsList.setPage(item.selected + 1))
  }

  const onFiltersChange = values => {
    dispatch(jobJobsList.changeFilter({ ...values }))
  }

  const isLoading = useMemo(() => {
    return props.loading
  }, [props.loading])

  const { visible, open, close, data: modalData } = usePopup()

  const onRecommend = id => {
    open({ id })
  }

  return (
    <Card>
      <CardBody>
        <div className={'mb-2'}>
          <GeneralJobsFilter defaultValues={props.filters} onFiltersChange={onFiltersChange} />
        </div>
        <OverlayDataShower isLoading={isLoading} isFailed={!!props.error} error={props.error}>
          <JobsTable
            items={props.list}
            actionProps={{ onRecommend }}
            loading={isLoading}
            removeControlProps={{ onSuccess: fetchList }}
          />
          <Pagination data={props.meta} onPageChange={onPageChange} className={'mt-2'} />
        </OverlayDataShower>

        {!props.list.length && !props.loading && !props.error && (
          <NoDataTextMessage variant={'empty'} />
        )}

        <Modal
          visible={visible}
          onClose={close}
          title={t('recommendations')}
          size={'xl'}
          onClosed={fetchList}
        >
          <TargetCandidatesContainer job={{ id: modalData?.id }} />
        </Modal>
      </CardBody>
    </Card>
  )
}

GeneralJobsContainerComponent.propTypes = {}

const mapStateToProps = state => {
  const { list, loading, error, meta, page, filters } = state.job.jobs.list
  return {
    list,
    loading,
    error,
    meta,
    page,
    filters,
  }
}

export const GeneralJobsContainer = connect(mapStateToProps)(GeneralJobsContainerComponent)
