import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
  loading: false,
  error: null,
  page: 1,
  meta: null,
}

const immigrateFAQEntriesListSlice = createSlice({
  name: 'immigrateFAQEntriesList',
  initialState,
  reducers: {
    getList(state) {
      state.loading = true
      state.error = null
    },
    getListSuccess(state, action) {
      state.loading = false
      state.list = action.payload.data
      state.meta = action.payload?.meta
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    addItem(state, action) {
      const item = {
        ...action.payload,
        questions: [],
      }

      state.list.push(item)
    },
    updateListItem(state, action) {
      state.list = state.list.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        } else {
          return item
        }
      })
    },
    removeItem(state, action) {
      state.list = state.list.filter(item => item.id !== action.payload.id)
    },
    addQuestionItem(state, action) {
      const { id, question } = action.payload

      state.list = state.list.map(item => {
        if (item.id === id) {
          const questions = [...item?.questions]
          questions.push(question)

          return {
            ...item,
            questions,
          }
        } else {
          return item
        }
      })
    },
    updateQuestionItem(state, action) {
      const { id, question } = action.payload

      state.list = state.list.map(item => {
        if (item.id === id) {
          const questions = item.questions.map(q => {
            if (q.id === question.id) {
              return {
                ...q,
                ...question,
              }
            } else {
              return q
            }
          })

          return {
            ...item,
            questions,
          }
        } else {
          return item
        }
      })
    },
    removeQuestionItem(state, action) {
      const { id, question } = action.payload

      state.list = state.list.map(item => {
        if (item.id === id) {
          const questions = item.questions.filter(q => q.id !== question.id)

          return {
            ...item,
            questions,
          }
        } else {
          return item
        }
      })
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListSuccess
 * @property {function} getListError
 * @property {function} setPage
 * @property {function} addItem
 * @property {function} updateListItem
 * @property {function} removeItem
 * @property {function} addQuestionItem
 * @property {function} updateQuestionItem
 * @property {function} removeQuestionItem
 * @property {function} cleanState
 */

export const immigrateFAQEntriesList = immigrateFAQEntriesListSlice.actions

export default immigrateFAQEntriesListSlice.reducer
