import { combineReducers } from 'redux'

import statistics from './statistics/reducers'
import candidates from './candidates/reducers'

const jobReducer = combineReducers({
  statistics,
  candidates,
})

export default jobReducer
