import React from 'react'

import { ROLE } from 'features/user'

export const useUserModel = () => {
  const isCandidate = user => {
    return user?.role === ROLE.STUDENT
  }

  const isEmployer = user => {
    return user?.role === ROLE.EMPLOYER
  }

  const isMentor = user => {
    return isCandidate() && user?.student_profile?.is_mentor
  }

  const getName = user => {
    return user?.first_name && user?.last_name ? `${user?.first_name} ${user?.last_name}` : null
  }

  return {
    isCandidate,
    isEmployer,
    getName,
    isMentor,
  }
}
