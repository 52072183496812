import React from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PageContent, PageHeader } from 'components/Layouts'

export const JobApplicationsTabLayout = props => {
  const { t } = useTranslation()

  return (
    <PageContent>
      <PageHeader back />

      <Outlet />
    </PageContent>
  )
}

JobApplicationsTabLayout.propTypes = {}
