import { combineReducers } from 'redux'

import edit from './edit/reducers'
import entries from './entries/reducers'

const immigrateQuestionReducer = combineReducers({
  edit,
  entries,
})

export default immigrateQuestionReducer
