import React from 'react'
import classNames from 'classnames'

export const Icon = ({ name, ...props }) => {
  switch (name) {
    case 'blocked':
      return (
        <i
          className={classNames('bx bxs-lock-alt', {
            [props.className]: props.className,
          })}
        />
      )

    case 'circle':
      return (
        <i
          className={classNames('bx bxs-circle', {
            [props.className]: props.className,
          })}
        />
      )

    case 'search':
      return (
        <i
          className={classNames('bx bx-search', {
            [props.className]: props.className,
          })}
        />
      )

    default:
      return null
  }
}
