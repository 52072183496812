import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import classNames from 'classnames'
import { FormField } from 'components/FormElements'
import InputMask from 'react-input-mask'

export const PhoneInput = ({
  id,
  label,
  placeholder,
  type,
  defaultValue,
  fieldRef = null,
  prependSymbol,
  prependAccessory,
  appendAccessory,
  normalize,
  leftAccessory,
  rightAccessory,
  disabled,
  value,
  meta = {},
  translateParams,
  onChange,
  wrapClassName,
  hintText,
  mask = '+19999999999',
  maskChar = null,
  ...props
}) => {
  const { error } = meta

  const showError = Boolean(error)

  const renderByAccessoryType = accessory => {
    if (accessory) {
      if (typeof accessory === 'string') {
        return <i className={accessory} />
      } else return accessory
    } else return <></>
  }

  return (
    <>
      <FormField
        id={id}
        label={label}
        meta={meta}
        hintText={hintText}
        translateParams={translateParams}
        className={wrapClassName}
        render={() => (
          <>
            {leftAccessory && renderByAccessoryType(leftAccessory)}

            {prependAccessory && prependAccessory.map((item, index) => item)}

            <InputMask {...props} mask={mask} value={value} onChange={onChange} maskChar={maskChar}>
              {inputMaskProps => (
                <Input
                  rows={type === 'textarea' ? '5' : null}
                  {...inputMaskProps}
                  type={type}
                  className={classNames('form-control', {
                    'form-control-right-accessory': rightAccessory,
                    'form-control-border-right-round': rightAccessory && !appendAccessory,
                    'form-control-left-accessory': leftAccessory,
                    'form-control-border-left-round': leftAccessory && !appendAccessory,
                  })}
                  id={id}
                  placeholder={placeholder}
                  disabled={disabled}
                  invalid={showError}
                  innerRef={fieldRef}
                  onBlur={props.onBlur}
                />
              )}
            </InputMask>

            {rightAccessory && renderByAccessoryType(rightAccessory)}

            {appendAccessory && appendAccessory.map((item, index) => item)}
          </>
        )}
      />
    </>
  )
}

PhoneInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.any,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.any,
  fieldRef: PropTypes.any,
  children: PropTypes.any,
  prependSymbol: PropTypes.any,
  prependAccessory: PropTypes.array,
  appendAccessory: PropTypes.array,
  normalize: PropTypes.func,
  rightAccessory: PropTypes.any,
  leftAccessory: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  translateParams: PropTypes.object,
  value: PropTypes.any,
  meta: PropTypes.object,
  wrapClassName: PropTypes.string,
  hintText: PropTypes.string,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
}
