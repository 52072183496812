import React from 'react'
import PropTypes from 'prop-types'
import s from './HtmlContent.module.scss'

export const HtmlContent = ({ content, ...props }) => {
  return (
    <div
      className={s.content}
      dangerouslySetInnerHTML={{
        __html: content || '',
      }}
    />
  )
}

HtmlContent.propTypes = {
  content: PropTypes.string,
}
