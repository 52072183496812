import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap'

import { Header } from './Header'
import { Body } from './Body'
import { Action } from './Action'

export const Questions = ({ items, action, editControlProps, removeControlProps, ...props }) => {
  const { t } = useTranslation()

  return (
    <>
      <UncontrolledAccordion flush stayOpen>
        {items.map(item => {
          const { id, question, answer } = item
          const itemId = id.toString()

          return (
            <div className={'d-flex flex-grow-1 gap-3 align-items-center'} key={id}>
              <AccordionItem className={'w-100'}>
                <AccordionHeader targetId={itemId}>
                  <Header title={question} />
                </AccordionHeader>
                <AccordionBody accordionId={itemId}>
                  <Body value={answer} />
                </AccordionBody>
              </AccordionItem>

              {action ? (
                <div className={'align-self-start pt-4'}>
                  <Action
                    data={item}
                    editControlProps={editControlProps}
                    removeControlProps={removeControlProps}
                  />
                </div>
              ) : null}
            </div>
          )
        })}
      </UncontrolledAccordion>
    </>
  )
}

Questions.propTypes = {
  items: PropTypes.array,
  action: PropTypes.bool,
  editControlProps: PropTypes.object,
  removeControlProps: PropTypes.object,
}
