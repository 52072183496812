import React from 'react'
import PropTypes from 'prop-types'
import { useRoutes } from 'react-router-dom'
import { useAuth } from 'features/auth'
import { useUser } from 'features/user'
import { getRootRoutes } from 'routes/index'

export const RootNavigator = () => {
  const { isLoggedIn } = useAuth()

  const { user } = useUser()

  return useRoutes(getRootRoutes(isLoggedIn, user))
}

RootNavigator.propTypes = {}
