import * as Yup from 'yup'

export const companyProfileValidation = {
  company_name: Yup.string().nullable().required('field.error.required'),
  company_website: Yup.string().nullable(),

  industry_id: Yup.mixed().nullable().required('field.error.required'),
  company_size_id: Yup.mixed().nullable().required('field.error.required'),

  company_cta_text: Yup.string()
    .nullable()
    .when(['$withCTA', 'company_cta_button_text', 'company_cta_button_link'], {
      is: (withCTA, field1, field2) => withCTA && (field1 || field2),
      then: schema => schema.required('field.error.required'),
      otherwise: schema => schema,
    }),

  company_cta_button_text: Yup.string()
    .nullable()
    .when(['$withCTA', 'company_cta_text', 'company_cta_button_link'], {
      is: (withCTA, field1, field2) => withCTA && (field1 || field2),
      then: schema => schema.required('field.error.required'),
      otherwise: schema => schema,
    }),

  company_cta_button_link: Yup.string()
    .nullable()
    .when(['$withCTA', 'company_cta_text', 'company_cta_button_text'], {
      is: (withCTA, field1, field2) => withCTA && (field1 || field2),
      then: schema => schema.required('field.error.required'),
      otherwise: schema => schema,
    }),
}

export const basicProfileValidation = {
  profile_position_id: Yup.mixed().nullable().required('field.error.required'),
}

export const employerProfileValidation = {
  employer_profile: Yup.object().shape(
    {
      ...basicProfileValidation,
      ...companyProfileValidation,
    },
    [
      ['company_cta_text', 'company_cta_button_text'],
      ['company_cta_text', 'company_cta_button_link'],
      ['company_cta_button_text', 'company_cta_text'],
      ['company_cta_button_text', 'company_cta_button_link'],
      ['company_cta_button_link', 'company_cta_text'],
      ['company_cta_button_link', 'company_cta_button_text'],
    ]
  ),
}
