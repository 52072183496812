import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { jobAPI } from '../../../api'
import {
  JOB_RECOMMENDATION_SUBMIT_REQUEST,
  JOB_RECOMMENDATION_SUBMIT_SUCCESS,
  JOB_RECOMMENDATION_SUBMIT_FAILED,
} from './actionTypes'

export const jobRecommendationsSubmitPA = createPromiseAction(
  JOB_RECOMMENDATION_SUBMIT_REQUEST,
  JOB_RECOMMENDATION_SUBMIT_SUCCESS,
  JOB_RECOMMENDATION_SUBMIT_FAILED
)()

function* submit(action) {
  try {
    const { id, user, recommended } = action.payload

    const response = recommended
      ? yield call(jobAPI.addJobRecommendation, id, user)
      : yield call(jobAPI.deleteJobRecommendation, id, user)

    const { data } = response

    yield put(jobRecommendationsSubmitPA.success({}))

    resolvePromiseAction(action, { recommended_for_job: recommended })
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* submitSaga() {
  yield takeEvery(jobRecommendationsSubmitPA.request, submit)
}
