import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { IconPlus } from '@tabler/icons'
import { Button, Modal } from 'components/Elements'
import { usePopup } from 'hooks'
import { AddCertificationForm } from './AddCertificationForm'

export const AddCertification = ({ onAdd, options }) => {
  const { t } = useTranslation()

  const { visible, open, close } = usePopup()

  const onCertificationAdd = value => {
    onAdd(value)
    close()
  }

  return (
    <>
      <div>
        <Button onClick={open} leftIcon={<IconPlus size={16} />} outline size={'md'}>
          {t('add_certification')}
        </Button>
      </div>

      <Modal visible={visible} centered onClose={close} title={t('add_certification')}>
        <AddCertificationForm onAdd={onCertificationAdd} options={options} />
      </Modal>
    </>
  )
}

AddCertification.propTypes = {
  options: PropTypes.array,
  onAdd: PropTypes.func,
}
