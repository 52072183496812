import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { JobPayRangeDisplay } from 'features/job/components/Elements'

export const PropertiesBlock = ({ data = {}, hidden = [], className = '' }) => {
  const { t } = useTranslation()

  const {
    profile_experience_year,
    pay_range_min,
    pay_range_max,
    job_type,
    is_remote,
    multiple_locations,
  } = data

  const items = [
    {
      icon: <i className={'bx bx-bar-chart-square mt-1 font-size-18'} />,
      key: 'experience',
      value: profile_experience_year?.value,
    },
    {
      icon: <i className={'mdi mdi-cash-usd-outline font-size-18'} />,
      key: 'pay-range',
      value: <JobPayRangeDisplay data={{ pay_range_min, pay_range_max }} />,
    },
    {
      icon: <i className={'mdi mdi-calendar-clock font-size-18'} />,
      key: 'type',
      value: job_type?.value,
    },
    {
      icon: <i className={'mdi mdi-home-account font-size-18'} />,
      key: 'remote',
      value: t('remote'),
      hidden: !is_remote,
    },
    {
      icon: <i className={'mdi mdi-map-marker font-size-18'} />,
      key: 'multiple_locations',
      value: t('multiple_locations'),
      hidden: !multiple_locations,
    },
  ]

  return (
    <div
      className={classNames('d-flex flex-wrap gap-3 font-size-14', {
        [className]: className,
      })}
    >
      {items
        .filter(item => !item.hidden)
        .map(({ icon, value, key }) => (
          <div key={key} className={'d-flex align-items-center'}>
            <span className={'text-primary'}>{icon}</span>
            <span className={'ms-2'}>{value}</span>
          </div>
        ))}
    </div>
  )
}

PropertiesBlock.propTypes = {
  data: PropTypes.object,
  hidden: PropTypes.array,
  className: PropTypes.string,
}
