import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { RemoveControl } from './RemoveControl'
import { EditControl } from './EditControl'
import { AddControl } from './AddControl'

export const Action = ({ data = {}, addControlProps, editControlProps, removeControlProps }) => {
  const { t } = useTranslation()

  return (
    <div className={'d-flex gap-4 font-size-14 justify-content-end text-nowrap align-items-center'}>
      <AddControl {...addControlProps} data={data} />

      <EditControl {...editControlProps} data={data} />

      <RemoveControl {...removeControlProps} data={data} />
    </div>
  )
}

Action.propTypes = {
  data: PropTypes.object,
  addControlProps: PropTypes.object,
  editControlProps: PropTypes.object,
  removeControlProps: PropTypes.object,
}
