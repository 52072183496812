import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import { useUserAccount } from 'features/users/hooks'

import { GeneralTableBlock } from './Blocks'
import { ReceivedApplications } from './Sections'

export const StatisticsContent = ({ data = {}, isFiltersValid, filters }) => {
  const { t } = useTranslation()

  const { isCandidate, isEmployer, isMentor } = useUserAccount()

  const userStatistics = isEmployer()
    ? data?.employer_statistics
    : isCandidate()
    ? data?.candidate_statistics
    : {}

  return (
    <Row>
      {isCandidate() && (
        <>
          <Col md={4}>
            <GeneralTableBlock title={t('applications')} data={userStatistics?.sent_applications} />
          </Col>

          <Col md={4}>
            <GeneralTableBlock title={t('invites')} data={userStatistics?.received_invites} />
          </Col>

          <Col md={4}>
            <GeneralTableBlock title={t('logins')} data={userStatistics?.logins} />
          </Col>

          <Col md={4}>
            <GeneralTableBlock title={t('viewed')} data={userStatistics?.viewed} />
          </Col>
        </>
      )}

      {isMentor() && (
        <>
          <Col md={4}>
            <GeneralTableBlock
              title={t('mentor_bookings')}
              data={data?.mentor_statistics?.bookings}
            />
          </Col>
        </>
      )}

      {isEmployer() && (
        <>
          <Col md={4}>
            <GeneralTableBlock title={t('jobs')} data={userStatistics?.jobs} />
          </Col>

          <Col md={4}>
            <ReceivedApplications
              data={userStatistics?.received_applications}
              isFiltersValid={isFiltersValid}
              filters={filters}
            />
          </Col>

          <Col md={4}>
            <GeneralTableBlock title={t('invites')} data={userStatistics?.sent_invites} />
          </Col>

          <Col md={4}>
            <GeneralTableBlock
              title={t('candidates_views')}
              data={userStatistics?.candidates_views}
            />
          </Col>
        </>
      )}
    </Row>
  )
}

StatisticsContent.propTypes = {
  data: PropTypes.object,
  isFiltersValid: PropTypes.bool,
  filters: PropTypes.object,
}
