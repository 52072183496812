import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import {
  JobsBlock,
  JobApplicationsBlock,
  JobInvitesBlock,
  EmployersBlock,
  CandidatesBlock,
  CandidatesViewsBlock,
  MentorsBlock,
  MentorsBookingsBlock,
} from './Blocks'

export const StatisticsContent = ({ data = {} }) => {
  const { t } = useTranslation()

  return (
    <Row>
      <Col md={4}>
        <JobsBlock data={data?.jobs} />
      </Col>

      <Col md={4}>
        <JobApplicationsBlock data={data?.job_applications} />
      </Col>

      <Col md={4}>
        <JobInvitesBlock data={data?.job_invites} />
      </Col>

      <Col md={4}>
        <EmployersBlock data={data?.employers} />
      </Col>

      <Col md={4}>
        <CandidatesBlock data={data?.candidates} />
      </Col>

      <Col md={4}>
        <CandidatesViewsBlock data={data?.candidates_views} />
      </Col>

      <Col md={4}>
        <MentorsBlock data={data?.mentors} />
      </Col>

      <Col md={4}>
        <MentorsBookingsBlock data={data?.mentor_bookings} />
      </Col>
    </Row>
  )
}

StatisticsContent.propTypes = {
  data: PropTypes.object,
}
