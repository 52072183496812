import React, { useCallback, useEffect } from 'react'
import { debounce } from 'lodash'

export const useFormValuesChangedDebounce = (watch, callback, delay = 700) => {
  const onDebouncedChange = useCallback(debounce(callback, delay), [callback])

  useEffect(() => {
    const subscription = watch(values => {
      onDebouncedChange(values)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    return () => {
      onDebouncedChange.cancel()
    }
  }, [])
}
