import React, { useReducer } from 'react'

import { ValidationsUtils } from 'utils'

const initialState = {
  error: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'error':
      return {
        ...state,
        error: action.payload,
      }
    case 'reset':
      return initialState
    default:
      return state
  }
}

export const useFormSubmit = ({ submit, setError }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const onSubmit = async (...args) => {
    resetState()

    try {
      await submit(...args)
    } catch (error) {
      dispatch({ type: 'error', payload: error })

      if (setError) {
        ValidationsUtils.setServerSideErrors(error?.errors, setError)
      }

      throw error
    }
  }

  const resetState = () => {
    dispatch({ type: 'reset' })
  }

  return {
    ...state,
    onSubmit,
    resetState,
  }
}
