import axios from 'axios'

class UsersAPI {
  getStudents = async params => {
    return axios.get(`api/admin/users/candidates`, {
      params,
    })
  }

  getEmployers = async params => {
    return axios.get(`api/admin/users/employers`, {
      params,
    })
  }

  getMentors = async params => {
    return axios.get(`api/admin/users/mentors`, {
      params,
    })
  }
}

export const usersAPI = new UsersAPI()
