import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import { AsyncCompanyFormSection } from 'features/company/components/FormSections'

import { Combobox, FormFieldController } from 'components/FormElements'
import { useEmployerJobsFetch } from 'features/job/hooks'
import { JOB_STATUS } from 'features/job/consts/general'

export const JobFormSection = ({ onJobChange }) => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext()

  const watchUserId = watch('user_id')

  const { data, loading, onFetch, resetState } = useEmployerJobsFetch()

  useEffect(() => {
    if (watchUserId) {
      onJobChange(null)

      resetState()

      onFetch({
        pagination: false,
        filters: {
          statuses: [JOB_STATUS.ACTIVE],
          user: watchUserId,
        },
        sort: [
          {
            id: 'posted_on',
            desc: true,
          },
        ],
      })
    }
  }, [watchUserId])

  return (
    <>
      <Row>
        <Col md={12}>
          <AsyncCompanyFormSection defaultValue={null} />
        </Col>
      </Row>

      {watchUserId && (
        <Row>
          <Col md={12}>
            <FormFieldController
              name={'job'}
              control={control}
              component={Combobox}
              id={'job'}
              label={t('job')}
              placeholder={t('job')}
              options={data?.data}
              // isDetermineValue
              // normalize={option => (option !== null ? option.id : null)}
              isLoading={loading}
              getOptionLabel={option => option?.title}
              getOptionValue={option => option.id}
              defaultValue={null}
              shouldUnregister
              onChange={onJobChange}
            />
          </Col>
        </Row>
      )}
    </>
  )
}
