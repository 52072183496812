import React from 'react'

import { tariffHelper } from 'features/billing/helpers/tariff'

export const useBillingSelectOptions = () => {
  const getTariffsPlans = () => {
    return tariffHelper.general.getTariffsPlans().map(plan => ({
      label: plan.name,
      value: plan.alias,
    }))
  }

  return {
    getTariffsPlans,
  }
}
