import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { DateUtils } from 'utils'

export const PropertiesBlock = ({
  data = {},
  className = '',
  withApplication = false,
  application = {},
}) => {
  const { t } = useTranslation()

  const {
    school,
    profile_study_program,
    profile_study_area,
    location_city,
    location_province,
    profile_experience_year,
  } = data

  const renderStudy = () => {
    let study = ''

    if (profile_study_program) {
      study = profile_study_program?.value
    }

    if (profile_study_area) {
      let area = profile_study_area?.value
      if (profile_study_program) {
        area = `: ${area}`
      }
      study += area
    }

    return study ? `${study} · ` : null
  }

  const renderSchool = () => {
    return school?.value ? `${school?.value} · ` : null
  }

  const renderLocation = () => {
    let location = ''

    if (location_city) {
      location = location_city?.value
    }

    if (location_province) {
      let province = location_province?.value
      if (location_city) {
        province = `, ${province}`
      }
      location += province
    }

    return location ? (
      <span>
        {t('location')}: <span className={'fw-normal'}>{location}</span>
      </span>
    ) : null
  }

  const renderExp = () => {
    return profile_experience_year?.value ? (
      <span>
        {' · '}
        {t('exp')}: <span className={'fw-normal'}>{profile_experience_year?.value}</span>
      </span>
    ) : null
  }

  const renderApplication = () => {
    const { created_at } = application

    return (
      <span>
        {' · '}
        {t('applied_on')}:{' '}
        <span className={'fw-normal'}>
          {created_at && DateUtils.format(created_at, 'YYYY/MM/DD')}
        </span>
      </span>
    )
  }

  return (
    <div className={'font-size-14 fw-light'}>
      <span>
        {renderStudy()}
        {renderSchool()}
        {renderLocation()}
        {renderExp()}
        {withApplication ? renderApplication() : null}
      </span>
    </div>
  )
}

PropertiesBlock.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
  withApplication: PropTypes.bool,
  application: PropTypes.object,
}
