import i18n from 'i18n'

export const getFeatures = () => {
  return [
    {
      name: i18n.t('candidate_searches'),
      key: 'candidate_search',
    },
    {
      name: i18n.t('candidate_invite'),
      key: 'candidate_invite',
    },
    {
      name: i18n.t('candidate_bulk_invite'),
      key: 'candidate_bulk_invite',
    },
    {
      name: i18n.t('candidate_recommendation'),
      key: 'candidate_recommendation',
    },
    {
      name: i18n.t('candidate_phone_pre-screen'),
      key: 'candidate_phone',
    },
    {
      name: i18n.t('blind_hiring'),
      key: 'blind_hiring',
    },
    {
      name: i18n.t('job_posting'),
      key: 'job_posting',
    },
    {
      name: i18n.t('job_posting_assistance'),
      key: 'job_posting_assistance',
    },
    {
      name: i18n.t('targeted_email_promotion'),
      key: 'targeted_email_promotion',
    },
    {
      name: i18n.t('company_profile'),
      key: 'company_profile',
    },
    {
      name: i18n.t('quarterly_nationwide_career_fair'),
      key: 'quarterly_nationwide_career_fair',
    },
  ]
}
