import React from 'react'
import { useTranslation } from 'react-i18next'

import { useAsyncDispatch, useNotify } from 'hooks'
import { jobApplicationExportFetchPA } from 'features/job-application/store'

export const useJobApplicationsExport = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const onExport = async params => {
    try {
      const data = await onAsyncDispatch(jobApplicationExportFetchPA.request, { params })

      return data?.data
    } catch (error) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    loading,
    onExport,
  }
}
