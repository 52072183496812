import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Anchor } from 'components/Elements'
import { SubscriptionBadge } from 'features/billing/components/Elements'
import { BILLING_TARIFF_ALIAS } from 'features/billing/consts/tariff'

const { PREMIUM } = BILLING_TARIFF_ALIAS

export const Employer = ({ user = {} }) => {
  const { t } = useTranslation()

  const name = user ? `${user?.first_name} ${user?.last_name}` : '-'

  const subscriptionPlan = user?.subscription?.tariff?.alias

  return (
    <div>
      <div className={'d-flex flex-wrap gap-2 align-items-center'}>
        <Anchor to={`/users/${user?.id}`} className={'text-decoration-underline'}>
          {name}
        </Anchor>

        {user?.subscription && <SubscriptionBadge size={'xs'} alias={subscriptionPlan} />}
      </div>

      {subscriptionPlan === PREMIUM && (
        <span className={'font-size-14'}>
          <span className={'text-muted'}>{`${t('monthly_recommended_jobs')}: `}</span>
          <span>{`${user?.month_recommended_jobs_count}/5`}</span>
        </span>
      )}
    </div>
  )
}

Employer.propTypes = {
  user: PropTypes.object,
}
