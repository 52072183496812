import axios from 'axios'

class JobAPI {
  getJob = async (id, params) => {
    return axios.get(`api/jobs/${id}`, {
      params,
    })
  }

  updateJob = async (id, params) => {
    return axios.put(`api/admin/jobs/${id}`, params)
  }

  deleteJob = async id => {
    return axios.delete(`api/admin/jobs/${id}`)
  }

  addJobRecommendation = async (id, user) => {
    return axios.post(`api/admin/jobs/${id}/recommendations/${user}`)
  }

  deleteJobRecommendation = async (id, user) => {
    return axios.delete(`api/admin/jobs/${id}/recommendations/${user}`)
  }

  getJobStatistics = async (id, params) => {
    return axios.get(`api/admin/jobs/${id}/statistics`, {
      params,
    })
  }

  getJobCandidates = async (id, params) => {
    return axios.get(`api/jobs/${id}/candidates`, {
      params,
    })
  }

  createInvite = async (id, params) => {
    return axios.post(`api/admin/jobs/${id}/invites`, params)
  }

  bulkInvite = async (id, params) => {
    return axios.post(`api/admin/jobs/${id}/bulk-invites`, params)
  }
}

export const jobAPI = new JobAPI()
