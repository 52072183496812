import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { NoDataTextMessage } from 'components/Elements'
import { DateUtils } from 'utils'

export const WorkAuthorizations = ({ data = [] }) => {
  const { t } = useTranslation()

  const renderItem = ({ value, expiry_date, ...item }) => {
    return (
      <>
        <div className={'d-flex flex-wrap'}>
          <span className={'fw-medium me-1'}>{t('name')}:</span> {value}
        </div>
        <div className={'d-flex flex-wrap'}>
          <span className={'fw-medium me-1'}>{t('expire_date_permit')}:</span>
          {expiry_date ? DateUtils.formatDate(expiry_date) : <NoDataTextMessage variant={'fill'} />}
        </div>
      </>
    )
  }

  return (
    <div className={''}>
      {data.map((item, i) => (
        <div key={i} className={i !== 0 ? 'mt-2' : ''}>
          {item?.weight === 2 ? item?.value : renderItem(item)}
        </div>
      ))}
    </div>
  )
}

WorkAuthorizations.propTypes = {
  data: PropTypes.array,
}
