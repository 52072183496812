import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { IconX, IconDownload, IconPencil, IconPlus } from '@tabler/icons'
import { Button as StapButton } from 'reactstrap'

import s from './ActionIcon.module.scss'

export const ActionIcon = ({
  type = 'button',
  name,
  disabled,
  id,
  children,
  onClick,
  className,
  size,
  outline = true,
}) => {
  const handleClick = e => {
    onClick(e)
  }

  const config = () => {
    let data = {
      icon: '',
      color: '',
      bgColor: '',
    }

    let iconSize = 16

    if (size === 'sm') {
      iconSize = 14
    }

    switch (name) {
      case 'remove':
        data = {
          icon: <IconX size={iconSize} />,
          color: 'danger',
        }
        break

      case 'download':
        data = {
          icon: <IconDownload size={iconSize} />,
          color: 'primary',
        }
        break

      case 'edit':
        data = {
          icon: <IconPencil size={iconSize} />,
          color: 'primary',
        }
        break

      case 'add':
        data = {
          icon: <IconPlus size={iconSize} />,
          color: 'primary',
        }
        break

      default:
        data = { ...data }
        break
    }
    return data
  }

  return (
    <StapButton
      id={id}
      className={classNames(`btn-rounded ${s.actionButton}`, {
        [className]: className,
        [s.actionButton_size_lg]: size === 'lg',
        [s.actionButton_size_sm]: size === 'sm',
      })}
      type={type}
      onClick={handleClick}
      disabled={disabled}
      outline={outline}
      color={config().color}
    >
      {children ? children : config().icon}
    </StapButton>
  )
}

ActionIcon.propTypes = {
  type: PropTypes.string,
  name: PropTypes.oneOf(['remove', 'download', 'edit', 'add']),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
  size: PropTypes.string,
  outline: PropTypes.bool,
}
