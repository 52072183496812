import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { FormFieldController, TextInput } from 'components/FormElements'
import { NormalizeUtils } from 'utils'
import { currencyHelper } from 'helpers'

const currencySymbol = currencyHelper.general.getGeneralCurrency()?.symbol

export const PayRangeFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const fields = [
    {
      name: 'pay_range_min',
      label: t('from'),
      placeholder: t('from'),
    },
    {
      name: 'pay_range_max',
      label: t('to'),
      placeholder: t('to'),
    },
  ]

  return (
    <>
      <h5>{t('pay_range')}</h5>
      <Row>
        {fields.map(({ name, label, placeholder }, i) => (
          <Col md={6} key={i}>
            <FormFieldController
              name={name}
              control={control}
              component={TextInput}
              id={name}
              label={label}
              placeholder={placeholder}
              normalize={NormalizeUtils.onlyNumbers}
              prependAccessory={[
                <span key={'1'} className="input-group-text">
                  {currencySymbol}
                </span>,
              ]}
              appendAccessory={[
                <span key={'yr'} className="input-group-text">
                  {`/${t('yr')}`}
                </span>,
              ]}
            />
          </Col>
        ))}
      </Row>
    </>
  )
}
