import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './Header.module.scss'
import { Bubble } from '../Bubble'

export const Header = ({ title, rightSection, ...props }) => {
  const { t } = useTranslation()

  return (
    <div className={'d-flex gap-3'}>
      <div className={'d-flex flex-grow gap-4'}>
        <div>
          <Bubble isQuestion />
        </div>

        <div className={s.text}>{title}</div>
      </div>

      {rightSection ? rightSection : null}
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  rightSection: PropTypes.node,
}
