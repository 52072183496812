import React from 'react'

import { ROLE } from 'features/user'
import { JobsRoot } from 'features/job/routes'
import { UsersRoot } from 'features/users/routes'
import { DashboardRoot } from 'features/dashboard/routes'
import { ImmigrateRoot } from 'features/immigrate/routes'

const generalRoutes = [
  {
    path: 'dashboard/*',
    element: <DashboardRoot />,
    roles: [ROLE.SUPER_ADMIN],
    guards: [],
  },
  {
    path: 'jobs/*',
    element: <JobsRoot />,
    roles: [ROLE.SUPER_ADMIN],
    guards: [],
  },
  {
    path: 'users/*',
    element: <UsersRoot />,
    roles: [ROLE.SUPER_ADMIN],
    guards: [],
  },
  {
    path: 'immigrate/*',
    element: <ImmigrateRoot />,
    roles: [ROLE.SUPER_ADMIN],
    guards: [],
  },
]

export const getGeneralRoutes = () => {
  return generalRoutes
}
