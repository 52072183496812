import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { userAPI } from '../../../api'
import {
  USERS_SUBSCRIPTION_UPDATE_REQUEST,
  USERS_SUBSCRIPTION_UPDATE_SUCCESS,
  USERS_SUBSCRIPTION_UPDATE_FAILED,
} from './actionTypes'

export const usersSubscriptionUpdatePA = createPromiseAction(
  USERS_SUBSCRIPTION_UPDATE_REQUEST,
  USERS_SUBSCRIPTION_UPDATE_SUCCESS,
  USERS_SUBSCRIPTION_UPDATE_FAILED
)()

function* update(action) {
  try {
    const { id, params } = action.payload

    const response = yield call(userAPI.updateUserSubscription, id, params)
    const { data } = response

    yield put(usersSubscriptionUpdatePA.success({}))

    resolvePromiseAction(action, { ...data })
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* updateSaga() {
  yield takeEvery(usersSubscriptionUpdatePA.request, update)
}
