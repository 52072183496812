import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Modal } from 'components/Elements'
import { CategoryEditContainer } from './CategoryEditContainer'
import { CategoryDataLoader } from './CategoryDataLoader'

export const CategoryEdit = ({ id, visible, onClose, onSuccess }) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal visible={visible} centered onClose={onClose} title={t('edit_category')} size={'lg'}>
        <CategoryDataLoader id={id}>
          <CategoryEditContainer id={id} onClose={onClose} onSuccess={onSuccess} />
        </CategoryDataLoader>
      </Modal>
    </>
  )
}

CategoryEdit.propTypes = {
  id: PropTypes.number,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}
