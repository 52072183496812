import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Dashboard } from '../dashboard'

export const DashboardRoot = () => {
  return (
    <Routes>
      <Route index element={<Dashboard />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}

DashboardRoot.propTypes = {}
