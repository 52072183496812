import { takeLatest, fork, put, all, call } from 'redux-saga/effects'

import i18n from 'i18n'
import { jobJobStatisticsData } from './slice'
import { jobAPI } from '../../../../api'

function* getData({ payload: { id, params = {} } }) {
  try {
    const response = yield call(jobAPI.getJobStatistics, id, params)

    const { data } = response

    yield put(jobJobStatisticsData.getDataSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(jobJobStatisticsData.getDataError(message))
  }
}

function* watchGetData() {
  yield takeLatest(jobJobStatisticsData.getData, getData)
}

export function* dataSaga() {
  yield all([fork(watchGetData)])
}
