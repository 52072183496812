import axios from 'axios'

class ImmigrateQuestionsAPI {
  createQuestion = async params => {
    return axios.post('api/admin/immigrate-questions', params)
  }

  getQuestions = async params => {
    return axios.get(`api/admin/immigrate-questions`, {
      params,
    })
  }
}

export const immigrateQuestionsAPI = new ImmigrateQuestionsAPI()
