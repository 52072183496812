import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './EducationBlock.module.scss'
import { NoDataTextMessage } from 'components/Elements'
import { Education as ProfileEducation } from 'features/candidate/components/Profile'

export const EducationBlock = ({ data }) => {
  const { t } = useTranslation()

  const { filled } = data

  return (
    <>
      <h5>{t('education')}</h5>
      {filled ? (
        <ProfileEducation
          data={{
            ...data,
          }}
        />
      ) : (
        <NoDataTextMessage variant={'data'} />
      )}
    </>
  )
}

EducationBlock.propTypes = {
  data: PropTypes.object,
}
