import { all } from 'redux-saga/effects'

import { createSaga } from './create/saga'
import { editSaga } from './edit/sagas'
import { removeSaga } from './remove/saga'
import { entriesSaga } from './entries/sagas'

export function* immigrateCategorySaga() {
  yield all([createSaga(), editSaga(), removeSaga(), entriesSaga()])
}
