import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { CategoryCreate } from 'features/immigrate-category/components/ImmigrateCategory'
import { usePopup } from 'hooks'
import { Button } from 'components/Elements'

export const CategoryAdd = ({ onSuccess }) => {
  const { t } = useTranslation()

  const { visible, open, close } = usePopup()

  const onSubmitSuccess = data => {
    close()

    onSuccess && onSuccess(data)
  }

  return (
    <>
      <div>
        <Button onClick={open}>{t('add_category')}</Button>
      </div>

      <CategoryCreate visible={visible} onClose={close} onSuccess={onSubmitSuccess} />
    </>
  )
}

CategoryAdd.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}
