import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { immigrateCategoryCreatePA } from 'features/immigrate-category/store'
import { useNotify } from 'hooks'
import { CategoryForm } from '../../CategoryForm'

export const CategoryCreateContainer = ({ onSuccess }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async values => {
    const data = await dispatch(immigrateCategoryCreatePA.request({ params: values }))

    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess && onSuccess(data)
  }

  return (
    <>
      <CategoryForm onSubmit={onSubmit} />
    </>
  )
}

CategoryCreateContainer.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}
