import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import {
  BasicInfoBlock,
  EducationBlock,
  WorkBlock,
  SelfIdentificationBlock,
  Attachments,
  MentorInfoBlock,
} from 'features/candidate/components/Profile/InfoBlocks'
import { useUserAccount } from 'features/users/hooks'

export const CandidateContent = () => {
  const { t } = useTranslation()

  const { account, isMentor } = useUserAccount()

  return (
    <>
      <Card>
        <CardBody>
          {isMentor() && (
            <div className={'pb-4'}>
              <MentorInfoBlock data={{ ...account?.student_profile }} />
            </div>
          )}

          <div>
            <BasicInfoBlock data={{ ...account?.student_profile }} />
          </div>

          <div className={'pt-4 pb-4'}>
            <Attachments data={{ ...account?.student_profile }} />
          </div>

          <div className={'pt-4'}>
            <WorkBlock data={{ ...account?.student_profile }} asMentor={isMentor()} />
          </div>

          <div className={'pt-4 pb-4'}>
            <EducationBlock data={{ ...account?.student_profile }} />
          </div>

          <div className={'pt-4'}>
            <SelfIdentificationBlock data={{ ...account?.student_profile }} />
          </div>
        </CardBody>
      </Card>
    </>
  )
}

CandidateContent.propTypes = {}
