import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FormText } from 'reactstrap'
import classNames from 'classnames'

export const FieldHint = ({ message, className }) => {
  const { t } = useTranslation()

  return <FormText className={classNames({ [className]: className })}>{t(message)}</FormText>
}

FieldHint.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
}
