import React from 'react'
import PropTypes from 'prop-types'
import RSSelect from 'react-select'
import { useTranslation } from 'react-i18next'

export const Select = ({ classNamePrefix = 'select2-selection', ...props }) => {
  const { t } = useTranslation()

  return (
    <RSSelect
      classNamePrefix="select2-selection"
      noOptionsMessage={() => t('select.no_options')}
      loadingMessage={() => `${t('loading')}...`}
      {...props}
    />
  )
}

Select.propTypes = {
  classNamePrefix: PropTypes.string,
}
