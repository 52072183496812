import _ from 'lodash'

import i18n from 'i18n'
import { JOB_STATUS } from 'features/job/consts/general'

export const getStatuses = () => {
  return [
    {
      label: i18n.t('active'),
      value: JOB_STATUS.ACTIVE,
    },
    {
      label: i18n.t('draft'),
      value: JOB_STATUS.DRAFT,
    },
    {
      label: i18n.t('closed'),
      value: JOB_STATUS.CLOSED,
    },
  ]
}

export const getStatus = value => {
  const options = getStatuses()
  return _.find(options, { value })
}
