import React from 'react'
import PropTypes from 'prop-types'
import { Modal as StrapModal, ModalHeader, ModalBody } from 'reactstrap'
import classNames from 'classnames'

export const Modal = ({
  visible = false,
  children,
  header = true,
  onClose,
  onClosed,
  className = '',
  title,
  renderTitle,
  ...props
}) => {
  return (
    <StrapModal
      isOpen={visible}
      toggle={onClose}
      onClosed={onClosed}
      className={className}
      {...props}
    >
      {header && (
        <ModalHeader toggle={onClose}>
          {renderTitle ? renderTitle() : title ? title : null}
        </ModalHeader>
      )}

      <ModalBody>{children}</ModalBody>
    </StrapModal>
  )
}

Modal.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.any,
  header: PropTypes.bool,
  title: PropTypes.string,
  renderTitle: PropTypes.func,
  onClose: PropTypes.func,
  onClosed: PropTypes.func,
  className: PropTypes.string,
}
