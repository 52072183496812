import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { Table } from '../../Blocks'
import { NumberFormat, Button } from 'components/Elements'
import { useJobApplicationsExport } from 'features/job-application/hooks'
import { DateUtils, FileUtils } from 'utils'
import { useUserAccount } from 'features/users/hooks'

const PERIOD_TYPE = {
  MONTH: 'month',
  CUSTOM: 'custom',
}

export const ReceivedApplications = ({ data = {}, isFiltersValid = false, filters = {} }) => {
  const { t } = useTranslation()

  const { account } = useUserAccount()

  const { loading, onExport } = useJobApplicationsExport()

  const onApplicationsExport = async () => {
    let params = {
      user: account?.id,
    }

    const {
      period: { type, range },
    } = filters

    const getPeriod = () => {
      if (type === PERIOD_TYPE.CUSTOM && isFiltersValid) {
        return range
      }

      const startOfMonth = moment().startOf('month').toDate()
      const endOfMonth = moment().endOf('month').toDate()

      return [startOfMonth, endOfMonth]
    }

    const [start, end] = getPeriod()

    params = {
      ...params,
      date_start: DateUtils.formatDateToServerFormat(start),
      date_end: DateUtils.formatDateToServerFormat(end),
    }

    const result = await onExport(params)

    if (result?.url) {
      FileUtils.downloadFileByLink(result?.url)
    }
  }

  const { period, total } = data

  const items = [
    {
      label: t('amount'),
      value: (
        <div className={'d-flex gap-2 flex-nowrap align-items-center'}>
          <div className={'d-flex flex-grow-1'}>
            <NumberFormat value={period} />
          </div>

          <Button
            disabled={!isFiltersValid || !period}
            loading={loading}
            width={'w-xs'}
            outline
            size={'sm'}
            onClick={onApplicationsExport}
          >
            {t('export')}
          </Button>
        </div>
      ),
    },
    {
      label: t('total_amount'),
      value: total,
    },
  ]

  return (
    <>
      <h4 className="font-size-18">{t('applications')}</h4>

      <Table items={items} />
    </>
  )
}

ReceivedApplications.propTypes = {
  data: PropTypes.object,
  isFiltersValid: PropTypes.bool,
  filters: PropTypes.object,
}
