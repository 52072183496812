export const CANDIDATES_FILTERS_INITIAL_VALUES = {
  profile_statuses: null,
  profile_search_statuses: null,
  location_provinces: null,
  location_cities: null,
  profile_study_programs: null,
  profile_study_areas: null,
  profile_work_authorizations: null,
  profile_experience_year: null,
  profile_skills: null,
  industries: null,
  target_positions: null,
  languages: null,
  schools: null,
  profile_willing_relocate: null,
  profile_objectives: null,
  has_resume: null,
  keywords: '',
  profile_certifications: '',
}
