import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { DataShower } from 'components/Elements'
import { immigrateQuestionEditData } from 'features/immigrate-question/store'

export const QuestionDataLoader = ({ id, children }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { data, loading, error } = useSelector(state => state.immigrateQuestion.edit.data)

  useEffect(() => {
    dispatch(immigrateQuestionEditData.getData({ id }))

    return () => {
      dispatch(immigrateQuestionEditData.cleanState({}))
    }
  }, [])

  return (
    <>
      <DataShower isLoading={loading} isFetched={!!data} isFailed={!!error} error={error}>
        {children}
      </DataShower>
    </>
  )
}

QuestionDataLoader.propTypes = {
  id: PropTypes.number,
  children: PropTypes.node,
}
