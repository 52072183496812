import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'
import s from './CTABlock.module.scss'

export const CTABlock = ({ data = {} }) => {
  const { t } = useTranslation()

  const { company_cta_text, company_cta_button_text, company_cta_button_link } = data

  return (
    <>
      <Card>
        <CardBody>
          <div className={'d-flex gap-3 align-items-center w-100 flex-column flex-lg-row'}>
            <div className={'d-flex flex-grow-1 font-size-20'}>
              <div className={s.text}>{company_cta_text}</div>
            </div>

            <div className={'text-nowrap'}>
              <a
                href={company_cta_button_link}
                target="_blank"
                rel="noreferrer"
                className={'btn btn-primary btn-lg btn-rounded'}
                role={'button'}
              >
                {company_cta_button_text}
              </a>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

CTABlock.propTypes = {
  data: PropTypes.object,
}
