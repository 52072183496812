import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { PageContent, PageHeader } from 'components/Layouts'
import { JobEdit as JobEditContainer } from 'features/job/components/Job'

export const JobEdit = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'edit_job'} />

      <PageContent>
        <PageHeader back />

        <JobEditContainer />
      </PageContent>
    </>
  )
}

JobEdit.propTypes = {}
