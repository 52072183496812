import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { isEqual } from 'lodash'

import { useFormValuesChangedDebounce } from 'hooks'

export const useFilterForm = ({ config = {}, onFiltersChange, initialValues = {} }) => {
  const methods = useForm({
    ...config,
  })

  const { reset, watch } = methods

  useFormValuesChangedDebounce(watch, onFiltersChange)

  const watchValues = watch()

  const isFiltersApplied = useMemo(() => {
    return !isEqual(watchValues, initialValues)
  }, [watchValues])

  const onReset = () => {
    reset(initialValues)
  }

  return {
    methods,
    isApplied: isFiltersApplied,
    onReset,
  }
}
