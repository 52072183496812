import React from 'react'
import { useTranslation } from 'react-i18next'
import { immigrateQuestionRemovePA } from 'features/immigrate-question/store'
import { useAsyncDispatch, useNotify } from 'hooks'

export const useQuestionRemove = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const onSubmit = async (id, onSuccess) => {
    try {
      const data = await onAsyncDispatch(immigrateQuestionRemovePA.request, { id })

      onSuccess && onSuccess({ ...data })

      showNotification({
        type: 'success',
        message: t('removed_successfully'),
      })
    } catch (error) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    onSubmit,
    loading,
  }
}
