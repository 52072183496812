import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

const initialHeaderRightOptions = {
  visible: false,
  render: () => null,
}

const initialConfig = {
  headerRightOptions: initialHeaderRightOptions,
}

const PanelLayoutContext = createContext({
  config: initialConfig,
  setConfig: () => {},
  updateConfig: (data = {}) => {},
  resetConfig: () => null,
  updateHeaderRightOptions: (data = {}) => null,
})

const PanelLayoutProvider = ({ children }) => {
  const [config, setConfig] = useState(initialConfig)

  const updateConfig = (data = {}) => {
    setConfig(prevState => ({
      ...prevState,
      ...data,
    }))
  }

  const resetConfig = () => {
    setConfig(initialConfig)
  }

  const updateHeaderRightOptions = (data = {}) => {
    setConfig(prevState => ({
      ...prevState,
      headerRightOptions: {
        ...prevState.headerRightOptions,
        ...data,
      },
    }))
  }

  return (
    <PanelLayoutContext.Provider
      value={{ config, setConfig, updateConfig, resetConfig, updateHeaderRightOptions }}
    >
      {children}
    </PanelLayoutContext.Provider>
  )
}

PanelLayoutProvider.propTypes = {
  children: PropTypes.node,
}

const usePanelLayoutContext = () => useContext(PanelLayoutContext)

export { PanelLayoutProvider, usePanelLayoutContext }
