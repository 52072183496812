import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { ConfirmModal, ActionIcon } from 'components/Elements'
import { useAsyncDispatch, useConfirmPopup, useNotify } from 'hooks'
import { jobRemovePA } from 'features/job/store'

export const RemoveControl = ({ className = '', onSuccess, id, size }) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  // Remove
  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const onRemoveConfirm = async () => {
    try {
      const data = await onAsyncDispatch(jobRemovePA.request, { id })

      onSuccess && onSuccess({ ...data })

      showNotification({
        type: 'success',
        message: t('job_removed_successfully'),
      })
    } catch (error) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  const { visible, open, close, onConfirm } = useConfirmPopup({ confirm: onRemoveConfirm })
  // --- //

  return (
    <>
      <div>
        <ActionIcon
          className={className}
          onClick={open}
          name={'remove'}
          disabled={loading}
          size={size}
        />
      </div>

      <ConfirmModal visible={visible} onClose={close} onConfirm={onConfirm} />
    </>
  )
}

RemoveControl.propTypes = {
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  id: PropTypes.number,
  size: PropTypes.string,
}
