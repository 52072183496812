import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Anchor } from 'components/Elements'
import { RemoveControl } from 'features/job/components/JobActionControls'

export const JobAction = ({ data = {}, onRecommend, removeControlProps }) => {
  const { t } = useTranslation()

  const { id } = data

  const handleRecommend = e => {
    e.preventDefault()

    onRecommend(id)
  }

  return (
    <div className={'d-flex gap-4 font-size-14 justify-content-end text-nowrap align-items-center'}>
      <Anchor to={``} onClick={handleRecommend} color={'primary'}>
        {t('recommend')}
      </Anchor>

      <RemoveControl {...removeControlProps} id={id} size={'sm'} />
    </div>
  )
}

JobAction.propTypes = {
  data: PropTypes.object,
  onRecommend: PropTypes.func,
  removeControlProps: PropTypes.object,
}
