import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { Alert } from 'components/Elements'

export const DataShower = ({
  isLoading,
  isFailed = false,
  error,
  children,
  spinnerSize = 'lg',
  customLoaderClass = null,
  errorContainerClass = null,
  isFetched = false,
  center = true,
  ...rest
}) => {
  const { t } = useTranslation()

  if (isLoading)
    return (
      <div
        className={classNames({
          // 'data-loader': true,
          'text-center': center,
          [customLoaderClass]: customLoaderClass,
        })}
      >
        <Spinner size={spinnerSize} color="primary" />
      </div>
    )

  if (isFailed) {
    return (
      <div className={errorContainerClass}>
        <Alert type={'error'}>{error || t('error')}</Alert>
      </div>
    )
  }

  if (isFetched) {
    return children
  } else {
    return null
  }
}

DataShower.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired,
  isFetched: PropTypes.bool.isRequired,
  children: PropTypes.node,
  spinnerSize: PropTypes.string,
  customLoaderClass: PropTypes.string,
  errorContainerClass: PropTypes.string,
  center: PropTypes.bool,
  error: PropTypes.string,
}
