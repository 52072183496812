import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NumberFormat } from 'components/Elements'

import { Table } from '../Table'

export const MentorsBookingsBlock = ({ data = {} }) => {
  const { t } = useTranslation()

  const { period, total } = data

  const items = [
    {
      label: t('amount'),
      Cell: () => <NumberFormat value={period} />,
    },
    {
      label: t('total_amount'),
      Cell: () => <NumberFormat value={total} />,
    },
  ]

  return (
    <>
      <h4 className="font-size-18">{t('mentors_bookings')}</h4>

      <Table items={items} />
    </>
  )
}

MentorsBookingsBlock.propTypes = {
  data: PropTypes.object,
}
