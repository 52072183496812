import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { immigrateCategoriesAPI } from 'features/immigrate-category/api'
import {
  IMMIGRATE_CATEGORY_CREATE_REQUEST,
  IMMIGRATE_CATEGORY_CREATE_SUCCESS,
  IMMIGRATE_CATEGORY_CREATE_FAILED,
} from './actionTypes'

export const immigrateCategoryCreatePA = createPromiseAction(
  IMMIGRATE_CATEGORY_CREATE_REQUEST,
  IMMIGRATE_CATEGORY_CREATE_SUCCESS,
  IMMIGRATE_CATEGORY_CREATE_FAILED
)()

function* create(action) {
  try {
    const { params } = action.payload

    const response = yield call(immigrateCategoriesAPI.createCategory, params)
    const { data } = response

    yield put(immigrateCategoryCreatePA.success({}))

    resolvePromiseAction(action, { ...data.data })
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* createSaga() {
  yield takeEvery(immigrateCategoryCreatePA.request, create)
}
