import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { authData } from 'features/auth/store'

export const useLogout = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const logout = async () => {
    dispatch(authData.cleanData({}))
  }

  return {
    logout,
  }
}
