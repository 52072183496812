import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FilterSection } from '../FilterSection'
import { FilterCard } from '../FilterCard'

export const FilterShell = ({ children, variant = 'default', ...props }) => {
  const { t } = useTranslation()

  const Component = variant === 'card' ? FilterCard : FilterSection

  return <Component {...props}>{children}</Component>
}

FilterShell.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['default', 'card']),
}
