import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Row, Col } from 'reactstrap'
import { SearchFormSection } from 'features/filter/components/FormSections'

import { Combobox, FormFieldController } from 'components/FormElements'
import { useSelectOptions } from 'hooks'
import { AdvancedFilter } from './AdvancedFilter'

export const FilterForm = ({ withSearch }) => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const {
    getProfileStatuses,
    getLocationProvinces,
    getGroupedLocations,
    getProfileStudyPrograms,
    getProfileStudyArea,
    getProfileWorkAuthorizations,
    getProfileExperienceYears,
    getSkills,
  } = useSelectOptions()

  const renderSelectField = ({ name, label, options, ...params }) => (
    <div>
      <FormFieldController
        name={name}
        control={control}
        component={Combobox}
        id={name}
        label={label}
        placeholder={label}
        options={options}
        isDetermineValue
        isMulti
        normalize={value => value.map(item => item.value)}
        // menuPortalTarget={document.querySelector('body')}
        {...params}
      />
    </div>
  )

  return (
    <>
      <form>
        {withSearch && (
          <Row>
            <Col lg={8} xl={6}>
              <SearchFormSection />
            </Col>
          </Row>
        )}

        <Row>
          <Col sm={6} lg={4} xl={3}>
            {renderSelectField({
              name: 'profile_statuses',
              label: t('statuses'),
              options: getProfileStatuses({ public_value: true }),
              // isSearchable: false,
            })}
          </Col>

          <Col sm={6} lg={4} xl={3}>
            {renderSelectField({
              name: 'location_provinces',
              label: t('provinces'),
              options: getLocationProvinces(),
            })}
          </Col>

          <Col sm={6} lg={4} xl={3}>
            {renderSelectField({
              name: 'location_cities',
              label: t('cities'),
              options: getGroupedLocations(),
              isGrouped: true,
            })}
          </Col>

          <Col sm={6} lg={4} xl={3}>
            {renderSelectField({
              name: 'profile_study_programs',
              label: t('study_programs'),
              options: getProfileStudyPrograms(),
            })}
          </Col>

          <Col sm={6} lg={4} xl={3}>
            {renderSelectField({
              name: 'profile_study_areas',
              label: t('areas_of_study'),
              options: getProfileStudyArea(),
            })}
          </Col>

          <Col sm={6} lg={4} xl={3}>
            {renderSelectField({
              name: 'profile_work_authorizations',
              label: t('work_authorizations'),
              options: getProfileWorkAuthorizations(),
            })}
          </Col>

          <Col sm={6} lg={4} xl={3}>
            {renderSelectField({
              name: 'profile_experience_year',
              label: t('years_of_experience'),
              options: getProfileExperienceYears(),
              isMulti: false,
              normalize: option => (option !== null ? option.value : null),
              isClearable: true,
            })}
          </Col>

          <Col sm={6} lg={4} xl={3}>
            {renderSelectField({
              name: 'profile_skills',
              label: t('skills'),
              options: getSkills(),
            })}
          </Col>
        </Row>

        <AdvancedFilter />
      </form>
    </>
  )
}

FilterForm.propTypes = {
  withSearch: PropTypes.bool,
}
