import React from 'react'
import { useSelector } from 'react-redux'

import { useUserModel } from 'features/users/hooks'

export const useUserAccount = () => {
  const { data: account } = useSelector(state => state.users.data)

  const userModel = useUserModel()

  const isCandidate = () => {
    return userModel.isCandidate(account)
  }

  const isEmployer = () => {
    return userModel.isEmployer(account)
  }

  const isMentor = () => {
    return userModel.isMentor(account)
  }

  const getName = () => {
    return userModel.getName(account)
  }

  return {
    account,
    isCandidate,
    isEmployer,
    getName,
    isMentor,
  }
}
