import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { employerCompanyBannerAPI } from 'features/users/api'
import { usersEditData } from 'features/users/store'
import {
  USERS_EMPLOYER_COMPANY_BANNER_REMOVE_REQUEST,
  USERS_EMPLOYER_COMPANY_BANNER_REMOVE_SUCCESS,
  USERS_EMPLOYER_COMPANY_BANNER_REMOVE_FAILED,
} from './actionTypes'

export const usersEmployerCompanyBannerRemovePA = createPromiseAction(
  USERS_EMPLOYER_COMPANY_BANNER_REMOVE_REQUEST,
  USERS_EMPLOYER_COMPANY_BANNER_REMOVE_SUCCESS,
  USERS_EMPLOYER_COMPANY_BANNER_REMOVE_FAILED
)()

function* remove(action) {
  try {
    const { id } = action.payload

    const response = yield call(employerCompanyBannerAPI.deleteBanner, id)
    const { data } = response

    yield put(usersEmployerCompanyBannerRemovePA.success({}))

    yield put(usersEditData.updateEmployerProfile({ company_banner: null }))

    resolvePromiseAction(action, {})
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* removeSaga() {
  yield takeEvery(usersEmployerCompanyBannerRemovePA.request, remove)
}
