import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { ImmigrateFAQ } from '../faq'

export const QuestionsRoot = () => {
  return (
    <Routes>
      <Route index element={<ImmigrateFAQ />} />
      <Route path={'*'} element={<Navigate to={'..'} replace={true} />} />;
    </Routes>
  )
}

QuestionsRoot.propTypes = {}
