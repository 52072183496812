import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  error: null,
}

const userLoaderSlice = createSlice({
  name: 'userLoader',
  initialState,
  reducers: {
    getData(state, action) {
      state.loading = true
      state.error = null
    },
    getDataSuccess(state) {
      state.loading = false
    },
    getDataError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

export const userLoader = userLoaderSlice.actions

export default userLoaderSlice.reducer
