import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { SidebarVerticalMenu } from './SidebarVerticalMenu'
import { SidebarDrawer } from './SidebarDrawer'

export const Sidebar = ({ drawerVisible, onDrawerClose }) => {
  const { t } = useTranslation()

  return (
    <>
      <SidebarVerticalMenu />

      <SidebarDrawer visible={drawerVisible} onClose={onDrawerClose} />
    </>
  )
}
