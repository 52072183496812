import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, CardBody, Card } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { LoginForm } from './LoginForm'
import { authLoginPA } from '../../../store'

export const LoginContainer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onSubmit = async values => {
    await dispatch(authLoginPA.request(values))
  }

  return (
    <Row className="justify-content-center align-items-center h-100">
      <Col lg={8} xl={6}>
        <Card className="overflow-hidden">
          <div>
            <Row>
              <Col>
                <div className="text-center p-4">
                  <h3 className={'mb-0'}>{t('login')}</h3>
                </div>
              </Col>
            </Row>
          </div>
          <CardBody>
            <LoginForm onSubmit={onSubmit} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
