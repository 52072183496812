import React from 'react'

import { usePopup } from 'hooks'

export const useConfirmPopup = ({ confirm }) => {
  const { data, visible, open, close, resetData } = usePopup()

  const onConfirm = () => {
    confirm && confirm(data)

    close()
  }

  return { data, visible, open, close, resetData, onConfirm }
}
