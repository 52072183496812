import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'reactstrap'
import classNames from 'classnames'
import { FieldError, FieldHint } from 'components/FormElements'

export const FormField = ({
  render,
  id,
  label,
  meta = {},
  translateParams,
  className = 'mb-3',
  hintText,
  ...props
}) => {
  const { error } = meta

  const showError = Boolean(error)

  const renderLabel = label => {
    if (label) {
      if (typeof label === 'string') {
        return label
      } else return label
    } else return <></>
  }

  return (
    <div
      className={classNames({
        [className]: className,
      })}
    >
      {label && <Label htmlFor={id}>{renderLabel(label)}</Label>}
      <div
        className={classNames('input-group', {
          'is-invalid': showError,
        })}
      >
        {render ? render() : null}
      </div>

      {hintText && <FieldHint message={hintText} />}

      <FieldError error={error} translateParams={translateParams} showError={showError} />
    </div>
  )
}

FormField.propTypes = {
  render: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.any,
  translateParams: PropTypes.object,
  meta: PropTypes.object,
  className: PropTypes.string,
  hintText: PropTypes.string,
}
