import { combineReducers } from 'redux'
import general from './general/slice'
import loader from './loader/slice'

const panelReducer = combineReducers({
  general,
  loader,
})

export default panelReducer
