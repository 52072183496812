import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { userAPI } from 'features/users/api'
import { JOB_CREATE_REQUEST, JOB_CREATE_SUCCESS, JOB_CREATE_FAILED } from './actionTypes'

export const jobCreatePA = createPromiseAction(
  JOB_CREATE_REQUEST,
  JOB_CREATE_SUCCESS,
  JOB_CREATE_FAILED
)()

function* create(action) {
  try {
    const { id, params } = action.payload

    const response = yield call(userAPI.createJob, id, params)
    const { data } = response

    yield put(jobCreatePA.success({}))

    resolvePromiseAction(action, {})
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* createSaga() {
  yield takeEvery(jobCreatePA.request, create)
}
