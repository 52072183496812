import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './SelfIdentificationBlock.module.scss'
import { NoDataTextMessage, TagBadge } from 'components/Elements'

export const SelfIdentificationBlock = ({ data }) => {
  const { t } = useTranslation()

  const { profile_self_identifications, gender, ethnic } = data

  return (
    <>
      <div className={'mb-4'}>
        <h5>{t('self_identification')}</h5>
        {profile_self_identifications && !!profile_self_identifications.length ? (
          profile_self_identifications.map((item, i) => (
            <p key={i} className={'mb-0'}>
              {item.value}
            </p>
          ))
        ) : (
          <NoDataTextMessage variant={'fill'} />
        )}
      </div>

      <div className={'mb-4'}>
        <h5>{t('gender')}</h5>
        {gender ? <p>{gender?.value}</p> : <NoDataTextMessage variant={'fill'} />}
      </div>

      <div className={'mb-4'}>
        <h5>{t('race_ethnicity')}</h5>
        {ethnic ? <p>{ethnic?.value}</p> : <NoDataTextMessage variant={'fill'} />}
      </div>
    </>
  )
}

SelfIdentificationBlock.propTypes = {
  data: PropTypes.object,
}
