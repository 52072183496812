import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink as RouterNavLink } from 'react-router-dom'
import classNames from 'classnames'

import s from './NavLink.module.scss'

export const NavLink = ({ icon, path, label, external, onClick, activeCondition }) => {
  const { t } = useTranslation()

  const render = () => (
    <div className={'d-flex'}>
      {icon && (
        <div>
          <i
            className={classNames(s.icon, {
              [icon]: icon,
            })}
          />
        </div>
      )}
      {label}
    </div>
  )

  return (
    <>
      {external ? (
        <a href={path} target={'_blank'} rel={'noreferrer'} className={s.link}>
          {render()}
        </a>
      ) : (
        <RouterNavLink
          to={path}
          className={({ isActive }) => {
            let result = `${s.link}`
            const activeClass = `${s.link_active}`

            isActive = activeCondition ? activeCondition() : isActive
            if (isActive) {
              result += ` ${activeClass}`
            }
            return result
          }}
          onClick={e => onClick && onClick(e)}
        >
          {render()}
        </RouterNavLink>
      )}
    </>
  )
}
