import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { Header } from './Header'

import { changeLayout } from 'store/actions'

export const BasicLayout = ({ children, headerProps }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(changeLayout('horizontal'))

    return () => {
      dispatch(changeLayout('vertical'))
    }
  }, [dispatch])
  return (
    <div id="layout-wrapper" className={'basic-layout'}>
      <Header {...headerProps} />
      <div className="main-content">
        <div className={'page-content'}>{children ? children : <Outlet />}</div>
      </div>
    </div>
  )
}

BasicLayout.propTypes = {
  children: PropTypes.any,
  headerProps: PropTypes.any,
}
