import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { GeneralBlock } from './GeneralBlock'
import { useUserAccount } from 'features/users/hooks'
import { CandidateContent } from './CandidateContent'
import { EmployerContent } from './EmployerContent'

export const UserDetailsContent = () => {
  const { t } = useTranslation()

  const { isCandidate, isEmployer } = useUserAccount()

  return (
    <>
      <GeneralBlock />

      {isCandidate() ? <CandidateContent /> : isEmployer() ? <EmployerContent /> : null}
    </>
  )
}

UserDetailsContent.propTypes = {}
