import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { employerCompanyBannerAPI } from 'features/users/api'
import { usersEditData } from 'features/users/store'
import {
  USERS_EMPLOYER_COMPANY_BANNER_UPLOAD_REQUEST,
  USERS_EMPLOYER_COMPANY_BANNER_UPLOAD_SUCCESS,
  USERS_EMPLOYER_COMPANY_BANNER_UPLOAD_FAILED,
} from './actionTypes'

export const usersEmployerCompanyBannerUploadPA = createPromiseAction(
  USERS_EMPLOYER_COMPANY_BANNER_UPLOAD_REQUEST,
  USERS_EMPLOYER_COMPANY_BANNER_UPLOAD_SUCCESS,
  USERS_EMPLOYER_COMPANY_BANNER_UPLOAD_FAILED
)()

function* upload(action) {
  try {
    const { id, params } = action.payload

    const response = yield call(employerCompanyBannerAPI.uploadBanner, id, params)
    const { data } = response

    yield put(usersEmployerCompanyBannerUploadPA.success({}))

    yield put(usersEditData.updateEmployerProfile({ company_banner: data?.data }))

    resolvePromiseAction(action, {})
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* uploadSaga() {
  yield takeEvery(usersEmployerCompanyBannerUploadPA.request, upload)
}
