import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button, Modal, ActionIcon } from 'components/Elements'
import { usePopup } from 'hooks'
import { UserSubscriptionUpdate } from 'features/users/components/UserSubscription'

export const SubscriptionUpdateControl = ({ className = '', onSuccess, user, size }) => {
  const { t } = useTranslation()

  const { visible, open, close } = usePopup()

  const onSubmitSuccess = data => {
    close()

    onSuccess(data)
  }

  const onClick = () => {
    open()
  }

  return (
    <>
      <div>
        <ActionIcon name={'edit'} onClick={onClick} className={className} size={size} />
      </div>

      <Modal visible={visible} centered onClose={close} title={t('subscription')}>
        <UserSubscriptionUpdate user={user} onSuccess={onSubmitSuccess} />
      </Modal>
    </>
  )
}

SubscriptionUpdateControl.propTypes = {
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  user: PropTypes.object,
  size: PropTypes.string,
}
