import React from 'react'
import { useSelector } from 'react-redux'
import { ROLE } from 'features/user'
import { selectUserData } from '../../store'

export const useUser = () => {
  const user = useSelector(selectUserData)

  return {
    user,
  }
}
