import { combineReducers } from 'redux'
import data from './data/slice'
import loader from './loader/slice'

const userReducer = combineReducers({
  data,
  loader,
})

export default userReducer
