import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { FormFieldController, TextInput, Combobox } from 'components/FormElements'
import { useSelectOptions } from 'hooks'
import { CTASection } from '../CTASection'

export const CompanySection = ({ withCTA }) => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { getCompanySizes, getIndustries } = useSelectOptions()

  return (
    <>
      <h5>{t('company')}</h5>
      <Row>
        <Col md={6}>
          <FormFieldController
            name={'employer_profile.company_name'}
            control={control}
            component={TextInput}
            id={'employer_profile.company_name'}
            label={t('company_name')}
            placeholder={t('company_name')}
          />
        </Col>

        <Col md={6}>
          <FormFieldController
            name={'employer_profile.industry_id'}
            control={control}
            component={Combobox}
            id={'employer_profile.industry_id'}
            label={t('industry')}
            placeholder={t('industry')}
            options={getIndustries()}
            isDetermineValue
            normalize={option => (option !== null ? option.value : null)}
            menuPortalTarget={document.querySelector('body')}
            isClearable
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormFieldController
            name={'employer_profile.company_website'}
            control={control}
            component={TextInput}
            id={'employer_profile.company_website'}
            label={t('website')}
            placeholder={t('website')}
          />
        </Col>

        <Col md={6}>
          <FormFieldController
            name={'employer_profile.company_size_id'}
            control={control}
            component={Combobox}
            id={'employer_profile.company_size_id'}
            label={t('company_size')}
            placeholder={t('company_size')}
            options={getCompanySizes()}
            isDetermineValue
            normalize={option => (option !== null ? option.value : null)}
            menuPortalTarget={document.querySelector('body')}
            isClearable
          />
        </Col>
      </Row>

      {withCTA && <CTASection />}
    </>
  )
}
