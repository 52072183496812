import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { PageContent, PageHeader } from 'components/Layouts'
import { UserDetailsContainer } from 'features/users/components/User/UserDetails'
import { useUserAccount } from 'features/users/hooks'
import { Header } from 'features/users/components/User/UserDetails/Layout'

export const User = props => {
  const { t } = useTranslation()

  const { isEmployer } = useUserAccount()

  return (
    <>
      <MegaTagTitle title={'user'} />

      <PageContent>
        <PageHeader back headerRight={isEmployer() && <Header />} />

        <UserDetailsContainer />
      </PageContent>
    </>
  )
}

User.propTypes = {}
