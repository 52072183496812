import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Table } from '../Table'

export const JobInvitesBlock = ({ data = {} }) => {
  const { t } = useTranslation()

  const { period, total } = data

  const items = [
    {
      label: t('amount'),
      value: period,
    },
    {
      label: t('total_amount'),
      value: total,
    },
  ]

  return (
    <>
      <h4 className="font-size-18">{t('invites')}</h4>

      <Table items={items} />
    </>
  )
}

JobInvitesBlock.propTypes = {
  data: PropTypes.object,
}
