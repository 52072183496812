import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Combobox } from 'components/FormElements'
import { AddCertification } from './AddCertification'

export const CertificationsField = ({
  id,
  value,
  onChange,
  onOptionAdd,
  options,
  meta,
  translateParams,
}) => {
  const { t } = useTranslation()

  value = value || []

  const onAdd = data => {
    const result = {
      // label: data,
      value: data,
      is_default: false,
    }
    onOptionAdd(result)

    onChange && onChange([...value, { ...result }])
  }

  return (
    <>
      <Combobox
        id={id}
        label={t('certifications')}
        placeholder={t('certifications')}
        value={value}
        meta={meta}
        translateParams={translateParams}
        onChange={onChange}
        options={options}
        isMulti
        getOptionLabel={option => option.value}
      />

      <AddCertification onAdd={onAdd} options={options} />
    </>
  )
}

CertificationsField.propTypes = {
  options: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
  onOptionAdd: PropTypes.func,
  meta: PropTypes.object,
  translateParams: PropTypes.object,
}
