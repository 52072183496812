import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { immigrateQuestionAPI } from 'features/immigrate-question/api'
import {
  IMMIGRATE_QUESTION_EDIT_SUBMIT_REQUEST,
  IMMIGRATE_QUESTION_EDIT_SUBMIT_SUCCESS,
  IMMIGRATE_QUESTION_EDIT_SUBMIT_FAILED,
} from './actionTypes'

export const immigrateQuestionEditSubmitPA = createPromiseAction(
  IMMIGRATE_QUESTION_EDIT_SUBMIT_REQUEST,
  IMMIGRATE_QUESTION_EDIT_SUBMIT_SUCCESS,
  IMMIGRATE_QUESTION_EDIT_SUBMIT_FAILED
)()

function* submit(action) {
  try {
    const { id, params } = action.payload
    const response = yield call(immigrateQuestionAPI.updateQuestion, id, params)
    const { data } = response

    yield put(immigrateQuestionEditSubmitPA.success({}))

    resolvePromiseAction(action, { ...data.data })
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* submitSaga() {
  yield takeEvery(immigrateQuestionEditSubmitPA.request, submit)
}
