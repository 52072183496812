import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { usersUsersEmployersList } from 'features/users/store'
import { OverlayDataShower, Pagination } from 'components/Elements'
import { GeneralUsersTable } from 'features/users/components/Users/Tables'
import { GeneralEmployersFilter } from 'features/employer/components/Employers/Filters'
import { KEYWORDS_MIN_LENGTH } from 'consts/form'

const GeneralEmployersContainerComponent = ({ ...props }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(usersUsersEmployersList.resetDataState({}))
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page, props.filters])

  const fetchList = () => {
    const { keywords, ...restFilters } = props.filters

    let searchFilters = {}
    if (keywords && keywords.trim().length >= KEYWORDS_MIN_LENGTH) {
      searchFilters = {
        keywords,
        search_fields: ['fullname', 'company_name'],
      }
    }

    let params = {
      page: props.page,
      sort: [{ id: 'first_name', desc: false }],
      filters: {
        ...restFilters,
        ...searchFilters,
      },
    }

    dispatch(usersUsersEmployersList.getList({ params }))
  }

  const onPageChange = item => {
    dispatch(usersUsersEmployersList.setPage(item.selected + 1))
  }

  const onFiltersChange = values => {
    dispatch(usersUsersEmployersList.changeFilter({ ...values }))
  }

  const isLoading = useMemo(() => {
    return props.loading
  }, [props.loading])

  const onSubscriptionUpdateSuccess = data => {
    dispatch(
      usersUsersEmployersList.updateListItem({
        ...data,
      })
    )
  }

  return (
    <Card>
      <CardBody>
        <div className={'mb-2'}>
          <GeneralEmployersFilter defaultValues={props.filters} onFiltersChange={onFiltersChange} />
        </div>

        <OverlayDataShower isLoading={isLoading} isFailed={!!props.error} error={props.error}>
          <GeneralUsersTable
            keys={['avatar', 'name', 'email', 'company', 'subscription']}
            items={props.list}
            loading={isLoading}
            onSubscriptionUpdateSuccess={onSubscriptionUpdateSuccess}
          />

          <Pagination data={props.meta} onPageChange={onPageChange} className={'mt-2'} />
        </OverlayDataShower>
      </CardBody>
    </Card>
  )
}

GeneralEmployersContainerComponent.propTypes = {}

const mapStateToProps = state => {
  const { list, loading, error, meta, page, filters } = state.users.users.employers.list
  return {
    list,
    loading,
    error,
    meta,
    page,
    filters,
  }
}

export const GeneralEmployersContainer = connect(mapStateToProps)(
  GeneralEmployersContainerComponent
)
