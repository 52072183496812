import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { immigrateQuestionCreatePA } from 'features/immigrate-question/store'
import { useNotify } from 'hooks'
import { QuestionForm } from '../../QuestionForm'

export const QuestionCreateContainer = ({ categoryId, onSuccess }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async values => {
    const params = {
      ...values,
      immigrate_category_id: categoryId,
    }
    const data = await dispatch(immigrateQuestionCreatePA.request({ params }))

    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess && onSuccess(data)
  }

  return (
    <>
      <QuestionForm onSubmit={onSubmit} />
    </>
  )
}

QuestionCreateContainer.propTypes = {
  categoryId: PropTypes.number,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}
