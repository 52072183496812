import React from 'react'
import logo from 'assets/images/logo/logo-icon.png'

export const Icon = ({ size, ...props }) => {
  let attrs = {
    height: '40px',
  }

  if (size === 'sm') {
    attrs = {
      ...attrs,
      height: '30px',
    }
  }

  return <img src={logo} alt={'logo'} {...attrs} {...props} />
}
