import { all } from 'redux-saga/effects'

import { dataSaga } from './data/saga'
import { usersSaga as listsSaga } from './users/sagas'
import { subscriptionSaga } from './subscription/sagas'
import { userSaga } from './user/sagas'
import { editSaga } from './edit/sagas'
import { employerSaga } from './employer/sagas'

export function* usersSaga() {
  yield all([dataSaga(), listsSaga(), subscriptionSaga(), userSaga(), editSaga(), employerSaga()])
}
