import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './WorkBlock.module.scss'
import { NoDataTextMessage, TagBadge } from 'components/Elements'
import { Works, Projects } from 'features/candidate/components/Profile'

export const WorkBlock = ({ data, asMentor = false }) => {
  const { t } = useTranslation()

  const {
    profile_experience_year,
    student_works,
    student_projects,
    skills,
    industries,
    target_positions,
    profile_certifications,
  } = data

  return (
    <>
      <div className={'mb-4'}>
        <h5>{t('total_years_of_work_experience')}</h5>
        {profile_experience_year ? (
          <p className={'mb-0'}>{profile_experience_year?.value}</p>
        ) : (
          <NoDataTextMessage variant={'data'} />
        )}
      </div>

      <div className={'mb-4'}>
        <h5>{t('work_experience')}</h5>
        {student_works && !!student_works.length ? (
          <Works data={student_works} />
        ) : (
          <NoDataTextMessage variant={'data'} />
        )}
      </div>

      <div className={'mb-4'}>
        <h5>{t('project_and_volunteer')}</h5>
        {student_projects && !!student_projects.length ? (
          <Projects data={student_projects} />
        ) : (
          <NoDataTextMessage variant={'data'} />
        )}
      </div>

      <div className={'mb-4'}>
        <h5>{t('skills')}</h5>

        <div className={'d-flex flex-wrap gap-3'}>
          {skills && !!skills.length ? (
            skills.map((item, i) => <TagBadge key={i}>{item?.value}</TagBadge>)
          ) : (
            <NoDataTextMessage variant={'fill'} />
          )}
        </div>
      </div>

      <div className={'mb-4'}>
        <h5>{asMentor ? t('industry') : t('target_industry')}</h5>

        <div className={'d-flex flex-wrap gap-3'}>
          {industries && !!industries.length ? (
            industries.map((item, i) => <TagBadge key={i}>{item?.value}</TagBadge>)
          ) : (
            <NoDataTextMessage variant={'fill'} />
          )}
        </div>
      </div>

      <div className={'mb-4'}>
        <h5>{asMentor ? t('position_of_expertise') : t('target_position')}</h5>

        <div className={'d-flex flex-wrap gap-3'}>
          {target_positions && !!target_positions.length ? (
            target_positions.map((item, i) => <TagBadge key={i}>{item?.value}</TagBadge>)
          ) : (
            <NoDataTextMessage variant={'fill'} />
          )}
        </div>
      </div>

      <div className={'mb-4'}>
        <h5>{t('certifications')}</h5>

        {profile_certifications && !!profile_certifications.length ? (
          <div>
            {profile_certifications.map((item, i) => (
              <div key={i}>{item?.value}</div>
            ))}
          </div>
        ) : (
          <NoDataTextMessage variant={'data'} />
        )}
      </div>
    </>
  )
}

WorkBlock.propTypes = {
  data: PropTypes.object,
}
