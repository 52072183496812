import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, Alert } from 'components/Elements'
import { validationSchema } from './validation'
import { FormFieldController, Combobox, TextInput } from 'components/FormElements'
import { useFormSubmit } from 'hooks'
import { NormalizeUtils } from 'utils'
import { useBillingSelectOptions } from 'features/billing/hooks'
import { BILLING_TARIFF_ALIAS } from 'features/billing/consts/tariff'

export const UserSubscriptionForm = ({ initialValues, credits, ...props }) => {
  const { t } = useTranslation()

  const defaultValues = {
    alias: null,
    ...initialValues,
  }

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    watch,
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit = async data => {
    try {
      await onFormSubmit(data)
    } catch (error) {}
  }

  const { getTariffsPlans } = useBillingSelectOptions()

  const watchAlias = watch('alias')

  return (
    <>
      <FormProvider {...methods}>
        {submitError && <Alert type={'error'}>{submitError?.message || t('error')}</Alert>}

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormFieldController
            name={'alias'}
            control={control}
            component={Combobox}
            id={'alias'}
            label={t('subscription_plan')}
            placeholder={t('subscription_plan')}
            options={getTariffsPlans()}
            isDetermineValue
            normalize={option => (option !== null ? option.value : null)}
          />

          {watchAlias === BILLING_TARIFF_ALIAS.FREE && (
            <FormFieldController
              name={'credits'}
              control={control}
              component={TextInput}
              id={'credits'}
              label={t('credits')}
              placeholder={t('credits')}
              normalize={NormalizeUtils.onlyNumbers}
              defaultValue={credits !== null ? credits.toString() : '0'}
              shouldUnregister
            />
          )}

          <div className="mt-3 pt-3">
            <div className="text-center">
              <Button type={'submit'} disabled={isSubmitting} loading={isSubmitting}>
                {t('save')}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

UserSubscriptionForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  credits: PropTypes.any,
}
