import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
  loading: false,
  error: null,
  page: 1,
  meta: null,
}

const immigrateQuestionEntriesListSlice = createSlice({
  name: 'immigrateQuestionEntriesList',
  initialState,
  reducers: {
    getList(state) {
      state.loading = true
      state.error = null
    },
    getListSuccess(state, action) {
      state.loading = false
      state.list = action.payload.data
      state.meta = action.payload?.meta
    },
    getListError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setPage(state, action) {
      state.page = action.payload
    },
    addItem(state, action) {
      state.list.push(action.payload)
    },
    updateListItem(state, action) {
      state.list = state.list.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        } else {
          return item
        }
      })
    },
    removeItem(state, action) {
      state.list = state.list.filter(item => item.id !== action.payload.id)
    },
    cleanState() {
      return initialState
    },
  },
})
/**
 * @namespace
 * @property {function} getList
 * @property {function} getListSuccess
 * @property {function} getListError
 * @property {function} setPage
 * @property {function} addItem
 * @property {function} updateListItem
 * @property {function} removeItem
 * @property {function} cleanState
 */

export const immigrateQuestionEntriesList = immigrateQuestionEntriesListSlice.actions

export default immigrateQuestionEntriesListSlice.reducer
