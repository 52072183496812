import React from 'react'
import { useTranslation } from 'react-i18next'

import { ActionIcon } from 'components/Elements'
import { usePopup } from 'hooks'
import { QuestionEdit } from 'features/immigrate-question/components/ImmigrateQuestion'

export const EditControl = ({ data, onSuccess }) => {
  const { t } = useTranslation()

  const { id } = data

  const { visible, open, close } = usePopup()

  const onSubmitSuccess = data => {
    close()

    onSuccess && onSuccess(data)
  }

  return (
    <>
      <div>
        <ActionIcon onClick={open} name={'edit'} />
      </div>

      <QuestionEdit id={id} visible={visible} onClose={close} onSuccess={onSubmitSuccess} />
    </>
  )
}
