import { combineReducers } from 'redux'

import employers from './employers/reducers'
import candidates from './candidates/reducers'
import mentors from './mentors/reducers'

const usersReducer = combineReducers({
  employers,
  candidates,
  mentors,
})

export default usersReducer
