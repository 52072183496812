import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { FormField, Select } from 'components/FormElements'

export const Combobox = ({
  id,
  label,
  placeholder,
  options = [],
  normalize,
  disabled,
  getOptionValue,
  getOptionLabel,
  wrapClassName = 'mb-3',
  isDetermineValue,
  isMulti = false,
  value,
  meta = {},
  translateParams,
  hintText,
  onBlur,
  onDetermine,
  isGrouped,
  ...props
}) => {
  const { error } = meta

  const { t } = useTranslation()
  const showError = Boolean(error)

  const onChange = value => {
    value = normalize ? normalize(value) : value

    if (isMulti && Array.isArray(value)) {
      value = !!value.length ? value : null
    }

    props.onChange && props.onChange(value)
  }

  const determineValue = () => {
    let optionFieldValue = getOptionValue ? getOptionValue : option => option.value

    if (onDetermine) return onDetermine(value, optionFieldValue, options)

    if (value === null || value === undefined) {
      return null
    }

    if (isMulti) {
      if (isGrouped) {
        let result = []
        options.forEach(group => {
          const groupOptions = group.options
          const groupValue = groupOptions.filter(item => value.includes(optionFieldValue(item)))
          result = [...result, ...groupValue]
        })

        return result
      }
      return options.filter(item => value.includes(optionFieldValue(item)))
    } else {
      return options.find(item => optionFieldValue(item) === value)
    }
  }

  return (
    <FormField
      id={id}
      label={label}
      meta={meta}
      hintText={hintText}
      translateParams={translateParams}
      className={'select2-container ' + wrapClassName}
      render={() => (
        <Select
          id={id}
          options={options}
          placeholder={placeholder}
          className={classNames({
            'is-invalid': showError,
          })}
          getOptionLabel={option => (getOptionLabel ? getOptionLabel(option) : `${option.label}`)}
          getOptionValue={option => (getOptionValue ? getOptionValue(option) : `${option.value}`)}
          isDisabled={disabled}
          isMulti={isMulti}
          {...props}
          value={isDetermineValue ? determineValue() : value}
          onChange={option => onChange(option)}
          onBlur={onBlur}
        />
      )}
    />
  )
}

Combobox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  normalize: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  meta: PropTypes.object,
  onChange: PropTypes.func,
  translateParams: PropTypes.object,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  wrapClassName: PropTypes.string,
  isDetermineValue: PropTypes.bool,
  onDetermine: PropTypes.func,
  isMulti: PropTypes.bool,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  isGrouped: PropTypes.bool,
}
