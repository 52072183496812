import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Modal, Button } from 'components/Elements'

export const ConfirmModal = ({
  visible = false,
  children,
  message,
  confirmText,
  onConfirm,
  onCancel,
  ...props
}) => {
  const { t } = useTranslation()

  message = message || t('please_confirm')

  const renderMessage = () => <p className={'font-size-22'}>{message}</p>

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    } else {
      props.onClose()
    }
  }

  return (
    <Modal visible={visible} centered header={false} {...props}>
      {children ? children : renderMessage()}

      <div className="mt-4 d-flex justify-content-end">
        <div className="button-items">
          <Button outline color="light" onClick={handleCancel} size={'md'}>
            {t('cancel')}
          </Button>

          <Button onClick={onConfirm} size={'md'}>
            {confirmText ? confirmText : t('confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.any,
  message: PropTypes.string,
  confirmText: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
}
