import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  title: Yup.string().required('field.error.required'),
  user_id: Yup.mixed()
    .nullable()
    .when('$isEdit', ($isEdit, schema) =>
      !$isEdit ? schema.required('field.error.required') : schema
    ),
  location_province_id: Yup.mixed()
    .nullable()
    .when(['is_remote', 'multiple_locations'], {
      is: (is_remote, multiple_locations) => !(is_remote || multiple_locations),
      then: Yup.mixed().nullable().required('field.error.required'),
    }),
  location_city_id: Yup.mixed()
    .nullable()
    .when(['is_remote', 'multiple_locations'], {
      is: (is_remote, multiple_locations) => !(is_remote || multiple_locations),
      then: Yup.mixed().nullable().required('field.error.required'),
    }),
  pay_range_min: Yup.number()
    .nullable(true)
    .transform((v, o) => (o === '' ? null : v)),
  pay_range_max: Yup.number()
    .nullable(true)
    .transform((v, o) => (o === '' ? null : v)),
  about_this_role: Yup.string().nullable(),
  job_type_id: Yup.mixed().nullable().required('field.error.required'),
  profile_study_program_id: Yup.mixed().nullable().required('field.error.required'),
  profile_study_area_id: Yup.mixed().nullable().required('field.error.required'),
  profile_experience_year_id: Yup.mixed().nullable().required('field.error.required'),
  skills: Yup.mixed().nullable().required('field.error.required'),
  profile_certifications: Yup.mixed().nullable(),
})
