import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { TableView, AvatarProvider, Anchor } from 'components/Elements'
import { Action } from './Action'
import { Subscription } from './Subscription'

export const GeneralUsersTable = ({
  keys = [],
  items,
  loading,
  actionProps,
  jobRecommendControlProps,
  onSubscriptionUpdateSuccess,
  jobInviteControlProps,
  ...props
}) => {
  const { t } = useTranslation()

  const [columns, setColumns] = useState([])

  const columnConfig = {
    avatar: {
      accessorKey: 'avatar',
      id: 'avatar',
      header: null,
      isPlaceholder: true,
      cell: info => <AvatarProvider avatar={info.row.original?.avatar} />,
      __headerProps: {
        style: {
          width: '70px',
        },
      },
    },
    name: {
      accessorKey: 'name',
      id: 'name',
      header: t('name'),
      cell: info => {
        const { id, first_name, last_name } = info.row.original
        return (
          <Anchor to={`/users/${id}`} className={'text-decoration-underline'}>
            {`${first_name} ${last_name}`}
          </Anchor>
        )
      },
    },
    email: {
      accessorKey: 'email',
      id: 'email',
      header: t('email'),
      cell: info => {
        const { id, email } = info.row.original
        return (
          <Anchor to={`/users/${id}`} className={'text-decoration-underline'}>
            {email}
          </Anchor>
        )
      },
    },
    company: {
      accessorKey: 'company_name',
      id: 'company_name',
      header: t('company'),
      cell: info => {
        const { employer_profile } = info.row.original
        return employer_profile?.company_name
      },
    },
    subscription: {
      accessorKey: 'subscription',
      id: 'subscription',
      header: t('subscription'),
      cell: info => (
        <Subscription
          user={info.row.original}
          onSubscriptionUpdateSuccess={onSubscriptionUpdateSuccess}
        />
      ),
    },
    action: {
      accessorKey: 'action',
      header: null,
      isPlaceholder: true,
      cell: info => (
        <Action
          user={info.row.original}
          {...actionProps}
          jobRecommendControlProps={jobRecommendControlProps}
          jobInviteControlProps={jobInviteControlProps}
        />
      ),
    },
  }

  useEffect(() => {
    let config = []

    for (let i = 0; i < keys.length; i++) {
      let key = keys[i]
      if (columnConfig[key]) {
        config.push(columnConfig[key])
      }
    }

    setColumns(config)
  }, [])

  return (
    <>
      <TableView data={items} columns={columns} loading={loading} />
    </>
  )
}

GeneralUsersTable.propTypes = {
  keys: PropTypes.array,
  items: PropTypes.array,
  actionProps: PropTypes.object,
  loading: PropTypes.bool,
  jobRecommendControlProps: PropTypes.object,
  onSubscriptionUpdateSuccess: PropTypes.func,
  jobInviteControlProps: PropTypes.object,
}
