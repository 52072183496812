import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

import { DataShower } from 'components/Elements'
import { usersData } from 'features/users/store'
import { UserDetailsContent } from './UserDetailsContent'
import { UserStatisticsContainer } from 'features/users/components/Statistics'

const UserDetailsContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { id } = useParams()

  useEffect(() => {
    dispatch(usersData.getData({ id }))

    return () => {
      dispatch(usersData.cleanState({}))
    }
  }, [])

  return (
    <>
      <DataShower
        isLoading={props.loading}
        isFetched={!!props.data}
        isFailed={!!props.error}
        error={props.error}
      >
        <Row>
          <Col>
            <UserDetailsContent />
          </Col>
        </Row>

        <Row>
          <Col>
            <UserStatisticsContainer />
          </Col>
        </Row>
      </DataShower>
    </>
  )
}

UserDetailsContainerComponent.propTypes = {}

const mapStateToProps = state => {
  const { data, loading, error } = state.users.data
  return {
    data,
    loading,
    error,
  }
}

export const UserDetailsContainer = connect(mapStateToProps)(UserDetailsContainerComponent)
