import axios from 'axios'

class JobApplicationsAPI {
  exportJobApplications = async params => {
    return axios.get(`api/admin/job-applications/export`, {
      params,
    })
  }
}

export const jobApplicationsAPI = new JobApplicationsAPI()
