import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { userAPI } from 'features/users/api'
import {
  USERS_EDIT_EMPLOYER_UPDATE_REQUEST,
  USERS_EDIT_EMPLOYER_UPDATE_SUCCESS,
  USERS_EDIT_EMPLOYER_UPDATE_FAILED,
} from './actionTypes'

export const usersEditEmployerUpdatePA = createPromiseAction(
  USERS_EDIT_EMPLOYER_UPDATE_REQUEST,
  USERS_EDIT_EMPLOYER_UPDATE_SUCCESS,
  USERS_EDIT_EMPLOYER_UPDATE_FAILED
)()

function* update(action) {
  try {
    const { id, params } = action.payload

    const response = yield call(userAPI.updateEmployerProfile, id, params)
    const { data } = response

    yield put(usersEditEmployerUpdatePA.success({}))

    resolvePromiseAction(action, { ...data })
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* updateSaga() {
  yield takeEvery(usersEditEmployerUpdatePA.request, update)
}
