import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { RadioButton } from './RadioButton'
import { DateRangePicker } from 'components/Controls'

const PERIOD_TYPE = {
  MONTH: 'month',
  CUSTOM: 'custom',
}

const { MONTH, CUSTOM } = PERIOD_TYPE

export const RadioDatePeriodPickerControl = ({
  value = {
    type: MONTH,
    range: [],
  },
  onChange,
}) => {
  const { t } = useTranslation()

  const { type, range } = value

  const onHandleChange = data => {
    onChange({
      ...value,
      ...data,
    })
  }

  const onTypeChange = data => {
    onHandleChange({
      type: data,
    })
  }

  const onRangeChange = data => {
    onHandleChange({
      range: data,
    })
  }

  return (
    <div className={'d-flex align-items-center flex-wrap'}>
      <div className={'me-4'}>
        <RadioButton
          value={MONTH}
          checked={type === MONTH}
          onChange={onTypeChange}
          id={MONTH}
          label={t('month')}
        />
      </div>

      <div>
        <RadioButton
          value={CUSTOM}
          checked={type === CUSTOM}
          onChange={onTypeChange}
          id={CUSTOM}
          render={() => (
            <DateRangePicker
              value={range}
              onChange={onRangeChange}
              id={CUSTOM}
              disabled={type !== CUSTOM}
              placeholder={t('select_period')}
              isClearable={type === CUSTOM}
            />
          )}
        />
      </div>
    </div>
  )
}

RadioDatePeriodPickerControl.propTypes = {
  value: PropTypes.shape({
    type: PropTypes.oneOf([MONTH, CUSTOM]),
    range: PropTypes.array,
  }),
  onChange: PropTypes.func,
}
