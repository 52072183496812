import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Modal } from 'components/Elements'
import { QuestionEditContainer } from './QuestionEditContainer'
import { QuestionDataLoader } from './QuestionDataLoader'

export const QuestionEdit = ({ id, visible, onClose, onSuccess }) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal visible={visible} centered onClose={onClose} title={t('edit_question')} size={'lg'}>
        <QuestionDataLoader id={id}>
          <QuestionEditContainer id={id} onClose={onClose} onSuccess={onSuccess} />
        </QuestionDataLoader>
      </Modal>
    </>
  )
}

QuestionEdit.propTypes = {
  id: PropTypes.number,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}
