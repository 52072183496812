import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'
import { connect, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { JobForm } from '../JobForm'
import { useNotify } from 'hooks'
import { DataShower } from 'components/Elements'
import { jobEditData, jobEditSubmitPA } from 'features/job/store'

export const JobEditComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { showNotification } = useNotify()

  const { id } = useParams()

  useEffect(() => {
    dispatch(jobEditData.getData({ id }))

    return () => {
      dispatch(jobEditData.cleanState({}))
    }
  }, [])

  const onSubmit = async values => {
    await dispatch(jobEditSubmitPA.request({ id, params: values }))

    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    navigate('/jobs')
  }

  const initialValues = useMemo(() => {
    let result = {}

    if (props.data) {
      const {
        location_province,
        location_city,
        pay_range_min,
        pay_range_max,
        profile_experience_year,
        job_type,
        profile_study_program,
        profile_study_area,
        languages,
        skills,
        ...restData
      } = props.data
      result = {
        location_province_id: location_province?.id || null,
        location_city_id: location_city?.id || null,
        profile_experience_year_id: profile_experience_year?.id,
        pay_range_min: pay_range_min !== null ? pay_range_min.toString() : '',
        pay_range_max: pay_range_max !== null ? pay_range_max.toString() : '',
        job_type_id: job_type?.id,
        profile_study_program_id: profile_study_program?.id,
        profile_study_area_id: profile_study_area?.id,
        languages: languages ? languages.map(item => item.id) : [],
        skills: skills ? skills.map(item => item.id) : [],
        ...restData,
      }
    }

    return result
  }, [props.data])

  return (
    <>
      <Card>
        <CardBody>
          <DataShower
            isLoading={props.loading}
            isFetched={!!props.data}
            isFailed={!!props.error}
            error={props.error}
          >
            <JobForm initialValues={initialValues} onSubmit={onSubmit} isEdit />
          </DataShower>
        </CardBody>
      </Card>
    </>
  )
}

JobEditComponent.propTypes = {}

const mapStateToProps = state => {
  const { data, loading, error } = state.job.edit.data
  return {
    data,
    loading,
    error,
  }
}

export const JobEdit = connect(mapStateToProps)(JobEditComponent)
