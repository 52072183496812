import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './SubBar.module.scss'
import { Anchor } from 'components/Elements'
import { DateUtils } from 'utils'

export const SubBar = ({ data = {}, withJobInvite = false, jobInvite, ...props }) => {
  const { t } = useTranslation()

  const { student_profile } = data

  const { profile_status } = student_profile || {}

  const job = jobInvite?.job

  return profile_status || withJobInvite ? (
    <div>
      <div className={'font-size-14'}>
        {profile_status && <span className={'fw-medium'}>{profile_status?.public_value}</span>}

        {withJobInvite && (
          <>
            <span className={'fw-light'}>
              {profile_status && ' | '}
              {`${t('invited_for')}: `}
              <span className={'fw-medium'}>
                <Anchor
                  to={`/jobs/${job?.id}`}
                  color={'dark'}
                  className={'text-decoration-underline'}
                >
                  {job?.title}
                </Anchor>
              </span>

              <>
                {' | '}
                {`${t('sent_on')}: `}
                <span className={'fw-normal'}>
                  {jobInvite?.created_at && DateUtils.format(jobInvite?.created_at, 'YYYY/MM/DD')}
                </span>
              </>
            </span>
          </>
        )}
      </div>
    </div>
  ) : null
}

SubBar.propTypes = {
  data: PropTypes.object,
  jobInvite: PropTypes.object,
  withJobInvite: PropTypes.bool,
}
