import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { MegaTagTitle } from 'components/Elements'
import { PageContent, PageHeader } from 'components/Layouts'
import { EmployerProfileEdit } from 'features/users/components/User/UserEdit'
import { useUserModel } from 'features/users/hooks'
import { DataShower } from 'components/Elements'
import { usersEditData } from 'features/users/store'

const UserEditComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { isEmployer } = useUserModel()

  const { id } = useParams()

  useEffect(() => {
    dispatch(usersEditData.getData({ id }))

    return () => {
      dispatch(usersEditData.cleanState({}))
    }
  }, [])

  return (
    <>
      <MegaTagTitle title={'edit_user'} />

      <PageContent>
        <PageHeader back />

        <DataShower
          isLoading={props.loading}
          isFetched={!!props.data}
          isFailed={!!props.error}
          error={props.error}
        >
          {isEmployer(props.data) ? <EmployerProfileEdit /> : null}
        </DataShower>
      </PageContent>
    </>
  )
}

UserEditComponent.propTypes = {}

const mapStateToProps = state => {
  const { data, loading, error } = state.users.edit.data
  return {
    data,
    loading,
    error,
  }
}

export const UserEdit = connect(mapStateToProps)(UserEditComponent)
