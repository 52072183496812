import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './Header.module.scss'

export const Header = ({ title, rightSection, ...props }) => {
  const { t } = useTranslation()

  return (
    <div className={'d-flex flex-grow-1 gap-3'}>
      <div className={'d-flex flex-grow-1 gap-4'}>
        <div className={s.text}>
          <h3>{title}</h3>
        </div>
      </div>

      {rightSection ? rightSection : null}
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  rightSection: PropTypes.node,
}
