import { combineReducers } from 'redux'

import layout from './layout/reducer'
import auth from 'features/auth/store/reducers'
import user from 'features/user/store/reducers'
import panel from 'features/panel/store/reducers'
import staticReducer from 'features/static/store/reducers'
import job from 'features/job/store/reducers'
import users from 'features/users/store/reducers'
import statistics from 'features/statistics/store/reducers'
import immigrateCategory from 'features/immigrate-category/store/reducers'
import immigrateQuestion from 'features/immigrate-question/store/reducers'
import immigrate from 'features/immigrate/store/reducers'

const rootReducer = combineReducers({
  layout,
  auth,
  user,
  panel,
  static: staticReducer,
  job,
  users,
  statistics,
  immigrateCategory,
  immigrateQuestion,
  immigrate,
})

export default rootReducer
