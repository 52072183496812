import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Jobs } from 'features/job/routes/jobs'
import { JobRoot } from 'features/job/routes/job'
import { JobCreate } from 'features/job/routes/create'

export const JobsRoot = () => {
  return (
    <Routes>
      <Route index element={<Jobs />} />

      <Route path={'create'} element={<JobCreate />} />

      <Route path={':id/*'} element={<JobRoot />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}

JobRoot.propTypes = {}
