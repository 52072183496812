import axios from 'axios'

class ImmigrateCategoriesAPI {
  createCategory = async params => {
    return axios.post('api/admin/immigrate-categories', params)
  }

  getCategories = async params => {
    return axios.get(`api/admin/immigrate-categories`, {
      params,
    })
  }
}

export const immigrateCategoriesAPI = new ImmigrateCategoriesAPI()
