import React from 'react'
import PropTypes from 'prop-types'

import { FieldError, FieldHint } from 'components/FormElements'
import { RadioDatePeriodPickerControl } from 'components/Controls'
import classNames from 'classnames'

export const RadioDatePeriodPickerField = ({
  meta = {},
  translateParams,
  className = 'mb-3',
  hintText,
  ...props
}) => {
  const { error } = meta

  const showError = Boolean(error)

  return (
    <>
      <div
        className={classNames({
          [className]: className,
        })}
      >
        <div
          className={classNames('', {
            'is-invalid': showError,
          })}
        >
          <RadioDatePeriodPickerControl {...props} />
        </div>

        {hintText && <FieldHint message={hintText} />}

        <FieldError error={error} translateParams={translateParams} showError={showError} />
      </div>
    </>
  )
}

RadioDatePeriodPickerField.propTypes = {
  translateParams: PropTypes.object,
  meta: PropTypes.object,
  className: PropTypes.string,
  hintText: PropTypes.string,
}
