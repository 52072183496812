import { all } from 'redux-saga/effects'

import { createSaga } from './create/saga'
import { dataSaga } from './data/saga'
import { jobsSaga } from './jobs/sagas'
import { candidatesSaga } from './candidates/sagas'
import { recommendationsSaga } from './recommendations/sagas'
import { editSaga } from './edit/sagas'
import { removeSaga } from './remove/saga'
import { jobSaga as currentJobSaga } from './job/sagas'
import { inviteSaga } from './invite/sagas'

export function* jobSaga() {
  yield all([
    createSaga(),
    dataSaga(),
    jobsSaga(),
    candidatesSaga(),
    recommendationsSaga(),
    editSaga(),
    removeSaga(),
    currentJobSaga(),
    inviteSaga(),
  ])
}
