import React from 'react'
import PropTypes from 'prop-types'

import { OverlayLoading } from '../OverlayLoading'
import s from './OverlayBlockLoading.module.scss'

export const OverlayBlockLoading = ({ isLoading, children }) => {
  return (
    <div className={s.block}>
      {children}

      <OverlayLoading isLoading={isLoading} />
    </div>
  )
}

OverlayBlockLoading.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.any,
}
