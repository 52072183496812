import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { LoadingScreen, Alert } from 'components/Elements'
import { panelGeneral, panelLoader } from 'features/panel/store'

export const PrivateLayout = () => {
  const dispatch = useDispatch()

  const { loading, error, prepared } = useSelector(state => state.panel.loader)

  useEffect(() => {
    dispatch(panelLoader.loadData())

    return () => {
      dispatch(panelGeneral.resetState())
    }
  }, [])

  return loading ? (
    <LoadingScreen />
  ) : prepared ? (
    <Outlet />
  ) : error ? (
    <Alert type={'error'} className={'mx-3 my-3'}>
      {error}
    </Alert>
  ) : null
}
