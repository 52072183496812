import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { ApplicationsBlock } from './Blocks'

export const Sidebar = () => {
  const { t } = useTranslation()

  return (
    <div>
      <div>
        <ApplicationsBlock />
      </div>
    </div>
  )
}

Sidebar.propTypes = {}
