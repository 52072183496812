import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { JobInviteForm } from '../JobInviteForm'
import { jobInviteSubmitPA } from 'features/job/store'
import { useNotify } from 'hooks'
import { jobInviteHelper } from 'features/job/helpers'

export const JobInviteCreate = ({ onSuccess, candidate, job, jobSelect }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async values => {
    const { message, job_id } = values

    const id = jobSelect ? job_id : job?.id

    const params = {
      receiver_id: candidate?.id,
      message,
    }

    await dispatch(jobInviteSubmitPA.request({ id, params }))

    showNotification({
      type: 'success',
      message: t('invite_submitted_successfully'),
    })

    onSuccess()
  }

  return (
    <>
      <JobInviteForm
        initialValues={{
          message: jobSelect
            ? ''
            : jobInviteHelper.message.createDefaultMessage({ candidate, job }),
        }}
        onSubmit={onSubmit}
        jobSelect={jobSelect}
        candidate={candidate}
      />
    </>
  )
}

JobInviteCreate.propTypes = {
  onSuccess: PropTypes.func,
  candidate: PropTypes.object,
  job: PropTypes.object,
  jobSelect: PropTypes.bool,
}
