import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Row, Col } from 'reactstrap'
import { IconPlus, IconMinus } from '@tabler/icons'

import { Combobox, FormFieldController } from 'components/FormElements'
import { useSelectOptions } from 'hooks'
import { Anchor } from 'components/Elements'
import { SearchFormSection } from 'features/filter/components/FormSections'

export const AdvancedFilter = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const [visible, setVisible] = useState(false)

  const onVisibleToggle = () => {
    setVisible(prevState => !prevState)
  }

  const onVisibleClick = e => {
    e.preventDefault()

    onVisibleToggle()
  }

  const {
    getIndustries,
    getTargetPositions,
    getLanguages,
    getSchools,
    getProfileWillingRelocates,
    getProfileObjectives,
    getBooleanOptions,
    getProfileSearchStatuses,
  } = useSelectOptions()

  const renderSelectField = ({ name, label, options, ...params }) => (
    <div>
      <FormFieldController
        name={name}
        control={control}
        component={Combobox}
        id={name}
        label={label}
        placeholder={label}
        options={options}
        isDetermineValue
        isMulti
        normalize={value => value.map(item => item.value)}
        // menuPortalTarget={document.querySelector('body')}
        {...params}
      />
    </div>
  )

  return (
    <>
      {visible && (
        <>
          <Row>
            <Col sm={6} lg={4} xl={3}>
              {renderSelectField({
                name: 'profile_search_statuses',
                label: t('job_search_status'),
                options: getProfileSearchStatuses(),
              })}
            </Col>

            <Col sm={6} lg={4} xl={3}>
              {renderSelectField({
                name: 'industries',
                label: t('target_industry'),
                options: getIndustries(),
              })}
            </Col>

            <Col sm={6} lg={4} xl={3}>
              {renderSelectField({
                name: 'target_positions',
                label: t('target_positions'),
                options: getTargetPositions(),
              })}
            </Col>

            <Col sm={6} lg={4} xl={3}>
              {renderSelectField({
                name: 'languages',
                label: t('languages'),
                options: getLanguages(),
              })}
            </Col>

            <Col sm={6} lg={4} xl={3}>
              {renderSelectField({
                name: 'schools',
                label: t('schools'),
                options: getSchools(),
              })}
            </Col>

            <Col sm={6} lg={4} xl={3}>
              {renderSelectField({
                name: 'profile_willing_relocate',
                label: t('willing_to_relocate'),
                options: getProfileWillingRelocates(),
              })}
            </Col>

            <Col sm={6} lg={4} xl={3}>
              {renderSelectField({
                name: 'profile_objectives',
                label: t('looking_for'),
                options: getProfileObjectives(),
              })}
            </Col>

            <Col sm={6} lg={4} xl={3}>
              {renderSelectField({
                name: 'has_resume',
                label: t('have_resume'),
                options: getBooleanOptions(),
              })}
            </Col>
          </Row>

          <Row>
            <Col lg={8} xl={6}>
              <SearchFormSection
                field={{
                  name: 'profile_certifications',
                  placeholder: t('certifications'),
                }}
                keywordsMinLength={2}
              />
            </Col>
          </Row>
        </>
      )}

      <div className={'d-flex justify-content-end'}>
        <Anchor
          to={'/'}
          leftIcon={
            visible ? (
              <IconMinus className={'text-primary'} />
            ) : (
              <IconPlus className={'text-primary'} />
            )
          }
          className={'text-decoration-underline'}
          onClick={onVisibleClick}
        >
          {t('advanced_search_filters')}
        </Anchor>
      </div>
    </>
  )
}

AdvancedFilter.propTypes = {}
