import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Input, Label } from 'reactstrap'

export const RadioButton = ({
  id,
  label,
  value,
  color,
  size,
  onChange,
  checked,
  disabled,
  className = '',
  render,
}) => {
  const renderLabel = label => {
    if (label) {
      if (typeof label === 'string') {
        return label
      } else return label
    } else return <></>
  }

  return (
    <div
      className={classNames('form-check', {
        [`form-check-${color}`]: color,
        [`form-check_size_${size}`]: size,
        [className]: className,
      })}
    >
      <div className="form-check-wrap">
        <Input
          className={classNames('form-check-input', {})}
          type="radio"
          id={id}
          value={value}
          checked={checked}
          onChange={event => onChange(event.target.value)}
          disabled={disabled}
          style={value === 'custom' ? { marginTop: '0.785rem' } : {}}
        />

        {label && (
          <Label className="form-check-label" htmlFor={id}>
            {renderLabel(label)}
          </Label>
        )}

        {render && render()}
      </div>
    </div>
  )
}

RadioButton.propTypes = {
  id: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  color: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  render: PropTypes.func,
}
