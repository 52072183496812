import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { PageContent } from 'components/Layouts'
import { GeneralMentorsContainer } from 'features/users/components/Users/Mentors'

export const Mentors = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'mentors'} />

      <PageContent>
        <GeneralMentorsContainer />
      </PageContent>
    </>
  )
}

Mentors.propTypes = {}
