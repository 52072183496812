import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { RecommendControl } from 'features/job/components/JobActionControls'

export const JobRecommendControl = ({ candidate = {}, onSuccess, job }) => {
  const { t } = useTranslation()

  const { id, recommended_for_job } = candidate

  const onRecommendSuccess = data => {
    onSuccess({ id, ...data })
  }

  return (
    <>
      <RecommendControl
        recommended={recommended_for_job}
        id={job?.id}
        candidate={candidate}
        onSuccess={onRecommendSuccess}
        label={recommended_for_job ? t('remove') : t('add')}
      />
    </>
  )
}

JobRecommendControl.propTypes = {
  candidate: PropTypes.object,
  onSuccess: PropTypes.func,
  job: PropTypes.any,
}
