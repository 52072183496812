import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { User } from '../user'
import { UserEdit } from '../edit'

export const UserRoot = () => {
  return (
    <Routes>
      <Route index element={<User />} />
      <Route path={'edit'} element={<UserEdit />} />
      <Route path={'*'} element={<Navigate to={'..'} replace={true} />} />;
    </Routes>
  )
}

UserRoot.propTypes = {}
