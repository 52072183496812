import axios from 'axios'

class EmployerCompanyBannerAPI {
  uploadBanner = async (id, data) => {
    const formData = new FormData()
    formData.append('company_banner', data.company_banner)

    return axios.post(`api/admin/users/${id}/employer/company-banner`, formData)
  }

  deleteBanner = async id => {
    return axios.delete(`api/admin/users/${id}/employer/company-banner`)
  }
}

export const employerCompanyBannerAPI = new EmployerCompanyBannerAPI()
