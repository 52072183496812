import axios from 'axios'

class ImmigrateQuestionAPI {
  getQuestion = async (id, params) => {
    return axios.get(`api/admin/immigrate-questions/${id}`, {
      params,
    })
  }

  updateQuestion = async (id, params) => {
    return axios.put(`api/admin/immigrate-questions/${id}`, params)
  }

  deleteQuestion = async id => {
    return axios.delete(`api/admin/immigrate-questions/${id}`)
  }
}

export const immigrateQuestionAPI = new ImmigrateQuestionAPI()
