import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { ApplicationsRoot } from '../applications'

export const CandidatesRoot = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={'applications'} replace={true} />} />

      <Route path={'applications/*'} element={<ApplicationsRoot />} />

      <Route path={'*'} element={<Navigate to={'..'} replace={true} />} />
    </Routes>
  )
}

CandidatesRoot.propTypes = {}
