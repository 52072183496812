import { takeLatest, fork, put, all, call } from 'redux-saga/effects'

import i18n from 'i18n'
import { jobAPI } from '../../../../../api'
import { jobJobCandidatesApplicationsList } from './slice'
import { RequestUtils } from 'utils'

function* getList({ payload: { id, params } }) {
  try {
    let requestParams = {
      includes: ['studentProfile', 'avatar', 'sentJobApplication', 'recommendedForJob'],
    }

    requestParams = RequestUtils.getMapRequestParams(params, requestParams)

    const response = yield call(jobAPI.getJobCandidates, id, requestParams)

    const { data } = response

    const {
      meta: { current_page, last_page },
    } = data

    if (current_page && last_page && current_page > last_page) {
      yield put(
        jobJobCandidatesApplicationsList.getList({
          params: {
            ...params,
            page: last_page,
          },
        })
      )
    } else {
      yield put(jobJobCandidatesApplicationsList.getListSuccess(data))
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(jobJobCandidatesApplicationsList.getListError(message))
  }
}

function* watchGetList() {
  yield takeLatest(jobJobCandidatesApplicationsList.getList, getList)
}

export function* listSaga() {
  yield all([fork(watchGetList)])
}
