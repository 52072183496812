import React from 'react'

import { tariffHelper } from 'features/billing/helpers'

export const useTariffStaticOptions = () => {
  const isEnterprise = alias => {
    return tariffHelper.general.isEnterprise(alias)
  }

  return {
    isEnterprise,
  }
}
