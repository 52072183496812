import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './ApplicationBlock.module.scss'
import { ContactLink } from 'features/users/components/Elements'
import { FileUtils, FormatUtils } from 'utils'
import { Anchor } from 'components/Elements'

export const ApplicationBlock = ({ data = {}, ...props }) => {
  const { t } = useTranslation()

  const { email, phone, resume } = data

  const onDownload = e => {
    e.preventDefault()

    FileUtils.downloadFileByLink(resume?.url)
  }

  return (
    <>
      <div className={'d-flex flex-wrap gap-1 justify-content-between'}>
        <div className={'d-flex flex-wrap gap-2'}>
          <ContactLink href={`mailto:${email}`}>
            <i className={'bx bx-envelope'} />
          </ContactLink>

          <ContactLink href={`tel:${FormatUtils.formatPhone(phone)}`}>
            <i className={'bx bx-phone'} />
          </ContactLink>
        </div>

        {resume && (
          <Anchor
            to={''}
            className={`text-primary fw-medium`}
            color={'primary'}
            onClick={onDownload}
          >
            {t('download_resume')}
          </Anchor>
        )}
      </div>
    </>
  )
}

ApplicationBlock.propTypes = {
  data: PropTypes.object,
}
