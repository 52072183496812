import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'
import classNames from 'classnames'

import s from './FilterCard.module.scss'

import { FilterSection } from '../FilterSection'

export const FilterCard = ({ children, className = '', ...props }) => {
  const { t } = useTranslation()

  return (
    <Card
      className={classNames(s.root, {
        [className]: className,
      })}
    >
      <CardBody>
        <FilterSection {...props}>{children}</FilterSection>
      </CardBody>
    </Card>
  )
}

FilterCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  reset: PropTypes.bool,
  onReset: PropTypes.func,
}
