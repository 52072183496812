import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ButtonLink, LogoIcon } from 'components/Elements'
import { ProfileMenu } from './ProfileMenu'
import { usePanelLayout } from 'hooks'

export const Header = ({ onDrawerOpen }) => {
  const { t } = useTranslation()

  const { config } = usePanelLayout()
  const { headerRightOptions } = config

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box d-lg-none d-md-block">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <LogoIcon type={'icon'} />
              </span>
            </Link>
          </div>

          <button
            type="button"
            onClick={onDrawerOpen}
            className="btn btn-sm px-3 font-size-16 header-item d-lg-none d-md-block"
            id="vertical-menu-btn"
          >
            <i className="fa fa-fw fa-bars" />
          </button>
        </div>
        <div className="d-flex align-items-center">
          {headerRightOptions.visible && (
            <div className={'me-4 pe-4'}>{headerRightOptions.render()}</div>
          )}

          <ProfileMenu />
        </div>
      </div>
    </header>
  )
}
