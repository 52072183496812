import React, { useReducer } from 'react'

const initialState = {
  data: null,
  loading: false,
  error: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loading: true,
      }
    case 'fetched':
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case 'error':
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case 'reset':
      return initialState
    default:
      return state
  }
}

export const useFetch = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const fetch = async (request, ...args) => {
    dispatch({ type: 'loading' })

    try {
      const response = await request(...args)
      const { data } = response

      dispatch({ type: 'fetched', payload: data })

      return data
    } catch (error) {
      dispatch({ type: 'error', payload: error })
    }
  }

  const resetState = () => {
    dispatch({ type: 'reset' })
  }

  return {
    ...state,
    fetch,
    resetState,
  }
}
