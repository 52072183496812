import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'

export const PanelSwitch = ({ ...props }) => {
  const location = useLocation()

  let path = '/dashboard'

  return <Navigate to={path} state={{ from: location }} />
}

PanelSwitch.propTypes = {}
