import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoggedIn: false,
  token: null,
}

const authDataSlice = createSlice({
  name: 'authData',
  initialState,
  reducers: {
    setData(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn
      state.token = action.payload.token
    },
    cleanData() {
      return initialState
    },
  },
})

export const authData = authDataSlice.actions

export const selectAuthData = state => state.auth.data

export default authDataSlice.reducer
