import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Row, Col } from 'reactstrap'

import { FormFieldController, RadioDatePeriodPickerField } from 'components/FormElements'

export const FilterForm = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <>
      <form>
        <Row>
          <Col>
            <FormFieldController
              name={'period'}
              control={control}
              component={RadioDatePeriodPickerField}
            />
          </Col>
        </Row>
      </form>
    </>
  )
}

FilterForm.propTypes = {}
