import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Elements'
import { FILE_MAX_SIZE } from 'consts/core'
import { useNotify } from 'hooks'
import { FileUtils, ValidationsUtils } from 'utils'
import s from './ImageManagerControl.module.scss'
import placeholder from 'assets/images/placeholders/image-placeholder-square.svg'

export const ImageManagerControl = ({
  image,
  onUpload,
  onRemove,
  uploading = false,
  removing = false,
  uploadLabel,
  deleteLabel,
}) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [selectedPhoto, setSelectedPhoto] = useState(null)

  const inputRef = useRef(null)

  const selectPhoto = event => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  useEffect(() => {
    if (image) {
      setSelectedPhoto(null)
    }
  }, [image])

  const onInputChange = event => {
    if (!event.target.files) return

    const file = event.target.files[0]

    const isValid = ValidationsUtils.fileMaxSize(file, FILE_MAX_SIZE)

    if (!isValid) {
      const message = t('file.max_size.error', {
        text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
      })
      showNotification({ type: 'error', message: message })

      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setSelectedPhoto(reader.result)
      }
    }
    reader.readAsDataURL(file)

    onUpload(file)

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  uploadLabel = uploadLabel || t('upload_image')
  deleteLabel = deleteLabel || t('delete_image')

  return (
    <>
      <div className={s.block}>
        <div className={s.imgBlock}>
          <img
            className={s.img}
            src={selectedPhoto ? selectedPhoto : image ? image : placeholder}
            alt={'banner'}
          />
        </div>

        <input
          type="file"
          onChange={onInputChange}
          ref={inputRef}
          accept="image/png, image/jpeg"
          className={'d-none'}
        />

        <div className={s.controlsBlock}>
          <Button
            onClick={selectPhoto}
            size={'sm'}
            disabled={uploading || removing}
            loading={uploading}
          >
            {uploadLabel}
          </Button>

          {image && (
            <Button
              outline
              color={'danger'}
              onClick={onRemove}
              size={'sm'}
              disabled={uploading || removing}
              loading={removing}
            >
              {deleteLabel}
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

ImageManagerControl.propTypes = {
  image: PropTypes.string,
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
  uploading: PropTypes.bool,
  removing: PropTypes.bool,
  uploadLabel: PropTypes.string,
  deleteLabel: PropTypes.string,
}
