import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { Combobox, FormFieldController } from 'components/FormElements'
import { useSelectOptions } from 'hooks'

export const LocationFormSection = () => {
  const { t } = useTranslation()

  const { control, watch, setValue } = useFormContext()

  const { getLocationProvinces, getLocationCities } = useSelectOptions()

  const watchProvince = watch('location_province_id')

  const onProvinceChange = value => {
    setValue('location_province_id', value)
    setValue('location_city_id', null)
  }

  return (
    <>
      <h5>{t('job_location')}</h5>
      <Row>
        <Col md={6}>
          <FormFieldController
            name={'location_province_id'}
            control={control}
            component={Combobox}
            id={'location_province_id'}
            label={t('province')}
            placeholder={t('province')}
            options={getLocationProvinces()}
            isDetermineValue
            normalize={option => (option !== null ? option.value : null)}
            onChange={onProvinceChange}
          />
        </Col>

        {watchProvince && (
          <Col md={6}>
            <FormFieldController
              name={'location_city_id'}
              control={control}
              component={Combobox}
              id={'location_city_id'}
              label={t('city')}
              placeholder={t('city')}
              options={getLocationCities({ location_province_id: watchProvince })}
              isDetermineValue
              normalize={option => (option !== null ? option.value : null)}
            />
          </Col>
        )}
      </Row>
    </>
  )
}
