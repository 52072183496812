import React from 'react'
import { useTranslation } from 'react-i18next'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useLocation } from 'react-router-dom'

import s from './SidebarContent.module.scss'
import { NavLink } from './NavLink'
import { APP_NAME } from 'consts/core'

export const SidebarContent = () => {
  const { t } = useTranslation()

  const { pathname } = useLocation()

  const navs = [
    {
      // title: t(''),
      menu: [
        {
          path: '/dashboard',
          label: t('dashboard'),
        },
      ],
    },
    {
      title: t('jobs'),
      menu: [
        {
          path: '/jobs',
          label: t('jobs'),
          activeCondition: () => pathname === '/jobs',
        },
      ],
    },
    {
      title: t('users'),
      menu: [
        {
          path: '/users/employers',
          label: t('employers'),
        },
        {
          path: '/users/candidates',
          label: t('candidates'),
        },
        {
          path: '/users/mentors',
          label: t('mentors'),
        },
      ],
    },
    {
      title: t('immigrate'),
      menu: [
        {
          path: '/immigrate/faq',
          label: t('faqs'),
        },
      ],
    },
  ]

  return (
    <div className={s.wrapper}>
      <PerfectScrollbar>
        <div className={'d-flex flex-column'}>
          {navs.map(({ title, menu }, i) => (
            <div className={s.navBlock} key={i}>
              <div className={s.navBlock__title}>{title}</div>
              <div className={s.navBlock__menu}>
                {menu.map((item, index) => (
                  <NavLink key={index} {...item} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </PerfectScrollbar>
      <div className={s.navBottomBlock}>
        <div className={s.footer}>
          {t('copyright')} {APP_NAME} {new Date().getFullYear()}
        </div>
      </div>
    </div>
  )
}
