import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect } from 'react-use'

import { jobCandidatesList } from 'features/job/store'
import { OverlayDataShower, Pagination } from 'components/Elements'
import { GeneralUsersTable } from 'features/users/components/Users/Tables'
import { GeneralCandidatesFilter } from 'features/candidate/components/Candidates/Filters'

const TargetCandidatesContainerComponent = ({ job, ...props }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(jobCandidatesList.cleanState({}))
    }
  }, [])

  useDeepCompareEffect(() => {
    fetchList()
  }, [props.page, props.filters])

  const fetchList = () => {
    const { profile_certifications, ...restFilters } = props.filters

    let certificationsFilters = {}
    if (profile_certifications && profile_certifications.trim().length >= 2) {
      certificationsFilters = {
        profile_certifications: profile_certifications.trim(),
      }
    }

    let params = {
      page: props.page,
      for_job: job?.id,
      sort: [{ id: 'candidate_score', desc: true }],
      filters: {
        ...restFilters,
        ...certificationsFilters,
      },
    }

    dispatch(jobCandidatesList.getList({ params }))
  }

  const onPageChange = item => {
    dispatch(jobCandidatesList.setPage(item.selected + 1))
  }

  const onFiltersChange = values => {
    dispatch(jobCandidatesList.changeFilter({ ...values }))
  }

  const isLoading = useMemo(() => {
    return props.loading
  }, [props.loading])

  const onRecommendSuccess = data => {
    dispatch(jobCandidatesList.updateListItem(data))
  }

  return (
    <>
      <div className={'mb-2'}>
        <GeneralCandidatesFilter defaultValues={props.filters} onFiltersChange={onFiltersChange} />
      </div>

      <OverlayDataShower isLoading={isLoading} isFailed={!!props.error} error={props.error}>
        <GeneralUsersTable
          keys={['avatar', 'name', 'email', 'action']}
          items={props.list}
          loading={isLoading}
          actionProps={{
            config: {
              jobRecommend: true,
            },
          }}
          jobRecommendControlProps={{ onSuccess: onRecommendSuccess, job }}
        />

        <Pagination data={props.meta} onPageChange={onPageChange} className={'mt-2'} />
      </OverlayDataShower>
    </>
  )
}

TargetCandidatesContainerComponent.propTypes = {
  job: PropTypes.object,
}

const mapStateToProps = state => {
  const { list, loading, error, meta, page, filters } = state.job.candidates.list
  return {
    list,
    loading,
    error,
    meta,
    page,
    filters,
  }
}

export const TargetCandidatesContainer = connect(mapStateToProps)(
  TargetCandidatesContainerComponent
)
