import { takeLatest, fork, put, all, call } from 'redux-saga/effects'

import i18n from 'i18n'
import { immigrateQuestionsAPI } from 'features/immigrate-question/api'
import { immigrateQuestionEntriesList } from './slice'
import { RequestUtils } from 'utils'

function* getList({ payload: { params } }) {
  try {
    const requestParams = RequestUtils.getMapRequestParams(params)

    const response = yield call(immigrateQuestionsAPI.getQuestions, requestParams)

    const { data } = response

    const meta = data?.meta

    const { current_page, last_page } = meta ?? {}

    if (current_page && last_page && current_page > last_page) {
      yield put(
        immigrateQuestionEntriesList.getList({
          params: {
            ...params,
            page: last_page,
          },
        })
      )
    } else {
      yield put(immigrateQuestionEntriesList.getListSuccess(data))
    }
  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(immigrateQuestionEntriesList.getListError(message))
  }
}

function* watchGetList() {
  yield takeLatest(immigrateQuestionEntriesList.getList, getList)
}

export function* listSaga() {
  yield all([fork(watchGetList)])
}
