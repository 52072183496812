import * as Yup from 'yup'

import { userFormHelper } from 'features/users/helpers'

const { generalValidation } = userFormHelper.general
const { employerProfileValidation } = userFormHelper.employerProfile

export const validationSchema = Yup.object().shape({
  ...generalValidation,

  ...employerProfileValidation,
})
