import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const NoDataTextMessage = ({ children, variant = 'empty' }) => {
  const { t } = useTranslation()

  const message =
    variant === 'empty'
      ? t('empty')
      : variant === 'fill'
      ? t('not_filled')
      : variant === 'data'
      ? t('no_data')
      : variant === 'info'
      ? t('no_info')
      : ''

  return <p className={'mb-0'}>{children ? children : message}</p>
}

NoDataTextMessage.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['empty', 'fill', 'data', 'info']),
}
