import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { General } from './General'
import { Info } from './Info'

export const JobDetailsContent = () => {
  const { t } = useTranslation()

  return (
    <>
      <General />

      <div className={'mt-4'}>
        <Info />
      </div>
    </>
  )
}

JobDetailsContent.propTypes = {}
