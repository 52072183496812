import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { JobInviteControl, JobRecommendControl } from './Controls'

export const CardActionBar = ({
  config = {},
  candidate = {},
  jobInviteControlProps,
  jobRecommendControlProps,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <div className={'d-flex gap-2'}>
      {config.jobInvite && <JobInviteControl candidate={candidate} {...jobInviteControlProps} />}

      {config.jobRecommend && (
        <JobRecommendControl candidate={candidate} {...jobRecommendControlProps} />
      )}
    </div>
  )
}

CardActionBar.propTypes = {
  config: PropTypes.object,
  candidate: PropTypes.object,
  jobInviteControlProps: PropTypes.object,
}
