import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { immigrateQuestionAPI } from 'features/immigrate-question/api'
import {
  IMMIGRATE_QUESTION_REMOVE_REQUEST,
  IMMIGRATE_QUESTION_REMOVE_SUCCESS,
  IMMIGRATE_QUESTION_REMOVE_FAILED,
} from './actionTypes'

export const immigrateQuestionRemovePA = createPromiseAction(
  IMMIGRATE_QUESTION_REMOVE_REQUEST,
  IMMIGRATE_QUESTION_REMOVE_SUCCESS,
  IMMIGRATE_QUESTION_REMOVE_FAILED
)()

function* remove(action) {
  try {
    const { id } = action.payload

    const response = yield call(immigrateQuestionAPI.deleteQuestion, id)
    const { data } = response

    yield put(immigrateQuestionRemovePA.success({}))

    resolvePromiseAction(action, { id })
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* removeSaga() {
  yield takeEvery(immigrateQuestionRemovePA.request, remove)
}
