import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { MegaTagTitle } from 'components/Elements'
import { PageContent } from 'components/Layouts'
import { StatisticsContainer } from 'features/dashboard/components/Statistics'

export const Dashboard = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'dashboard'} />

      <PageContent>
        <Row>
          <Col>
            <StatisticsContainer />
          </Col>
        </Row>
      </PageContent>
    </>
  )
}

Dashboard.propTypes = {}
