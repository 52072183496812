import { takeLatest, fork, put, all, call } from 'redux-saga/effects'

import i18n from 'i18n'
import { usersUserStatisticsData } from './slice'
import { userAPI } from '../../../../api'

function* getData({ payload: { id, params = {} } }) {
  try {
    const response = yield call(userAPI.getUserStatistics, id, params)

    const { data } = response

    yield put(usersUserStatisticsData.getDataSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(usersUserStatisticsData.getDataError(message))
  }
}

function* watchGetData() {
  yield takeLatest(usersUserStatisticsData.getData, getData)
}

export function* dataSaga() {
  yield all([fork(watchGetData)])
}
