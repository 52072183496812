import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { optionsHelper } from 'helpers'

const getMonth = optionsHelper.month.getMonth

export const Projects = ({ data = [] }) => {
  const { t } = useTranslation()

  const renderPeriod = ({
    date_start_month,
    date_start_year,
    date_end_month,
    date_end_year,
    is_present,
  }) => {
    const start = `${getMonth(date_start_month)?.label} ${date_start_year}`
    const end = is_present ? t('present') : `${getMonth(date_end_month)?.label} ${date_end_year}`
    return `${start} - ${end}`
  }

  const renderProject = ({ title, duration, ...period }) => (
    <>
      <span>{title}</span>
      <br />
      <span>{renderPeriod({ ...period })}</span>
    </>
  )

  return (
    <div className={''}>
      {data.map((item, i) => (
        <div key={i} className={i !== 0 ? 'mt-4' : ''}>
          {renderProject(item)}
        </div>
      ))}
    </div>
  )
}

Projects.propTypes = {
  data: PropTypes.array,
}
