import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'
import { useDispatch } from 'react-redux'

import { JobForm } from '../JobForm'
import { jobCreatePA } from 'features/job/store'
import { useNotify } from 'hooks'

export const JobCreate = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async values => {
    const { user_id, ...restValues } = values

    await dispatch(jobCreatePA.request({ id: user_id, params: restValues }))

    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <Card>
        <CardBody>
          <JobForm defaultValues onSubmit={onSubmit} />
        </CardBody>
      </Card>
    </>
  )
}

JobCreate.propTypes = {}
