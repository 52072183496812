import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { jobAPI } from '../../../api'
import {
  JOB_EDIT_SUBMIT_REQUEST,
  JOB_EDIT_SUBMIT_SUCCESS,
  JOB_EDIT_SUBMIT_FAILED,
} from './actionTypes'

export const jobEditSubmitPA = createPromiseAction(
  JOB_EDIT_SUBMIT_REQUEST,
  JOB_EDIT_SUBMIT_SUCCESS,
  JOB_EDIT_SUBMIT_FAILED
)()

function* submit(action) {
  try {
    const { id, params } = action.payload
    const response = yield call(jobAPI.updateJob, id, params)
    const { data } = response

    yield put(jobEditSubmitPA.success({}))

    resolvePromiseAction(action, {})
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* submitSaga() {
  yield takeEvery(jobEditSubmitPA.request, submit)
}
