import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useLogout } from 'features/auth'

export const LogoutLink = () => {
  const { t } = useTranslation()

  const { logout } = useLogout()

  const onClick = async e => {
    e.preventDefault()

    await logout()
  }

  return (
    <Link to="/" onClick={onClick} className="dropdown-item">
      <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
      <span>{t('logout')}</span>
    </Link>
  )
}
