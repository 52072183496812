import i18n from 'i18n'

export const getBooleanOptions = () => {
  return [
    {
      label: i18n.t('yes'),
      value: true,
    },
    {
      label: i18n.t('no'),
      value: false,
    },
  ]
}
