import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

// import { InviteControl } from 'features/job/components/JobActionControls'

export const JobInviteControl = ({ candidate = {}, onSuccess, job, blocked = false, ...props }) => {
  const { t } = useTranslation()

  const { id, has_invite } = candidate

  const onInviteSuccess = data => {
    onSuccess({ id, has_invite: true })
  }

  return (
    <>
      {/*<InviteControl*/}
      {/*  invited={has_invite}*/}
      {/*  candidate={candidate}*/}
      {/*  job={job}*/}
      {/*  onSuccess={onInviteSuccess}*/}
      {/*  blocked={blocked}*/}
      {/*  {...props}*/}
      {/*/>*/}
    </>
  )
}

JobInviteControl.propTypes = {
  candidate: PropTypes.object,
  onSuccess: PropTypes.func,
  job: PropTypes.any,
  blocked: PropTypes.bool,
}
