import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Alert as RSAlert } from 'reactstrap'

export const Alert = ({ type, children, className = '', ...props }) => {
  const color = useMemo(() => {
    if (props.color) return props.color
    if (type) {
      if (type === 'success') return 'success'
      if (type === 'error') return 'danger'
    }
    return undefined
  }, [props.color, type])

  return (
    <RSAlert className={className} {...props} color={color}>
      {children}
    </RSAlert>
  )
}

Alert.propTypes = {
  type: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
}
