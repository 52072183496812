import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from 'components/Elements'
import { LoginContainer } from 'features/auth/components'

export const Login = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'login'} />

      <LoginContainer />
    </>
  )
}
