import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { SubscriptionBadge } from 'features/billing/components/Elements'
import { SubscriptionUpdateControl } from 'features/users/components/UserActionControls'

export const Subscription = ({ user = {}, onSubscriptionUpdateSuccess }) => {
  const { t } = useTranslation()

  const onUpdateSuccess = data => {
    onSubscriptionUpdateSuccess({ id: user?.id, subscription: data?.data })
  }

  return (
    <div className={'d-flex flex-wrap align-items-center'}>
      {user?.subscription ? <SubscriptionBadge alias={user?.subscription?.tariff?.alias} /> : '-'}

      <SubscriptionUpdateControl
        user={user}
        onSuccess={onUpdateSuccess}
        className={'ms-3'}
        size={'sm'}
      />
    </div>
  )
}

Subscription.propTypes = {
  user: PropTypes.object,
  onSubscriptionUpdateSuccess: PropTypes.func,
}
