import React from 'react'
import PropTypes from 'prop-types'
import { FormProvider } from 'react-hook-form'

import { useFilterForm } from 'features/filter/hooks'
import { FilterShell } from 'features/filter/components'
import { EMPLOYERS_FILTERS_INITIAL_VALUES } from 'features/employer/consts/filters'
import { FilterForm } from '../FilterForm'

export const GeneralEmployersFilter = ({
  defaultValues,
  onFiltersChange,
  withSearch = false,
  layout,
}) => {
  const { methods, isApplied, onReset } = useFilterForm({
    config: {
      defaultValues: {
        ...defaultValues,
      },
    },
    onFiltersChange,
    initialValues: {
      ...EMPLOYERS_FILTERS_INITIAL_VALUES,
    },
  })

  return (
    <FormProvider {...methods}>
      <FilterShell variant={layout} reset={isApplied} onReset={onReset}>
        <FilterForm withSearch={withSearch} />
      </FilterShell>
    </FormProvider>
  )
}

GeneralEmployersFilter.propTypes = {
  onFiltersChange: PropTypes.func,
  defaultValues: PropTypes.object,
  layout: PropTypes.oneOf(['default', 'card']),
}
