import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { jobApplicationsAPI } from 'features/job-application/api'
import {
  JOB_APPLICATION_EXPORT_FETCH_REQUEST,
  JOB_APPLICATION_EXPORT_FETCH_SUCCESS,
  JOB_APPLICATION_EXPORT_FETCH_FAILED,
} from './actionTypes'

export const jobApplicationExportFetchPA = createPromiseAction(
  JOB_APPLICATION_EXPORT_FETCH_REQUEST,
  JOB_APPLICATION_EXPORT_FETCH_SUCCESS,
  JOB_APPLICATION_EXPORT_FETCH_FAILED
)()

function* fetch(action) {
  try {
    const { params } = action.payload

    const response = yield call(jobApplicationsAPI.exportJobApplications, params)

    const { data } = response

    yield put(jobApplicationExportFetchPA.success({}))

    resolvePromiseAction(action, { ...data })
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* fetchSaga() {
  yield takeEvery(jobApplicationExportFetchPA.request, fetch)
}
