import axios from 'axios'

class JobsAPI {
  createJob = async params => {
    return axios.post('api/jobs', params)
  }

  getJobs = async params => {
    return axios.get(`api/jobs`, {
      params,
    })
  }
}

export const jobsAPI = new JobsAPI()
