import React from 'react'
import { useTranslation } from 'react-i18next'

import { ActionIcon } from 'components/Elements'
import { usePopup } from 'hooks'
import { QuestionCreate } from 'features/immigrate-question/components/ImmigrateQuestion'

export const AddControl = ({ data, onSuccess }) => {
  const { t } = useTranslation()

  const { id } = data

  const { visible, open, close } = usePopup()

  const onSubmitSuccess = question => {
    close()

    onSuccess && onSuccess(id, question)
  }

  return (
    <>
      <div>
        <ActionIcon onClick={open} name={'add'} outline={false} />
      </div>

      <QuestionCreate
        categoryId={id}
        visible={visible}
        onClose={close}
        onSuccess={onSubmitSuccess}
      />
    </>
  )
}
