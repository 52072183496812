import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { FormField } from 'components/FormElements'
import { BasicEditor } from 'features/rich-text-editor/components'

export const EditorField = ({
  id,
  label,
  placeholder,
  fieldRef,
  children,
  normalize,
  disabled,
  value,
  meta = {},
  translateParams,
  wrapClassName = 'mb-3',
  hintText,
  onChange,
  ...props
}) => {
  const { error } = meta

  const showError = Boolean(error)

  const onEditorChange = (event, editor) => {
    if (onChange) {
      const data = editor.getData()
      onChange(data)
    }
  }

  return (
    <>
      <FormField
        id={id}
        label={label}
        meta={meta}
        hintText={hintText}
        translateParams={translateParams}
        className={wrapClassName}
        render={() => (
          <div className={'w-100'}>
            <BasicEditor
              id={id}
              placeholder={placeholder}
              data={value}
              onChange={onEditorChange}
              disabled={disabled}
              onBlur={props.onBlur}
              {...props}
              // editorRef={fieldRef}
            />
          </div>
        )}
      />
    </>
  )
}

EditorField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.any,
  placeholder: PropTypes.string,
  fieldRef: PropTypes.any,
  children: PropTypes.any,
  normalize: PropTypes.func,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  translateParams: PropTypes.object,
  value: PropTypes.any,
  meta: PropTypes.object,
  wrapClassName: PropTypes.string,
}
