import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { UserRoot } from 'features/users/routes/user'
import { Employers, Candidates, Mentors } from 'features/users/routes/users'

export const UsersRoot = () => {
  return (
    <Routes>
      <Route path={'employers'} element={<Employers />} />

      <Route path={'candidates'} element={<Candidates />} />

      <Route path={'mentors'} element={<Mentors />} />

      <Route path={':id/*'} element={<UserRoot />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}

UsersRoot.propTypes = {}
