import axios from 'axios'

class UserAPI {
  getUser = async (id, params) => {
    return axios.get(`api/users/${id}`, {
      params,
    })
  }

  updateEmployerProfile = async (id, params) => {
    return axios.post(`api/admin/users/${id}/employer`, params)
  }

  updateUserSubscription = async (id, params) => {
    return axios.post(`api/admin/users/${id}/subscription`, params)
  }

  createJob = async (id, params) => {
    return axios.post(`api/admin/users/${id}/jobs`, params)
  }

  getUserStatistics = async (id, params) => {
    return axios.get(`api/admin/users/${id}/statistics`, {
      params,
    })
  }
}

export const userAPI = new UserAPI()
