import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { AvatarProvider } from 'components/Elements'
import s from './GeneralBlock.module.scss'

export const GeneralBlock = ({ data = {} }) => {
  const { t } = useTranslation()

  const { company_logo, company_name, industry } = data

  return (
    <>
      <Card className={'w-100'}>
        <CardBody>
          <div className={s.block}>
            <div className={s.avatarWrap}>
              <AvatarProvider
                avatar={company_logo}
                type={'thumb'}
                size={'large'}
                variant={'logo'}
              />
            </div>
            <div>
              <div>
                <h4 className={'mb-0'}>{company_name}</h4>
                {industry && <p className={'text-muted mt-2 mb-0 fw-light'}>{industry?.value}</p>}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

GeneralBlock.propTypes = {
  data: PropTypes.object,
}
