import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Edit } from './Actions'

export const Header = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={'d-flex flex-wrap gap-4'}>
        <Edit />
      </div>
    </>
  )
}

Header.propTypes = {}
