import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useAsyncDispatch, useNotify } from 'hooks'
import { jobRecommendationsSubmitPA } from 'features/job/store'
import { Button } from 'components/Elements'
import classNames from 'classnames'

export const RecommendControl = ({
  id,
  recommended = false,
  candidate = {},
  className = '',
  onSuccess,
  label,
}) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const { loading, onAsyncDispatch } = useAsyncDispatch()

  const onSubmit = async e => {
    e.preventDefault()

    try {
      const newStatus = !recommended

      const data = await onAsyncDispatch(jobRecommendationsSubmitPA.request, {
        id,
        user: candidate?.id,
        recommended: newStatus,
      })

      onSuccess && onSuccess({ ...data })
    } catch (error) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return (
    <div>
      <Button
        className={className}
        onClick={onSubmit}
        outline={recommended}
        disabled={loading}
        size={'sm'}
        width={'w-sm'}
        loading={loading}
      >
        {label ? label : recommended ? t('not_recommend') : t('recommend')}
      </Button>
    </div>
  )
}

RecommendControl.propTypes = {
  id: PropTypes.number,
  recommended: PropTypes.bool,
  candidate: PropTypes.object,
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  label: PropTypes.string,
}
