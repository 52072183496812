import PropTypes from 'prop-types'
import React from 'react'
import { RootNavigator } from 'routes'

const App = props => {
  return (
    <>
      <RootNavigator />
    </>
  )
}

App.propTypes = {}

export default App
