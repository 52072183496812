import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { useUser } from 'features/user'

export const RouteGuard = ({ children, roles, guards = [], ...props }) => {
  const location = useLocation()

  const { user } = useUser()

  const withGuard = type => {
    return guards.some(guard => guard === type)
  }

  if (roles && user && !roles.includes(user.role)) {
    const path = '/dashboard'
    return <Navigate to={path} state={{ from: location }} />
  }

  return <>{children}</>
}

RouteGuard.propTypes = {
  children: PropTypes.any,
  roles: PropTypes.array,
}
