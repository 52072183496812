import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './TitleBlock.module.scss'
import { Anchor, AvatarProvider, TagBadge } from 'components/Elements'
import { SubBar } from './SubBar'

export const TitleBlock = ({
  data = {},
  config = {
    jobInvite: false,
  },
  jobInvite,
  ...props
}) => {
  const { t } = useTranslation()

  const { id, first_name, last_name, avatar, student_profile } = data

  const { profile_willing_relocate, profile_work_authorizations, profile_status } =
    student_profile || {}

  const getName = () => {
    return `${first_name} ${last_name}`
  }

  return (
    <div className={'d-flex'}>
      <div>
        <AvatarProvider avatar={avatar} className={'me-2'} />
      </div>

      <div className={'d-flex flex-column justify-content-center'}>
        <div className={'d-flex flex-wrap gap-1'}>
          <h6 className={'mb-0'}>
            <Anchor to={`/users/${id}`} color={'dark'} className={'text-decoration-underline'}>
              {getName()}
            </Anchor>
          </h6>
          <div className={'d-flex flex-wrap gap-1'}>
            {profile_willing_relocate && (
              <div className={s.tagBlock}>
                <TagBadge size={'xs'}>{`${t('relocate')}: ${
                  profile_willing_relocate?.value
                }`}</TagBadge>
              </div>
            )}

            {profile_work_authorizations &&
              !!profile_work_authorizations.length &&
              profile_work_authorizations.map((item, i) => (
                <div key={i} className={s.tagBlock}>
                  <TagBadge size={'xs'}>{item?.value}</TagBadge>
                </div>
              ))}
          </div>
        </div>
        <SubBar data={data} withJobInvite={config?.jobInvite} jobInvite={jobInvite} />
      </div>
    </div>
  )
}

TitleBlock.propTypes = {
  data: PropTypes.object,
  config: PropTypes.object,
  jobInvite: PropTypes.object,
}
