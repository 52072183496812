import React from 'react'
import { useSelector } from 'react-redux'
import { selectStaticSelectsOptions } from '../../store'

export const useStaticSelects = () => {
  const options = useSelector(selectStaticSelectsOptions)

  const getSelectOptions = key => {
    return options?.[key] || []
  }

  return {
    options,
    getSelectOptions,
  }
}
