import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import { SearchFormSection } from 'features/filter/components/FormSections'

export const FilterForm = () => {
  const { t } = useTranslation()

  return (
    <>
      <form>
        <Row>
          <Col lg={8} xl={6}>
            <SearchFormSection />
          </Col>
        </Row>
      </form>
    </>
  )
}

FilterForm.propTypes = {}
