import { all, fork, put, takeEvery } from 'redux-saga/effects'
import { userLoader } from 'features/user/store'
import { panelLoader } from '../loader/slice'
import { panelGeneral } from './slice'

function* resetState() {
  // clean preparing panel data
  yield put(panelLoader.cleanState())

  // clean fetching user state
  yield put(userLoader.cleanState())
}

export function* watchResetState() {
  yield takeEvery(panelGeneral.resetState, resetState)
}

export function* generalSaga() {
  yield all([fork(watchResetState)])
}
