import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './Body.module.scss'
import { Bubble } from '../Bubble'
import { HtmlContent } from 'features/rich-text-editor/components'

export const Body = ({ value, ...props }) => {
  const { t } = useTranslation()

  return (
    <div className={'d-flex gap-3'}>
      <div className={'d-flex flex-grow gap-4'}>
        <div>
          <Bubble />
        </div>

        <div className={s.text}>
          <HtmlContent content={value} />
        </div>
      </div>
    </div>
  )
}

Body.propTypes = {
  value: PropTypes.string,
}
