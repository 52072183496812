import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { useUserAccount } from 'features/users/hooks'

export const Basic = () => {
  const { t } = useTranslation()

  const { account } = useUserAccount()

  const { employer_profile } = account

  const { profile_position } = employer_profile

  return (
    <>
      <Card>
        <CardBody>
          <div>
            <h5>{t('position')}</h5>
            <p className={'mb-0'}>{profile_position?.value}</p>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

Basic.propTypes = {}
