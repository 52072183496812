import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { TagBadge } from 'components/Elements'

export const PaidJobBadge = ({ size = 'md', color = 'success' }) => {
  const { t } = useTranslation()

  return (
    <>
      <TagBadge size={size} color={color}>
        {t('paid')}
      </TagBadge>
    </>
  )
}

PaidJobBadge.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}
