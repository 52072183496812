import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import dataReducer from './data/slice'

const authDataPersistConfig = {
  key: 'authData',
  storage,
}

const authReducer = combineReducers({
  data: persistReducer(authDataPersistConfig, dataReducer),
})

export default authReducer
