import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { useJob } from 'features/job/hooks'
import s from './Info.module.scss'
import { NoDataTextMessage, TagBadge } from 'components/Elements'

export const Info = () => {
  const { t } = useTranslation()

  const { job } = useJob()
  const {
    about_this_role,
    profile_study_program,
    profile_study_area,
    skills,
    languages,
    profile_certifications,
    job_url,
  } = job

  return (
    <Card>
      <CardBody>
        <div className={'mb-4'}>
          <h5>{t('about_this_role')}</h5>
          {about_this_role ? (
            <div className={s.about}>{about_this_role}</div>
          ) : (
            <NoDataTextMessage variant={'info'} />
          )}
        </div>

        <div className={'mb-4'}>
          <h5>{t('study_program')}</h5>
          <p>{profile_study_program?.value}</p>
        </div>

        <div className={'mb-4'}>
          <h5>{t('area_of_study')}</h5>
          <p>{profile_study_area?.value}</p>
        </div>

        <div className={'mb-4'}>
          <h5>{t('skills')}</h5>

          <div className={'d-flex flex-wrap gap-3'}>
            {skills && !!skills.length ? (
              skills.map((item, i) => <TagBadge key={i}>{item?.value}</TagBadge>)
            ) : (
              <NoDataTextMessage variant={'info'} />
            )}
          </div>
        </div>

        <div className={'mb-4'}>
          <h5>{t('languages')}</h5>

          <div className={'d-flex flex-wrap gap-3'}>
            {languages && !!languages.length ? (
              languages.map((item, i) => <TagBadge key={i}>{item?.value}</TagBadge>)
            ) : (
              <NoDataTextMessage variant={'info'} />
            )}
          </div>
        </div>

        <div className={'mb-4'}>
          <h5>{t('certifications')}</h5>

          {profile_certifications && !!profile_certifications.length ? (
            <div>
              {profile_certifications.map((item, i) => (
                <div key={i}>{item?.value}</div>
              ))}
            </div>
          ) : (
            <NoDataTextMessage variant={'data'} />
          )}
        </div>

        {job_url && (
          <div className={'mt-4'}>
            <h5>{t('original_job_url')}</h5>
            <p>
              <a href={job_url} target={'_blank'} rel="noreferrer" className={'text-primary'}>
                {t('open')}
              </a>
            </p>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

Info.propTypes = {}
