import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './JobInfo.module.scss'
import { Anchor } from 'components/Elements'
import { PaidJobBadge } from 'features/job/components/Elements'

export const JobInfo = ({ data = {} }) => {
  const { t } = useTranslation()

  const { id, title } = data

  return (
    <div>
      <Anchor to={`/jobs/${id}`} className={'text-decoration-underline'}>
        {title}
      </Anchor>

      {data?.featured && (
        <div>
          <PaidJobBadge size={'xs'} />
        </div>
      )}
    </div>
  )
}

JobInfo.propTypes = {
  data: PropTypes.object,
}
