import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { FormFieldController, TextInput, PhoneInput } from 'components/FormElements'

export const GeneralFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <>
      <Row>
        <Col md={6}>
          <FormFieldController
            name={'first_name'}
            control={control}
            component={TextInput}
            id={'first_name'}
            label={t('first_name')}
            placeholder={t('first_name')}
          />
        </Col>

        <Col md={6}>
          <FormFieldController
            name={'last_name'}
            control={control}
            component={TextInput}
            id={'last_name'}
            label={t('last_name')}
            placeholder={t('last_name')}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormFieldController
            name={'email'}
            control={control}
            component={TextInput}
            id={'email'}
            label={t('email')}
            placeholder={t('email')}
            readOnly
          />
        </Col>

        <Col md={6}>
          <FormFieldController
            name={'phone'}
            control={control}
            component={PhoneInput}
            id={'phone'}
            label={t('phone')}
            placeholder={t('phone')}
          />
        </Col>
      </Row>
    </>
  )
}
