import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button, Modal } from 'components/Elements'
import { usePopup } from 'hooks'
import { JobInviteCreate } from 'features/job/components/JobInvite'

export const InviteControl = ({
  invited = false,
  className = '',
  onSuccess,
  candidate,
  job,
  blocked = false,
  jobSelect,
}) => {
  const { t } = useTranslation()

  const { visible, open, close } = usePopup()

  const onSubmitSuccess = data => {
    // close()

    onSuccess && onSuccess(data)
  }

  return (
    <>
      <div>
        <Button
          className={className}
          onClick={open}
          outline={invited}
          disabled={invited}
          size={'sm'}
          width={'w-sm'}
        >
          {invited ? t('invited') : t('invite')}
        </Button>
      </div>

      <Modal visible={visible} centered onClose={close} title={t('invite')}>
        <JobInviteCreate
          job={job}
          candidate={candidate}
          onSuccess={onSubmitSuccess}
          jobSelect={jobSelect}
        />
      </Modal>
    </>
  )
}

InviteControl.propTypes = {
  invited: PropTypes.bool,
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  candidate: PropTypes.object,
  job: PropTypes.object,
  blocked: PropTypes.bool,
  jobSelect: PropTypes.bool,
}
