import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { LogoIcon } from 'components/Elements'

export const Header = ({ rightSection }) => {
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <LogoIcon type={'icon'} />
                </span>
                <span className="logo-lg">
                  <LogoIcon />
                </span>
              </Link>
            </div>
          </div>

          <div className="d-flex">{rightSection}</div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  rightSection: PropTypes.any,
}
