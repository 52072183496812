import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmationControl } from 'components/Controls'
import { useQuestionRemove } from 'features/immigrate-question/hooks'
import { ActionIcon } from 'components/Elements'

export const RemoveControl = ({ data, onSuccess }) => {
  const { t } = useTranslation()

  const { loading, onSubmit } = useQuestionRemove()

  const onRemoveConfirm = async () => {
    await onSubmit(data?.id, onSuccess)
  }

  return (
    <>
      <ConfirmationControl
        onConfirm={onRemoveConfirm}
        render={({ onOpen }) => <ActionIcon onClick={onOpen} name={'remove'} disabled={loading} />}
      />
    </>
  )
}
