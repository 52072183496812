import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { Combobox, FormFieldController } from 'components/FormElements'
import { useEmployersFetch } from 'features/users/hooks'

export const AsyncCompanyFormSection = ({ ...props }) => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { data, loading, onFetch } = useEmployersFetch()

  useEffect(() => {
    onFetch({
      pagination: false,
      sort: [{ id: 'company_name', desc: false }],
    })
  }, [])

  return (
    <>
      <Row>
        <Col md={12}>
          <FormFieldController
            name={'user_id'}
            control={control}
            component={Combobox}
            id={'user_id'}
            label={t('company')}
            placeholder={t('company')}
            options={data?.data}
            isDetermineValue
            normalize={option => (option !== null ? option.id : null)}
            isLoading={loading}
            getOptionLabel={option => option?.employer_profile?.company_name}
            getOptionValue={option => option.id}
            {...props}
          />
        </Col>
      </Row>
    </>
  )
}
