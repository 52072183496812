import React from 'react'
import { Navigate } from 'react-router-dom'
import { PanelLayout, PrivateLayout } from 'components/Layouts'
import { PanelSwitch, RouteGuard } from 'routes'
import { getGeneralRoutes } from './routes'

const mapChildrenRoutes = routs => [
  ...routs.map(({ path, index, element, roles, guards }, i) => {
    return {
      key: `${i}-${path}`,
      path,
      index,
      element: (
        <RouteGuard roles={roles} guards={guards}>
          {element}
        </RouteGuard>
      ),
    }
  }),
]

export const getPrivateRoutes = (isLoggedIn, user) => {
  return [
    {
      path: '/*',
      element: isLoggedIn ? <PrivateLayout /> : <Navigate to={`/login`} replace />,
      children: [
        {
          index: true,
          element: <PanelSwitch />,
        },
        {
          element: <PanelLayout />,
          children: [...mapChildrenRoutes(getGeneralRoutes())],
        },
        {
          path: '*',
          element: <Navigate to={`/`} replace />,
        },
      ],
    },
  ]
}
