import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { immigrateCategoryEditSubmitPA } from 'features/immigrate-category/store'
import { useNotify } from 'hooks'
import { CategoryForm } from '../../CategoryForm'

export const CategoryEditContainer = ({ onSuccess }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const { data: category } = useSelector(state => state.immigrateCategory.edit.data)

  const onSubmit = async values => {
    const data = await dispatch(
      immigrateCategoryEditSubmitPA.request({ id: category?.id, params: values })
    )

    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess && onSuccess(data)
  }

  return (
    <>
      <CategoryForm initialValues={{ ...category }} onSubmit={onSubmit} />
    </>
  )
}

CategoryEditContainer.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}
