import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { FormFieldController, TextInput } from 'components/FormElements'

export const CTASection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <>
      <h5>{t('cta')}</h5>
      <Row>
        <Col>
          <FormFieldController
            name={'employer_profile.company_cta_text'}
            control={control}
            component={TextInput}
            id={'employer_profile.company_cta_text'}
            label={t('cta_text')}
            placeholder={t('cta_text')}
            type={'textarea'}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormFieldController
            name={'employer_profile.company_cta_button_text'}
            control={control}
            component={TextInput}
            id={'employer_profile.company_cta_button_text'}
            label={t('cta_button_text')}
            placeholder={t('cta_button_text')}
          />
        </Col>

        <Col md={6}>
          <FormFieldController
            name={'employer_profile.company_cta_button_link'}
            control={control}
            component={TextInput}
            id={'employer_profile.company_cta_button_link'}
            label={t('cta_button_link')}
            placeholder={t('cta_button_link')}
          />
        </Col>
      </Row>
    </>
  )
}
