import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { getData } from 'features/user/store/loader/saga'
import { getData as getSelects } from 'features/static/store/selects/saga'
import i18n from 'i18n'
import { panelLoader } from './slice'

function* loadData() {
  try {
    const [user, selects] = yield all([call(getData), call(getSelects)])

    yield put(panelLoader.loadDataSuccess({}))
  } catch (error) {
    const message = error?.response?.data?.message || i18n.t('error')
    yield put(panelLoader.loadDataError(message))
  }
}

export function* watchLoadData() {
  yield takeLatest(panelLoader.loadData, loadData)
}

export function* loaderSaga() {
  yield all([fork(watchLoadData)])
}
