import axios from 'axios'

class StatisticsAPI {
  getDashboardStatistics = async params => {
    return axios.get(`api/admin/statistics/dashboard`, {
      params,
    })
  }
}

export const statisticsAPI = new StatisticsAPI()
