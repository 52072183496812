import React from 'react'
import { Spinner } from 'reactstrap'
import PropTypes from 'prop-types'

import s from './OverlayLoading.module.scss'

export const OverlayLoading = ({ isLoading, spinnerSize = 'lg' }) => {
  return (
    <>
      {isLoading && (
        <div className={s.block}>
          <Spinner size={spinnerSize} color="primary" />
        </div>
      )}
    </>
  )
}

OverlayLoading.propTypes = {
  isLoading: PropTypes.bool,
  spinnerSize: PropTypes.string,
}
