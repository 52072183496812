import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'

export const PageContent = ({ children }) => {
  return (
    <div className={'page-content'}>
      <Container fluid>{children}</Container>
    </div>
  )
}

PageContent.propTypes = {
  children: PropTypes.any,
}
