import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { IconPencil } from '@tabler/icons'
import { useSelector } from 'react-redux'

import { Anchor } from 'components/Elements'

export const Edit = () => {
  const { t } = useTranslation()

  const { data } = useSelector(state => state.job.data)

  return (
    <Anchor
      to={`/jobs/${data?.id}/edit`}
      className="font-size-18 fw-medium"
      leftIcon={<IconPencil size={20} />}
      color={'primary'}
    >
      {t('edit_details')}
    </Anchor>
  )
}

Edit.propTypes = {}
