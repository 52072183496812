import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle } from 'components/Elements'
import { PageContent } from 'components/Layouts'
import { FAQEntriesContainer } from 'features/immigrate/components/FAQ/FAQEntries'

export const ImmigrateFAQ = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'immigrate-questions.title'} />

      <PageContent>
        <FAQEntriesContainer />
      </PageContent>
    </>
  )
}

ImmigrateFAQ.propTypes = {}
