import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const panelGeneralSlice = createSlice({
  name: 'panelGeneral',
  initialState,
  reducers: {
    resetState() {
      return initialState
    },
  },
})

export const panelGeneral = panelGeneralSlice.actions

export default panelGeneralSlice.reducer
