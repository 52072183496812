import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const FilterSection = ({
  children,
  className = '',
  reset = false,
  onReset,
  rightSection,
  ...props
}) => {
  const { t } = useTranslation()

  const onClear = e => {
    e.preventDefault()

    onReset && onReset()
  }

  return (
    <>
      {children}

      {(reset || rightSection) && (
        <div className={'d-flex mt-2 justify-content-end align-items-center flex-wrap gap-3'}>
          {reset && (
            <div>
              <a onClick={onClear} className={'text-primary font-size-14'}>
                {t('clear_all')}
              </a>
            </div>
          )}

          {rightSection ? rightSection : null}
        </div>
      )}
    </>
  )
}

FilterSection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  reset: PropTypes.bool,
  onReset: PropTypes.func,
  rightSection: PropTypes.node,
}
