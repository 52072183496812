import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { NoDataTextMessage } from 'components/Elements'

export const BasicBlock = ({ data = {} }) => {
  const { t } = useTranslation()

  const { company_website, company_size } = data

  return (
    <>
      <Card className={'w-100'}>
        <CardBody>
          <div className={'mb-4'}>
            <h5>{t('website')}</h5>
            {company_website ? (
              <p>
                <a
                  href={company_website}
                  target={'_blank'}
                  rel="noreferrer"
                  className={'text-primary'}
                >
                  {company_website}
                </a>
              </p>
            ) : (
              <NoDataTextMessage variant={'fill'} />
            )}
          </div>

          <div>
            <h5>{t('company_size')}</h5>
            {company_size ? <p>{company_size?.value}</p> : <NoDataTextMessage variant={'fill'} />}
          </div>
        </CardBody>
      </Card>
    </>
  )
}

BasicBlock.propTypes = {
  data: PropTypes.object,
}
