import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import s from './BasicInfoBlock.module.scss'
import { TagBadge, NoDataTextMessage } from 'components/Elements'
import { WorkAuthorizations } from '../../WorkAuthorizations'

export const BasicInfoBlock = ({ data }) => {
  const { t } = useTranslation()

  const {
    profile_status,
    profile_search_status,
    location_city,
    location_province,
    location_country,
    languages,
    profile_work_authorizations,
    profile_objectives,
    profile_willing_relocate,
  } = data

  return (
    <>
      <div className={'mb-4'}>
        <h5>{t('status')}</h5>
        {profile_status ? (
          <p>{profile_status?.public_value}</p>
        ) : (
          <NoDataTextMessage variant={'fill'} />
        )}
      </div>

      <div className={'mb-4'}>
        <h5>{t('job_search_status')}</h5>
        {profile_search_status ? (
          profile_search_status?.value
        ) : (
          <NoDataTextMessage variant={'fill'} />
        )}
      </div>

      <div className={'mb-4'}>
        <h5>{t('i_am_looking_for')}</h5>

        <div className={'d-flex flex-wrap gap-3'}>
          {profile_objectives && !!profile_objectives.length ? (
            profile_objectives.map((item, i) => <TagBadge key={i}>{item?.value}</TagBadge>)
          ) : (
            <NoDataTextMessage variant={'fill'} />
          )}
        </div>
      </div>

      <div className={'mb-4'}>
        <h5>{t('county_of_origin')}</h5>
        {location_country ? location_country?.value : <NoDataTextMessage variant={'fill'} />}
      </div>

      <div className={'mb-4'}>
        <h5>{t('language_spoken')}</h5>

        <div className={'d-flex flex-wrap gap-3'}>
          {languages && !!languages.length ? (
            languages.map((item, i) => <TagBadge key={i}>{item?.value}</TagBadge>)
          ) : (
            <NoDataTextMessage variant={'fill'} />
          )}
        </div>
      </div>

      <div className={'mb-4'}>
        <h5>{t('city')}</h5>
        {location_city ? location_city?.value : <NoDataTextMessage variant={'fill'} />}
      </div>

      <div className={'mb-4'}>
        <h5>{t('province')}</h5>
        {location_province ? location_province?.value : <NoDataTextMessage variant={'fill'} />}
      </div>

      <div className={'mb-4'}>
        <h5>{t('relocation')}</h5>
        {profile_willing_relocate ? (
          <TagBadge>{profile_willing_relocate?.value}</TagBadge>
        ) : (
          <NoDataTextMessage variant={'fill'} />
        )}
      </div>

      <div className={'mb-4'}>
        <h5>{t('work_authorizations')}</h5>
        {profile_work_authorizations && !!profile_work_authorizations.length ? (
          <WorkAuthorizations data={profile_work_authorizations} />
        ) : (
          <NoDataTextMessage variant={'data'} />
        )}
      </div>
    </>
  )
}

BasicInfoBlock.propTypes = {
  data: PropTypes.object,
}
