import React from 'react'
import { useStaticSelects, STATIC_SELECT } from 'features/static'
import { optionsHelper } from 'helpers/options'

const mapStaticOptions = options => {
  return options.map(item => ({
    ...item,
    label: item.value,
    value: item.id,
  }))
}

// hook for get select options, which themselves have no features/context
export const useSelectOptions = () => {
  const staticSelects = useStaticSelects()

  const getStaticSelectOptions = key => {
    return mapStaticOptions(staticSelects.getSelectOptions(key))
  }

  const getProfileStatuses = (params = { public_value: true }) => {
    const { public_value } = params

    if (public_value) {
      return staticSelects.getSelectOptions(STATIC_SELECT.PROFILE_STATUSES).map(item => ({
        ...item,
        label: item.public_value,
        value: item.id,
      }))
    }

    return getStaticSelectOptions(STATIC_SELECT.PROFILE_STATUSES)
  }

  const getLocationProvinces = () => {
    return getStaticSelectOptions(STATIC_SELECT.LOCATION_PROVINCES)
  }

  const getLocationCities = (params = { location_province_id: null }) => {
    const { location_province_id } = params

    const options = getStaticSelectOptions(STATIC_SELECT.LOCATION_CITIES)

    if (location_province_id) {
      return options.filter(item => item.location_province_id === location_province_id)
    }

    return options
  }

  const getGroupedLocations = () => {
    const provinces = getLocationProvinces()

    return provinces.map(item => {
      const cities = getLocationCities({ location_province_id: item.id })
      return {
        ...item,
        options: cities,
      }
    })
  }

  const getLocationCountries = () => {
    return getStaticSelectOptions(STATIC_SELECT.LOCATION_COUNTRIES)
  }

  const getLanguages = () => {
    return getStaticSelectOptions(STATIC_SELECT.LANGUAGES)
  }

  const getProfileWorkAuthorizations = () => {
    return getStaticSelectOptions(STATIC_SELECT.PROFILE_WORK_AUTHORIZATIONS)
  }

  const getProfileObjectives = () => {
    return getStaticSelectOptions(STATIC_SELECT.PROFILE_OBJECTIVES)
  }

  const getProfileWillingRelocates = () => {
    return getStaticSelectOptions(STATIC_SELECT.PROFILE_WILLING_RELOCATES)
  }

  const getSchools = () => {
    return getStaticSelectOptions(STATIC_SELECT.SCHOOLS)
  }

  const getProfileStudyPrograms = () => {
    return getStaticSelectOptions(STATIC_SELECT.PROFILE_STUDY_PROGRAMS)
  }

  const getProfileStudyYears = () => {
    return getStaticSelectOptions(STATIC_SELECT.PROFILE_STUDY_YEARS)
  }

  const getProfileStudyArea = (params = { extended: false }) => {
    const { extended } = params

    const options = getStaticSelectOptions(STATIC_SELECT.PROFILE_STUDY_AREA)

    if (!extended) {
      return options.filter(item => item.weight !== 0)
    }

    return options
  }

  const getSkills = () => {
    return getStaticSelectOptions(STATIC_SELECT.SKILLS)
  }

  const getIndustries = () => {
    return getStaticSelectOptions(STATIC_SELECT.INDUSTRIES)
  }

  const getTargetPositions = () => {
    return getStaticSelectOptions(STATIC_SELECT.TARGET_POSITIONS)
  }

  const getProfileSelfIdentifications = () => {
    return getStaticSelectOptions(STATIC_SELECT.PROFILE_SELF_IDENTIFICATION)
  }

  const getProfileGenders = () => {
    return getStaticSelectOptions(STATIC_SELECT.GENDERS)
  }

  const getProfileEthnics = () => {
    return getStaticSelectOptions(STATIC_SELECT.ETHNICS)
  }

  const getCompanySizes = () => {
    return getStaticSelectOptions(STATIC_SELECT.COMPANY_SIZES)
  }

  const getProfilePositions = () => {
    return getStaticSelectOptions(STATIC_SELECT.PROFILE_POSITIONS)
  }

  const getProfileExperienceYears = () => {
    return getStaticSelectOptions(STATIC_SELECT.PROFILE_EXPERIENCE_YEARS)
  }

  const getJobLocations = () => {
    return getStaticSelectOptions(STATIC_SELECT.JOB_LOCATION)
  }

  const getJobTypes = () => {
    return getStaticSelectOptions(STATIC_SELECT.JOB_TYPE)
  }

  const getMonths = () => {
    return optionsHelper.month.getMonths()
  }

  const getProfileCertifications = () => {
    return staticSelects.getSelectOptions(STATIC_SELECT.PROFILE_CERTIFICATIONS)
  }

  const getBooleanOptions = () => {
    return optionsHelper.general.getBooleanOptions()
  }

  const getProfileSearchStatuses = () => {
    return getStaticSelectOptions(STATIC_SELECT.PROFILE_SEARCH_STATUSES)
  }

  return {
    getProfileStatuses,
    getLocationProvinces,
    getLocationCities,
    getGroupedLocations,
    getLocationCountries,
    getLanguages,
    getProfileWorkAuthorizations,
    getProfileObjectives,
    getProfileWillingRelocates,
    getSchools,
    getProfileStudyPrograms,
    getProfileStudyYears,
    getProfileStudyArea,
    getSkills,
    getIndustries,
    getTargetPositions,
    getProfileSelfIdentifications,
    getProfileGenders,
    getProfileEthnics,
    getCompanySizes,
    getProfilePositions,
    getProfileExperienceYears,
    getJobLocations,
    getJobTypes,
    getMonths,
    getProfileCertifications,
    getBooleanOptions,
    getProfileSearchStatuses,
  }
}
