import React from 'react'
import PropTypes from 'prop-types'

import { Logo } from './Logo'
import { Icon } from './Icon'

export const LogoIcon = props => {
  switch (props.type) {
    case 'icon':
      return <Icon {...props} />

    default:
      return <Logo {...props} />
  }
}

LogoIcon.propTypes = {
  type: PropTypes.string,
}
