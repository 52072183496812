import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import s from './ContactLink.module.scss'

export const ContactLink = ({ children, className, size, ...props }) => {
  return (
    <a
      className={classNames(`${s.root}`, {
        [className]: className,
      })}
      {...props}
    >
      {children}
    </a>
  )
}

ContactLink.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.string,
}
