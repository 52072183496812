import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { MegaTagTitle, ButtonLink } from 'components/Elements'
import { PageContent, PageHeader } from 'components/Layouts'
import { GeneralJobsContainer } from 'features/job/components/Jobs/GeneralJobs'

export const Jobs = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'jobs'} />

      <PageContent>
        <PageHeader
          headerRight={
            <ButtonLink to={'/jobs/create'} className={'text-capitalize'}>
              {t('post_a_job')}
            </ButtonLink>
          }
        />

        <GeneralJobsContainer />
      </PageContent>
    </>
  )
}

Jobs.propTypes = {}
