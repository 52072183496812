import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { useUserAccount } from 'features/users/hooks'
import s from './GeneralBlock.module.scss'
import { AvatarProvider } from 'components/Elements'
import { FormatUtils } from 'utils'
import { SubscriptionBadge } from 'features/billing/components/Elements'

export const GeneralBlock = () => {
  const { t } = useTranslation()

  const { account, getName, isEmployer } = useUserAccount()

  return (
    <>
      <Card>
        <CardBody>
          <div className={s.block}>
            <div className={s.avatarWrap}>
              <AvatarProvider avatar={account?.avatar} size={'md'} />
            </div>
            <div>
              <div>
                <h4 className={'mb-0'}>{getName()}</h4>
              </div>
            </div>
          </div>

          <div className={'mt-3'}>
            <div className={''}>
              <h6>{t('email')}</h6>
              <p className={'mb-0'}>{account.email}</p>
            </div>

            <div className={'mt-3'}>
              <h6>{t('phone')}</h6>
              <p className={'mb-0'}>{FormatUtils.formatPhone(account.phone)}</p>
            </div>

            {isEmployer() && (
              <div className={'mt-3'}>
                <h6>{t('subscription')}</h6>
                {account?.subscription ? (
                  <SubscriptionBadge alias={account?.subscription?.tariff?.alias} />
                ) : (
                  '-'
                )}
              </div>
            )}

            {isEmployer() && (
              <div className={'mt-3'}>
                <h6>{t('credits')}</h6>
                {account?.subscription?.credits !== null ? account?.subscription?.credits : '0'}
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  )
}

GeneralBlock.propTypes = {}
