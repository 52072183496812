import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Basic } from './Basic'
import { Company } from './Company'

export const EmployerContent = () => {
  const { t } = useTranslation()

  return (
    <>
      <Basic />

      <Company />
    </>
  )
}

EmployerContent.propTypes = {}
