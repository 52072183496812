import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import s from './TagBadge.module.scss'

export const TagBadge = ({
  className,
  textClassName,
  size = 'lg',
  color = 'primary',
  variant = 'light',
  children,
  leftSection,
  rightSection,
}) => {
  return (
    <>
      <div
        className={classNames(s.root, {
          [`bg-${color}`]: color,
          'bg-soft': variant === 'light',
          [s.size_lg]: size === 'lg',
          [s.size_xs]: size === 'xs',
          [s.size_md]: size === 'md',
          [className]: className,
        })}
      >
        {leftSection && <span className={s.leftSection}>{leftSection}</span>}

        <span
          className={classNames(s.inner, {
            [`text-${color}`]: color,
            [textClassName]: textClassName,
          })}
        >
          {children}
        </span>

        {rightSection && <span className={s.rightSection}>{rightSection}</span>}
      </div>
    </>
  )
}

TagBadge.propTypes = {
  className: PropTypes.string,
  textClassName: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  leftSection: PropTypes.node,
  rightSection: PropTypes.node,
}
