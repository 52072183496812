import React from 'react'
import PropTypes from 'prop-types'
import s from './BannerBlock.module.scss'

export const BannerBlock = ({ data }) => {
  return (
    <>
      <img className={s.img} src={data?.full} alt={'banner'} />
    </>
  )
}

BannerBlock.propTypes = {
  img: PropTypes.object,
}
