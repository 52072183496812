import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import {
  GeneralBlock,
  BasicBlock,
  CTABlock,
  BannerBlock,
} from 'features/company/components/Profile/ProfileDetails/InfoBlocks'
import { useUserAccount } from 'features/users/hooks'

export const Company = () => {
  const { t } = useTranslation()

  const { account } = useUserAccount()

  const { employer_profile } = account

  return (
    <>
      <h5>{t('company')}</h5>

      <>
        <Row>
          <Col lg={6} xxl={4} className={'d-flex'}>
            <GeneralBlock data={employer_profile} />
          </Col>

          <Col lg={6} xxl={8} className={'d-flex'}>
            <BasicBlock data={employer_profile} />
          </Col>
        </Row>

        <>
          {employer_profile?.company_cta_text && (
            <Row>
              <Col>
                <CTABlock data={employer_profile} />
              </Col>
            </Row>
          )}

          {employer_profile?.company_banner && (
            <Row className={'mb-3'}>
              <Col>
                <BannerBlock data={employer_profile?.company_banner} />
              </Col>
            </Row>
          )}
        </>
      </>
    </>
  )
}

Company.propTypes = {}
