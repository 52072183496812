import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button as StapButton } from 'reactstrap'

export const Button = ({
  type,
  loading,
  disabled,
  title,
  leftIcon,
  icon,
  size = 'lg',
  onClick,
  color = 'primary',
  submit = false,
  className = '',
  outline = false,
  rounded = true,
  children,
  width = 'w-lg',
  ...rest
}) => {
  return (
    <StapButton
      type={type || (submit ? 'submit' : 'button')}
      className={classNames('', {
        // "btn-block": true,
        [`btn-${color}`]: color && !outline,
        [`btn-${size}`]: size,
        [`btn-rounded`]: rounded,
        [width]: width,
        [className]: className,
      })}
      disabled={disabled}
      onClick={onClick}
      outline={outline}
      color={color && outline ? color : ''}
      {...rest}
    >
      <div className={'d-flex align-items-center justify-content-center'}>
        {loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />}

        {leftIcon && <div className={'d-flex align-items-center me-2'}>{leftIcon}</div>}

        {children}
      </div>
    </StapButton>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  leftIcon: PropTypes.any,
  icon: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  submit: PropTypes.bool,
  className: PropTypes.string,
  outline: PropTypes.bool,
  rounded: PropTypes.bool,
  children: PropTypes.any,
  width: PropTypes.string,
}
