export const STATIC_SELECT = {
  ETHNICS: 'ethnics',
  GENDERS: 'genders',
  INDUSTRIES: 'industries',
  LANGUAGES: 'languages',
  LOCATION_CITIES: 'location_cities',
  LOCATION_COUNTRIES: 'location_countries',
  LOCATION_PROVINCES: 'location_provinces',
  PROFILE_OBJECTIVES: 'profile_objectives',
  PROFILE_SELF_IDENTIFICATION: 'profile_self_identification',
  PROFILE_STATUSES: 'profile_statuses',
  PROFILE_STUDY_AREA: 'profile_study_area',
  PROFILE_STUDY_PROGRAMS: 'profile_study_programs',
  PROFILE_STUDY_YEARS: 'profile_study_years',
  PROFILE_WILLING_RELOCATES: 'profile_willing_relocates',
  PROFILE_WORK_AUTHORIZATIONS: 'profile_work_authorizations',
  SCHOOLS: 'schools',
  SKILLS: 'skills',
  TARGET_POSITIONS: 'target_positions',
  COMPANY_SIZES: 'company_sizes',
  PROFILE_POSITIONS: 'profile_positions',
  PROFILE_EXPERIENCE_YEARS: 'profile_experience_years',
  JOB_LOCATION: 'jobLocation',
  JOB_TYPE: 'jobType',
  PROFILE_CERTIFICATIONS: 'profile_certifications',
  PROFILE_SEARCH_STATUSES: 'profile_search_statuses',
}
