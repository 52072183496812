import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import placeholder from 'assets/images/placeholders/avatar.svg'
import logoPlaceholder from 'assets/images/placeholders/logo.svg'

export const Avatar = ({ src, size = 'sm', variant = 'avatar', className = '' }) => {
  return (
    <>
      <img
        className={classNames('rounded-circle img-cover', {
          [`avatar-${size}`]: size,
          [className]: className,
        })}
        src={src || (variant === 'logo' ? logoPlaceholder : placeholder)}
        alt="avatar"
      />
    </>
  )
}

Avatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.oneOf(['avatar', 'logo']),
  className: PropTypes.string,
}
