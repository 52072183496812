import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Modal } from 'components/Elements'
import { CategoryCreateContainer } from './CategoryCreateContainer'

export const CategoryCreate = ({ visible, onClose, onSuccess }) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal visible={visible} centered onClose={onClose} title={t('add_category')} size={'lg'}>
        <CategoryCreateContainer onClose={onClose} onSuccess={onSuccess} />
      </Modal>
    </>
  )
}

CategoryCreate.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}
