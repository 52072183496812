import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useUser } from 'features/user'
import { LogoutLink } from './LogoutLink'
import { AvatarProvider } from 'components/Elements'

export const ProfileMenu = props => {
  const { t } = useTranslation()

  const [menu, setMenu] = useState(false)

  const { user } = useUser()

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
          <AvatarProvider avatar={user?.avatar} size={'xs'} />
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <LogoutLink />
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}
