import React from 'react'
import PropTypes from 'prop-types'

import { Avatar } from '../Avatar'

export const AvatarProvider = ({ avatar, type = 'thumb', ...props }) => {
  const src = avatar ? (type === 'thumb' ? avatar?.thumb : type === 'full' ? avatar?.full : '') : ''

  return <Avatar src={src} {...props} />
}

AvatarProvider.propTypes = {
  avatar: PropTypes.object,
  type: PropTypes.oneOf(['full', 'thumb']),
}
