import axios from 'axios'

class ProfileAPI {
  getProfile = async params => {
    return axios.get('api/profile', { params })
  }

  updatePassword = async params => {
    return axios.put('api/profile/password', params)
  }
}

export const profileAPI = new ProfileAPI()
