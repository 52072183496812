import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Applications } from '../applications'
import { JobApplicationsTabLayout } from 'features/job/components/Layouts'

export const ApplicationsRoot = () => {
  return (
    <Routes>
      <Route element={<JobApplicationsTabLayout />}>
        <Route index element={<Applications />} />
      </Route>

      <Route path={'*'} element={<Navigate to={'..'} replace={true} />} />
    </Routes>
  )
}

ApplicationsRoot.propTypes = {}
