import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { DataShower } from 'components/Elements'
import { immigrateCategoryEditData } from 'features/immigrate-category/store'

export const CategoryDataLoader = ({ id, children }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { data, loading, error } = useSelector(state => state.immigrateCategory.edit.data)

  useEffect(() => {
    dispatch(immigrateCategoryEditData.getData({ id }))

    return () => {
      dispatch(immigrateCategoryEditData.cleanState({}))
    }
  }, [])

  return (
    <>
      <DataShower isLoading={loading} isFetched={!!data} isFailed={!!error} error={error}>
        {children}
      </DataShower>
    </>
  )
}

CategoryDataLoader.propTypes = {
  id: PropTypes.number,
  children: PropTypes.node,
}
