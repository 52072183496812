import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { JobInviteForm } from '../JobInviteForm'
import { jobInviteBulkInvitePA } from 'features/job/store'
import { useNotify } from 'hooks'

export const JobBulkInvite = ({ onSuccess, filters, job, jobSelect }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async values => {
    const { message, job_id } = values

    const id = jobSelect ? job_id : job?.id

    let mapFilters = {}

    Object.entries(filters).forEach(entry => {
      const [key, value] = entry

      if (value) {
        mapFilters = {
          ...mapFilters,
          [key]: value,
        }
      }
    })

    const params = {
      message,
      filters: mapFilters,
    }

    await dispatch(jobInviteBulkInvitePA.request({ id, params }))

    showNotification({
      type: 'success',
      message: t('invite_submitted_successfully'),
    })

    onSuccess()
  }

  return (
    <>
      <JobInviteForm onSubmit={onSubmit} jobSelect={jobSelect} />
    </>
  )
}

JobBulkInvite.propTypes = {
  onSuccess: PropTypes.func,
  filters: PropTypes.object,
  job: PropTypes.object,
  jobSelect: PropTypes.bool,
}
