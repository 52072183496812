export const EN = {
  login: 'Login',
  sign_up: 'Sign Up',
  'email_verification.success': 'Verification passed successfully',
  dont_have_account: 'Don’t have an account',
  'field.error.required': 'This field is required',
  'field.error.invalid': 'This field is invalid',
  'field.error.password.length': 'The password must be at least {{count}} characters',
  'field.error.password.no_match': 'Passwords do not match',
  error: 'Error',
  email: 'Email',
  password: 'Password',
  confirm_password: 'Confirm password',
  forgot_password: 'Forgot password',
  create_account: 'Create account',
  already_have_account: 'Already have an account',
  required: 'Required',
  reset_password: 'Reset password',
  remember_it: 'Remember it',
  reset: 'Reset',
  back_to: 'Back to',
  terms_and_conditions: 'Terms and conditions',
  logout: 'Logout',
  name: 'Name',
  'confirmation-email-sent': 'A confirmation email has been sent to your email',
  send_confirmation_email_to: 'Send a confirmation email to',
  success: 'Success',
  student: 'Student',
  employer: 'Employer',
  register: 'Register',
  registration: 'Registration',
  student_registration: 'Student Registration',
  employer_registration: 'Employer Registration',
  or: 'or',
  first_name: 'First name',
  last_name: 'Last name',
  phone: 'Phone',
  confirm: 'Confirm',
  register_confirm_text: 'By confirming, you agree to our',
  register_confirm_text_2: 'You are also agreeing to our',
  employer_guidelines: 'Employer Guidelines',
  student_guidelines: 'Student Guidelines',
  terms_of_use: 'Terms of Use',
  privacy_policy: 'Privacy Policy',
  and: 'and',
  book_call: 'Book a call',
  find_job: 'Find a job',
  employer_register_info_title: "Have questions? Let's chat.",
  employer_register_info_text:
    'Our dedicated team is here to help. Schedule a call to explore how we can help you reach your diversity goal.',
  student_register_info_title: 'Launch your career with us',
  student_register_info_text:
    'The only platform you will ever need to launch a successful career as an international student & graduate. See you on the other side👋',
  loading: 'Loading',
  'select.no_options': 'No options',
  status: 'Status',
  profile: 'Profile',
  my_profile: 'My profile',
  invites: 'Invites',
  all_jobs: 'All jobs',
  browse_jobs: 'Browse jobs',
  applied: 'Applied',
  saved: 'Saved',
  discover_employers: 'Discover employers',
  help: 'Help',
  webinars: 'Webinars',
  bootcamp: 'Bootcamp',
  resources: 'Resources',
  community: 'Community',
  get_help: 'Get help',
  copyright: 'Copyright',
  company: 'Company',
  company_profile: 'Company profile',
  subscription: 'Subscription',
  candidates: 'Candidates',
  browse_candidates: 'Browse candidates',
  candidate_invites: 'Candidate invites',
  my_jobs: 'My jobs',
  active: 'Active',
  draft: 'Draft',
  closed: 'Closed',
  create_profile: 'Create profile',
  lets_create_your_profile: 'Lets create your profile',
  do_it_later: 'Do it later',
  basic_profile: 'Basic profile',
  work_and_education_details: 'Work & Education Details',
  self_identified_demographic_information: 'Self-identified Demographic Information',
  'self_identified.disclaimer':
    'This information will only be used to help our partner employers build more diverse and inclusive talent pipelines. Your data is handled with care and it will never be used to discriminate against you.',
  back: 'Back',
  next: 'Next',
  complete: 'Complete',
  province_location: 'Province location',
  current_city_in_canada: 'Current city in Canada',
  county_of_origin: 'Country of Origin',
  language_spoken: 'Language Spoken',
  language: 'Language',
  work_authorization: 'Work authorization',
  expire_date_work_authorization: 'Expiry date of the work authorization',
  check_all_physically_have: 'Check all that you physically have',
  objective: 'Objective',
  are_you_willing_relocate_to_work: 'Are you willing to relocate to work',
  education_details: 'Education details',
  school: 'School',
  year: 'Year',
  study_program: 'Study program',
  year_of_study: 'Year of study',
  area_of_study: 'Area of study',
  my_top_skills: 'My top skills',
  work_details: 'Work details',
  years_of_work_experience: 'Years of work experience',
  target_industry: 'Target Industry',
  i_want_work_in: 'I want to work in',
  target_position: 'Target position',
  max: 'Max',
  which_of_this_identify_as: 'Which of this do you identify as',
  gender: 'Gender',
  race_ethnicity: 'Race/Ethnicity',
  add_work: 'Add work',
  add_project: 'Add project',
  title: 'Title',
  position_title: 'Position title',
  project_title: 'Project title',
  duration: 'Duration',
  country: 'Country',
  work_experience: 'Work experience',
  work: 'Work',
  project: 'Project',
  project_and_volunteer: 'Project & Volunteer Experience',
  others: 'Others',
  saved_successfully: 'Saved successfully',
  certification: 'Certification',
  edit: 'Edit',
  location: 'Location',
  city: 'City',
  province: 'Province',
  expire_date: 'Expire date',
  relocation: 'Relocation',
  no_data: 'No data',
  empty: 'Empty',
  not_filled: 'Not filled',
  education: 'Education',
  year_s: 'year(s)',
  skills: 'Skills',
  self_identification: 'Self-identification',
  edit_profile: 'Edit profile',
  save: 'Save',
  position: 'Position',
  company_name: 'Company name',
  website: 'Website',
  company_size: 'Company size',
  edit_company_profile: 'Edit company profile',
  industry: 'Industry',
  'file.max_size.error': 'The file must not be greater than {{text}}',
  upload_photo: 'Upload photo',
  delete_photo: 'Delete photo',
  upload_logo: 'Upload logo',
  delete_logo: 'Delete logo',
  deleted_successfully: 'Deleted successfully',
  profile_settings: 'Profile settings',
  change_password: 'Change password',
  current_password: 'Current password',
  new_password: 'New password',
  confirm_new_password: 'Confirm new password',
  settings: 'Settings',
  password_change_successfully: 'Password change successfully',
  job_search_prep: 'Job search prep',
  build_your_network: 'Build your network',
  slack_community: 'Slack community',
  meet_mentors: 'Meet the mentors',
  free_resources: 'Free resources',
  events_and_webinars: 'Events & Webinars',
  career_launcher_bootcamp: 'Career Launcher Bootcamp',
  jobs: 'Jobs',
  drafts: 'Drafts',
  active_jobs: 'Active jobs',
  job: 'Job',
  edit_job: 'Edit job',
  applied_jobs: 'Applied jobs',
  saved_jobs: 'Saved jobs',
  post_job: 'Post Job',
  save_to_draft: 'Save to Draft',
  closed_jobs: 'Closed jobs',
  post_a_job: 'Post a job',
  job_title: 'Job Title',
  'job-title-field-placeholder': 'Ex - UX Designer',
  experience_level: 'Experience Level',
  type: 'Type',
  about_this_role: 'About This Role',
  languages: 'Languages',
  pay_range: 'Pay Range',
  from: 'From',
  to: 'To',
  hr: 'hr',
  job_posted_successfully: 'Job posted successfully',
  saved_to_draft_successfully: 'Saved to draft successfully',
  candidate_registration: 'Candidate Registration',
  candidate: 'Candidate',
  candidate_guidelines: 'Candidate Guidelines',
  education_institution: 'Education Institution',
  graduate_year: 'Graduate Year',
  total_years_of_work_experience: 'Total Years of Work Experience',
  remote: 'Remote',
  posted_on: 'Posted on',
  created_on: 'Created on',
  no_jobs_yet: 'No jobs yet',
  previous: 'Previous',
  edit_details: 'Edit Details',
  please_confirm: 'Please confirm',
  cancel: 'Cancel',
  visit_website: 'Visit Website',
  no_info: 'No info',
  view_jobs: 'View Jobs',
  expand: 'Expand',
  collapse: 'Collapse',
  job_saved_successfully: 'Job saved successfully',
  job_removed_from_saved: 'Job removed from saved',
  candidates_not_found: 'Candidates not found',
  relocate: 'Relocate',
  exp: 'Exp',
  contact_details: 'Contact Details',
  clear_all: 'Clear All',
  statuses: 'Statuses',
  provinces: 'Provinces',
  cities: 'Cities',
  study_programs: 'Study Programs',
  areas_of_study: 'Areas of Study',
  work_authorizations: 'Work Authorizations',
  years_of_experience: 'Years of Experience',
  period: 'Period',
  month: 'Month',
  present: 'Present',
  i_am_looking_for: 'I’m looking for',
  select_all_that_apply: 'select all that apply',
  expire_date_permit: 'Expiry date of permit',
  add_work_authorization: 'Add work authorization',
  resume: 'Resume',
  upload_resume: 'Upload Resume',
  upload_file: 'Upload File',
  no_resume: 'No resume',
  download_resume: 'Download Resume',
  invite: 'Invite',
  invited: 'Invited',
  submit_invite: 'Submit Invite',
  invite_submitted_successfully: 'Invite submitted successfully',
  message: 'Message',
  'job_invite.template_message':
    'Hi {{name}}, I have reviewed your profile and I’d like to personally invite you to apply to the position {{position}}. I believe that you could be a good fit for the role.',
  'job_invite.template_message.2':
    'Hi {{name}}, I have reviewed your profile and I’d like to personally invite you to apply to the position.',
  no_invites_yet: 'No invites yet',
  invited_you_to: 'Invited you to',
  invited_on: 'Invited on',
  original_job_url: 'Original Job URL',
  job_location: 'Job Location',
  yr: 'yr',
  minimum_education_requirement: 'Minimum Education Requirement',
  preferred_area_of_study: 'Preferred Area of Study',
  'must-have_skills': 'Must-have Skills',
  required_languages: 'Required Languages',
  open: 'Open',
  create_job: 'Create Job',
  recommend: 'Recommend',
  date_created: 'Date created',
  user: 'User',
  recommendations: 'Recommendations',
  not_recommend: 'Not recommend',
  remove: 'Remove',
  add: 'Add',
  users: 'Users',
  employers: 'Employers',
  employers_not_found: 'Employers not found',
  subscription_plan: 'Subscription Plan',
  'tariff.free': 'Free',
  'tariff.per_job': 'Per Job',
  'tariff.premium': 'Premium',
  'tariff.enterprise': 'Enterprise',
  monthly_recommended_jobs: 'Monthly Recommended Jobs',
  paid: 'Paid',
  credits: 'Credits',
  job_removed_successfully: 'Job removed successfully',
  dashboard: 'Dashboard',
  statistics: 'Statistics',
  amount: 'Amount',
  total: 'Total',
  total_amount: 'Total Amount',
  per_employer: 'Per Employer',
  applications: 'Applications',
  logins_amount: 'Logins Amount',
  candidates_views: 'Candidates Views',
  select_period: 'Select period',
  logins: 'Logins',
  viewed: 'Viewed',
  view: 'View',
  invited_for: 'Invited for',
  sent_on: 'Sent on',
  types_of_jobs: 'Types of jobs',
  no_jobs: 'No jobs',
  applied_on: 'Applied on',
  no_applications_yet: 'No applications yet',
  mentor: 'Mentor',
  mentors: 'Mentors',
  mentor_registration: 'Mentor Registration',
  position_of_expertise: 'Position of Expertise',
  schedule_link: 'Schedule Link',
  is_shared_mentor_text: 'I want to share my profile with recruiters & hiring managers',
  yes: 'Yes',
  no: 'No',
  mentors_not_found: 'Mentors not found',
  mentors_bookings: 'Mentors bookings',
  mentor_bookings: 'Mentor bookings',
  certifications: 'Certifications',
  add_certification: 'Add certification',
  'field.error.min_length': 'The field must be at least {{count}} characters',
  certification_already_exist: 'This certification already exist',
  advanced_search_filters: 'Advanced Search Filters',
  target_positions: 'Target positions',
  schools: 'Schools',
  willing_to_relocate: 'Willing to relocate',
  looking_for: 'Looking for',
  have_resume: 'Have resume',
  immigrate: 'Immigrate',
  faqs: 'FAQs',
  'immigrate-questions.title': 'Immigrate FAQs',
  add_question: 'Add Question',
  edit_question: 'Edit Question',
  question: 'Question',
  answer: 'Answer',
  removed_successfully: 'Removed successfully',
  add_category: 'Add Category',
  edit_category: 'Edit Category',
  search: 'Search',
  job_search_status: 'Job Search Status',
  featured: 'Featured',
  edit_user: 'Edit user',
  upload_banner: 'Upload banner',
  delete_banner: 'Delete banner',
  upload_image: 'Upload image',
  delete_image: 'Delete image',
  cta: 'CTA',
  cta_text: 'CTA text',
  cta_button_text: 'CTA button text',
  cta_button_link: 'CTA button link',
  'min-characters.count': 'Min. {{count}} characters',
  invite_all_matched_candidates: 'Invite All Matched Candidates',
  export: 'Export',
  multiple_locations: 'Multiple locations',
}
