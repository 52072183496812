import { combineReducers } from 'redux'

import edit from './edit/reducers'
import entries from './entries/reducers'

const immigrateCategoryReducer = combineReducers({
  edit,
  entries,
})

export default immigrateCategoryReducer
