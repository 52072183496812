import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import { JobApplicationsBlock, JobInvitesBlock } from './Blocks'

export const StatisticsContent = ({ data = {} }) => {
  const { t } = useTranslation()

  return (
    <Row>
      <Col md={4}>
        <JobApplicationsBlock data={data?.job_applications} />
      </Col>

      <Col md={4}>
        <JobInvitesBlock data={data?.job_invites} />
      </Col>
    </Row>
  )
}

StatisticsContent.propTypes = {
  data: PropTypes.object,
}
