import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

export const AuthGuard = ({ children, ...props }) => {
  const location = useLocation()

  const { isLoggedIn } = props

  if (isLoggedIn) {
    return <Navigate to={'/'} state={{ from: location }} />
  }

  return <Outlet />
}

AuthGuard.propTypes = {
  children: PropTypes.any,
  isLoggedIn: PropTypes.bool,
}
