import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'

import { Button } from 'components/Elements'
import { TextInput, FormFieldController } from 'components/FormElements'

const minLength = 1

export const AddCertificationForm = ({ ...props }) => {
  const { t } = useTranslation()

  const { control, handleSubmit } = useForm({
    defaultValues: {
      certification: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        certification: Yup.string()
          .trim()
          .required('field.error.required')
          .min(minLength, 'field.error.min_length')
          .test(
            'exist',
            'certification_already_exist',
            val => !_.find(props.options, { value: val })
          ),
      })
    ),
    reValidateMode: 'onBlur',
  })

  const onSubmit = data => {
    props.onAdd(data.certification.trim())
  }

  return (
    <>
      <form
        onSubmit={event => {
          // this part is for stopping parent forms to trigger their submit
          if (event) {
            // sometimes not true, e.g. React Native
            if (typeof event.preventDefault === 'function') {
              event.preventDefault()
            }
            if (typeof event.stopPropagation === 'function') {
              // prevent any outer forms from receiving the event too
              event.stopPropagation()
            }
          }
          return handleSubmit(onSubmit)(event)
        }}
      >
        <FormFieldController
          name={'certification'}
          control={control}
          component={TextInput}
          id={'certification'}
          label={t('certification')}
          placeholder={t('certification')}
          translateParams={{ count: minLength }}
        />

        <div className="mt-3 pt-3">
          <div className="text-center">
            <Button type={'submit'}>{t('add')}</Button>
          </div>
        </div>
      </form>
    </>
  )
}

AddCertificationForm.propTypes = {
  onAdd: PropTypes.func,
  options: PropTypes.array,
}
