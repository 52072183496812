import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'

import { currencyHelper } from 'helpers'

const currencySymbol = currencyHelper.general.getGeneralCurrency()?.symbol

export const CurrencyFormat = ({ value = '', ...props }) => {
  const { t } = useTranslation()

  return (
    <NumericFormat
      value={value}
      prefix={currencySymbol}
      displayType="text"
      thousandSeparator=","
      decimalSeparator="."
      decimalScale={2}
      {...props}
    />
  )
}

CurrencyFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
