import moment from 'moment'

const isSamePeriodLength = (firstDateRange, secondDateRange) => {
  const startDate = moment(firstDateRange[0])
  const endDate = moment(firstDateRange[1])
  const secondStart = moment(secondDateRange[0])
  const secondEnd = moment(secondDateRange[1])

  return endDate.diff(startDate, 'days') === secondEnd.diff(secondStart, 'days')
}

const format = (date, format) => {
  if (format) {
    return moment(date).format(format)
  }
  return date
}

const formatDateToServerFormat = date => {
  return date ? format(date, 'YYYY-MM-DD') : null
}

const formatDate = date => {
  return date ? format(date, 'DD/MM/YYYY') : null
}

const localeToUTC = (date, format = null) => {
  const value = moment(date).utc()

  if (format) {
    return value.format(format)
  }

  return value.toDate()
}

const utcToLocale = (date, format = null) => {
  const value = moment.utc(date).local()

  if (format) {
    return value.format(format)
  }

  return value.toDate()
}

const timeToDate = (time, format = 'HH:mm') => {
  return moment(time, format).toDate()
}

const dateToTime = (date, format = 'HH:mm') => {
  return moment(date).format(format)
}

const utcTimeToLocaleDate = (time, format = 'HH:mm') => {
  return moment.utc(time, format).local().toDate()
}

const localeToUtcTime = (date, format = 'HH:mm') => {
  return localeToUTC(date, format)
}

const yearToDate = (year, format = 'YYYYY') => {
  return moment(year, format).toDate()
}

export {
  format,
  formatDateToServerFormat,
  formatDate,
  isSamePeriodLength,
  localeToUTC,
  utcToLocale,
  timeToDate,
  dateToTime,
  utcTimeToLocaleDate,
  localeToUtcTime,
  yearToDate,
}
