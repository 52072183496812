import React, { useState, useMemo } from 'react'
import classNames from 'classnames'

import { TextInput } from '../TextInput'

export const PasswordInputFiled = props => {
  const [visible, setVisible] = useState(false)

  const type = visible ? 'text' : 'password'

  const toggleVisible = e => {
    e.preventDefault()

    setVisible(prevVisible => !prevVisible)
  }

  const rightAccessory = useMemo(
    () => (
      <div className={'form-right-accessory'}>
        <a className={'form-control-eye text-dark'} href={'#'} onClick={toggleVisible}>
          <i
            className={classNames('mdi font-size-16', {
              'mdi-eye-outline': !visible,
              'mdi-eye-off-outline': visible,
            })}
          />
        </a>
      </div>
    ),
    [visible]
  )

  return <TextInput type={type} rightAccessory={rightAccessory} {...props} />
}
