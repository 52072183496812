import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { useDeepCompareEffect } from 'react-use'
import { Card, CardHeader, CardBody } from 'reactstrap'

import { OverlayDataShower } from 'components/Elements'
import { usersUserStatisticsData } from 'features/users/store'
import { StatisticsContent } from './StatisticsContent'
import { GeneralStatisticsFilter } from 'features/statistics/components/Filters'
import { DateUtils } from 'utils'
import { useUserAccount } from 'features/users/hooks'

const PERIOD_TYPE = {
  MONTH: 'month',
  CUSTOM: 'custom',
}

const UserStatisticsContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { account } = useUserAccount()

  const id = account?.id

  const [filters, setFilters] = useState({
    period: {
      type: PERIOD_TYPE.MONTH,
      range: [],
    },
  })

  useEffect(() => {
    return () => {
      dispatch(usersUserStatisticsData.cleanState({}))
    }
  }, [])

  const isFiltersValid = useMemo(() => {
    const {
      period: { type, range },
    } = filters

    if (type === PERIOD_TYPE.CUSTOM) {
      const [period_start, period_end] = range
      return !!(period_start && period_end)
    }

    return true
  }, [filters])

  useDeepCompareEffect(() => {
    if (isFiltersValid) {
      fetchData()
    }
  }, [isFiltersValid, filters])

  const fetchData = () => {
    let params = {}

    const {
      period: { type, range },
    } = filters

    if (type === PERIOD_TYPE.CUSTOM && isFiltersValid) {
      const [period_start, period_end] = range
      params = {
        ...params,
        period_start: DateUtils.formatDateToServerFormat(period_start),
        period_end: DateUtils.formatDateToServerFormat(period_end),
      }
    }

    dispatch(usersUserStatisticsData.getData({ id, params }))
  }

  const onFiltersChange = values => {
    setFilters(prevState => ({
      ...prevState,
      ...values,
    }))
  }

  return (
    <>
      <h4 className="font-size-18">{t('statistics')}</h4>

      <Card>
        <CardHeader className={'mb-3 mt-3'}>
          <GeneralStatisticsFilter defaultValues={filters} onFiltersChange={onFiltersChange} />
        </CardHeader>
        <CardBody>
          <OverlayDataShower
            isLoading={props.loading}
            isFetched={!!props.data}
            isFailed={!!props.error}
            error={props.error}
          >
            <StatisticsContent
              data={props.data}
              isFiltersValid={isFiltersValid}
              filters={filters}
            />
          </OverlayDataShower>
        </CardBody>
      </Card>
    </>
  )
}

UserStatisticsContainerComponent.propTypes = {}

const mapStateToProps = state => {
  const { data, loading, error } = state.users.user.statistics.data
  return {
    data,
    loading,
    error,
  }
}

export const UserStatisticsContainer = connect(mapStateToProps)(UserStatisticsContainerComponent)
