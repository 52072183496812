import _ from 'lodash'
import moment from 'moment'

const getMonthNameByNumber = number => moment(number, 'M').format('MMMM')

export const getMonths = () => {
  const list = Array.from({ length: 12 }, (_, i) => i + 1)

  return list.map(number => ({
    label: getMonthNameByNumber(number),
    value: number,
  }))
}

export const getMonth = value => {
  const options = getMonths()
  return _.find(options, { value: value })
}
