import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className="footer">
      <Container fluid={true}>
        <Row>
          <Col></Col>
        </Row>
      </Container>
    </footer>
  )
}
