import { takeLatest, fork, put, all, call } from 'redux-saga/effects'

import i18n from 'i18n'
import { immigrateCategoryEditData } from './slice'
import { immigrateCategoryAPI } from 'features/immigrate-category/api'
import { RequestUtils } from 'utils'

function* getData({ payload: { id, params = {} } }) {
  try {
    const requestParams = RequestUtils.getMapRequestParams(params)

    const response = yield call(immigrateCategoryAPI.getCategory, id, requestParams)

    const { data } = response

    yield put(immigrateCategoryEditData.getDataSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(immigrateCategoryEditData.getDataError(message))
  }
}

function* watchGetData() {
  yield takeLatest(immigrateCategoryEditData.getData, getData)
}

export function* dataSaga() {
  yield all([fork(watchGetData)])
}
