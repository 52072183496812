import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { JobAction } from './JobAction'
import { Employer } from './Employer'
import { JobInfo } from './JobInfo'
import { TableView } from 'components/Elements'
import { DateUtils } from 'utils'

export const JobsTable = ({ items, actionProps, loading, removeControlProps, ...props }) => {
  const { t } = useTranslation()

  const columns = [
    {
      accessorKey: 'title',
      id: 'title',
      header: t('job_title'),
      cell: info => <JobInfo data={info.row.original} />,
    },
    {
      accessorKey: 'company_name',
      id: 'company_name',
      header: t('company'),
      cell: info => {
        const { user } = info.row.original
        return user?.employer_profile?.company_name
      },
    },
    {
      accessorKey: 'user',
      id: 'user',
      header: t('employer'),
      cell: info => {
        const { user } = info.row.original
        return <Employer user={user} />
      },
    },
    {
      accessorKey: 'created_at',
      id: 'created_at',
      header: t('date_created'),
      cell: info => DateUtils.formatDate(info.getValue()),
    },
    {
      accessorKey: 'action',
      header: null,
      isPlaceholder: true,
      cell: info => (
        <JobAction
          data={info.row.original}
          {...actionProps}
          removeControlProps={removeControlProps}
        />
      ),
    },
  ]

  return (
    <>
      <TableView data={items} columns={columns} loading={loading} />
    </>
  )
}

JobsTable.propTypes = {
  items: PropTypes.array,
  actionProps: PropTypes.object,
  loading: PropTypes.bool,
  removeControlProps: PropTypes.object,
}
