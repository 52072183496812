import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'

import { MegaTagTitle } from 'components/Elements'
import { SpecificApplicationsContainer } from 'features/job/components/JobCandidates/Applications'
import { GeneralCandidatesFilter } from 'features/candidate/components/Candidates/Filters'
import { jobJobCandidatesApplicationsList } from 'features/job/store'
import { KEYWORDS_MIN_LENGTH } from 'consts/form'

const ApplicationsComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const queryParams = useMemo(() => {
    const { keywords, profile_certifications, ...restFilters } = props.filters

    let searchFilters = {}
    if (keywords && keywords.trim().length >= KEYWORDS_MIN_LENGTH) {
      searchFilters = {
        keywords,
        search_fields: ['fullname'],
      }
    }

    let certificationsFilters = {}
    if (profile_certifications && profile_certifications.trim().length >= 2) {
      certificationsFilters = {
        profile_certifications: profile_certifications.trim(),
      }
    }

    return {
      filters: {
        ...restFilters,
        ...searchFilters,
        ...certificationsFilters,
      },
    }
  }, [props.filters])

  const onFiltersChange = values => {
    dispatch(jobJobCandidatesApplicationsList.changeFilter({ ...values }))
  }

  return (
    <>
      <MegaTagTitle title={'applications'} />

      <div className={'mb-2'}>
        <GeneralCandidatesFilter
          defaultValues={props.filters}
          onFiltersChange={onFiltersChange}
          advancedFilter={false}
          withSearch
          layout={'card'}
        />
      </div>

      <SpecificApplicationsContainer queryParams={queryParams} />
    </>
  )
}

ApplicationsComponent.propTypes = {}

const mapStateToProps = state => {
  const { filters } = state.job.job.candidates.applications.list
  return {
    filters,
  }
}

export const Applications = connect(mapStateToProps)(ApplicationsComponent)
