import * as Yup from 'yup'

import { ValidationsUtils } from 'utils'

export const generalValidation = {
  first_name: Yup.string().required('field.error.required'),
  last_name: Yup.string().required('field.error.required'),
  phone: Yup.string()
    .required('field.error.required')
    .test('phone', 'field.error.invalid', ValidationsUtils.phoneFormat),
}
