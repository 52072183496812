import React from 'react'
import PropTypes from 'prop-types'

import s from './Icon.module.scss'

export const Icon = ({ children, ...props }) => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.5429 33.7298C37.9259 30.1477 40 25.316 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C23.3409 40 26.4905 39.1808 29.2589 37.7324C32.5306 40.749 36.6008 40.0555 39.5083 39.5601C39.6762 39.5315 39.8402 39.5036 40 39.477C37.5648 38.9917 35.1297 36.6155 34.5429 33.7298Z"
          fill="currentColor"
        />
        <text x="50%" y="50%" fill={'currentColor'} dominantBaseline="central" textAnchor="middle">
          {children}
        </text>
      </svg>
    </>
  )
}

Icon.propTypes = {
  children: PropTypes.node,
}
