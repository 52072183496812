import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button, Modal } from 'components/Elements'
import { usePopup } from 'hooks'
import { JobBulkInvite } from 'features/job/components/JobInvite'

export const BulkInviteControl = ({
  disabled = false,
  className = '',
  onSuccess,
  filters,
  job,
  blocked = false,
  jobSelect,
}) => {
  const { t } = useTranslation()

  const { visible, open, close } = usePopup()

  const onSubmitSuccess = data => {
    // close()

    onSuccess && onSuccess(data)
  }

  return (
    <>
      <div>
        <Button className={className} onClick={open} disabled={disabled} size={'sm'} width={'w-sm'}>
          {t('invite_all_matched_candidates')}
        </Button>
      </div>

      <Modal visible={visible} centered onClose={close} title={t('invite_all_matched_candidates')}>
        <JobBulkInvite
          job={job}
          filters={filters}
          onSuccess={onSubmitSuccess}
          jobSelect={jobSelect}
        />
      </Modal>
    </>
  )
}

BulkInviteControl.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onSuccess: PropTypes.func,
  filters: PropTypes.object,
  job: PropTypes.object,
  blocked: PropTypes.bool,
  jobSelect: PropTypes.bool,
}
