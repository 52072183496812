import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { Row, Col } from 'reactstrap'

import { Button, Alert } from 'components/Elements'
import { validationSchema } from './validation'
import { GeneralFormSection } from 'features/users/components/FormSections'
import { Combobox, FormFieldController } from 'components/FormElements'
import { useSelectOptions, useFormSubmit } from 'hooks'
import { CompanySection } from './Sections'

export const EditForm = ({ initialValues, withCTA, ...props }) => {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      employer_profile: {
        profile_position_id: null,
        company_name: '',
        industry_id: null,
        company_website: '',
        company_size_id: null,
        company_cta_text: '',
        company_cta_button_text: '',
        company_cta_button_link: '',
      },
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
    context: { withCTA },
  })

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit = async data => {
    try {
      const { email, phone, employer_profile, ...otherValues } = data

      const {
        company_cta_text,
        company_cta_button_text,
        company_cta_button_link,
        ...otherProfile
      } = employer_profile

      let ctaValues = {}

      if (withCTA) {
        ctaValues = {
          company_cta_text,
          company_cta_button_text,
          company_cta_button_link,
        }
      }

      const values = {
        phone: phone.replace(/[^0-9]/g, ''),
        ...otherValues,
        employer_profile: {
          ...otherProfile,
          ...ctaValues,
        },
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  const { getProfilePositions } = useSelectOptions()

  return (
    <>
      <FormProvider {...methods}>
        {submitError && <Alert type={'error'}>{submitError?.message || t('error')}</Alert>}

        <form onSubmit={handleSubmit(onSubmit)}>
          <GeneralFormSection />

          <Row>
            <Col md={6}>
              <FormFieldController
                name={'employer_profile.profile_position_id'}
                control={control}
                component={Combobox}
                id={'employer_profile.profile_position_id'}
                label={t('position')}
                placeholder={t('position')}
                options={getProfilePositions()}
                isSearchable={false}
                isDetermineValue
                normalize={option => (option !== null ? option.value : null)}
              />
            </Col>
          </Row>

          <CompanySection withCTA={withCTA} />

          <div className="mt-3">
            <div className="button-items">
              <Button disabled={isSubmitting} loading={isSubmitting} type={'submit'}>
                {t('save')}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

EditForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  withCTA: PropTypes.bool,
}
