import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { jobAPI } from '../../api'
import { JOB_REMOVE_REQUEST, JOB_REMOVE_SUCCESS, JOB_REMOVE_FAILED } from './actionTypes'

export const jobRemovePA = createPromiseAction(
  JOB_REMOVE_REQUEST,
  JOB_REMOVE_SUCCESS,
  JOB_REMOVE_FAILED
)()

function* remove(action) {
  try {
    const { id } = action.payload

    const response = yield call(jobAPI.deleteJob, id)
    const { data } = response

    yield put(jobRemovePA.success({}))

    resolvePromiseAction(action, { id })
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* removeSaga() {
  yield takeEvery(jobRemovePA.request, remove)
}
