import React from 'react'

import { usePanelLayoutContext } from 'contexts'

export const usePanelLayout = () => {
  const { config, setConfig, updateConfig, resetConfig, updateHeaderRightOptions } =
    usePanelLayoutContext()

  const setOptions = (data = {}) => {
    updateConfig(data)
  }

  const setHeaderRightOptions = (data = {}) => {
    updateHeaderRightOptions(data)
  }

  return {
    config,
    setConfig,
    setOptions,
    resetConfig,
    setHeaderRightOptions,
  }
}
