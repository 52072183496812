import React from 'react'
import PropTypes from 'prop-types'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export const BasicEditor = ({ config, ...props }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        link: {
          addTargetToExternalLinks: true,
        },
        ...config,
      }}
      {...props}
    />
  )
}

BasicEditor.propTypes = {
  config: PropTypes.object,
}
