import { combineReducers } from 'redux'

import data from './data/slice'
import users from './users/reducers'
import user from './user/reducers'
import edit from './edit/reducers'

const usersReducer = combineReducers({
  data,
  users,
  user,
  edit,
})

export default usersReducer
