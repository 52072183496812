import i18n from 'i18n'

export const createDefaultMessage = ({ candidate, job = {} }) => {
  const { first_name, last_name } = candidate

  const name = `${first_name} ${last_name}`

  const { title } = job

  return title
    ? i18n.t('job_invite.template_message', {
        name,
        position: title,
      })
    : i18n.t('job_invite.template_message.2', { name })
}
