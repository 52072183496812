import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { usersUsersMentorsList } from 'features/users/store'
import { OverlayDataShower, Pagination } from 'components/Elements'
import { GeneralUsersTable } from 'features/users/components/Users/Tables'
import { GeneralCandidatesFilter } from 'features/candidate/components/Candidates/Filters'
import { KEYWORDS_MIN_LENGTH } from 'consts/form'

const GeneralMentorsContainerComponent = ({ ...props }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(usersUsersMentorsList.resetDataState({}))
    }
  }, [])

  useEffect(() => {
    fetchList()
  }, [props.page, props.filters])

  const fetchList = () => {
    const { keywords, profile_certifications, ...restFilters } = props.filters

    let searchFilters = {}
    if (keywords && keywords.trim().length >= KEYWORDS_MIN_LENGTH) {
      searchFilters = {
        keywords,
        search_fields: ['fullname'],
      }
    }

    let certificationsFilters = {}
    if (profile_certifications && profile_certifications.trim().length >= 2) {
      certificationsFilters = {
        profile_certifications: profile_certifications.trim(),
      }
    }

    let params = {
      page: props.page,
      sort: [{ id: 'candidate_score', desc: true }],
      filters: {
        ...restFilters,
        ...searchFilters,
        ...certificationsFilters,
      },
    }

    dispatch(usersUsersMentorsList.getList({ params }))
  }

  const onPageChange = item => {
    dispatch(usersUsersMentorsList.setPage(item.selected + 1))
  }

  const onFiltersChange = values => {
    dispatch(usersUsersMentorsList.changeFilter({ ...values }))
  }

  const isLoading = useMemo(() => {
    return props.loading
  }, [props.loading])

  return (
    <Card>
      <CardBody>
        <div className={'mb-2'}>
          <GeneralCandidatesFilter
            defaultValues={props.filters}
            onFiltersChange={onFiltersChange}
            withSearch
          />
        </div>

        <OverlayDataShower isLoading={isLoading} isFailed={!!props.error} error={props.error}>
          <GeneralUsersTable
            keys={['avatar', 'name', 'email']}
            items={props.list}
            loading={isLoading}
          />

          <Pagination data={props.meta} onPageChange={onPageChange} className={'mt-2'} />
        </OverlayDataShower>
      </CardBody>
    </Card>
  )
}

GeneralMentorsContainerComponent.propTypes = {}

const mapStateToProps = state => {
  const { list, loading, error, meta, page, filters } = state.users.users.mentors.list
  return {
    list,
    loading,
    error,
    meta,
    page,
    filters,
  }
}

export const GeneralMentorsContainer = connect(mapStateToProps)(GeneralMentorsContainerComponent)
