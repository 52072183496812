import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Route, Routes } from 'react-router-dom'

import { QuestionsRoot } from '../faq'

export const ImmigrateRoot = () => {
  return (
    <Routes>
      <Route path={'faq/*'} element={<QuestionsRoot />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}

ImmigrateRoot.propTypes = {}
