import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Icon } from 'components/Elements'
import { FormFieldController, TextInput } from 'components/FormElements'

export const SearchFormSection = ({ field, keywordsMinLength = 1 }) => {
  const { t } = useTranslation()

  const { control, watch, getValues } = useFormContext()

  const fieldName = field?.name || 'keywords'
  const placeholder = field?.placeholder || t('search')

  const [keywordsHint, setKeywordsHint] = useState(false)

  const onKeywordHintVisible = value => {
    if (value && value.length < keywordsMinLength) {
      setKeywordsHint(true)
    } else {
      setKeywordsHint(false)
    }
  }

  const onDebouncedChange = useCallback(debounce(onKeywordHintVisible, 700), [onKeywordHintVisible])

  useEffect(() => {
    onKeywordHintVisible(getValues(fieldName))

    const subscription = watch((values, { name }) => {
      if (!name || name === fieldName) {
        onDebouncedChange(values?.[fieldName])
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <FormFieldController
      name={fieldName}
      control={control}
      component={TextInput}
      id={fieldName}
      placeholder={placeholder}
      leftAccessory={
        <div className={'form-left-accessory'}>
          <Icon name={'search'} className={'font-size-16'} />
        </div>
      }
      hintText={keywordsHint ? t('min-characters.count', { count: keywordsMinLength }) : null}
    />
  )
}
