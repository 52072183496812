import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: null,
  loading: false,
  error: null,
}

const staticSelectsSlice = createSlice({
  name: 'staticSelects',
  initialState,
  reducers: {
    getData(state) {
      state.loading = true
      state.error = null
    },
    getDataSuccess(state, action) {
      state.loading = false
      state.data = action.payload.data
    },
    getDataError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    cleanState() {
      return initialState
    },
  },
})

export const staticSelects = staticSelectsSlice.actions

export const selectStaticSelectsOptions = state => state.static.selects.data

export default staticSelectsSlice.reducer
