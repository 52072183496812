import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Label, Input } from 'reactstrap'
import classNames from 'classnames'

import { FieldError } from '../FieldError'

export const CheckboxField = ({
  id,
  label,
  reference,
  children,
  normalize,
  color = 'primary',
  wrapClass = 'mb-3',
  size,
  value,
  meta,
  ...props
}) => {
  const { error } = meta

  const { t } = useTranslation()
  const showError = Boolean(error)

  //eslint-disable-next-line
  const onChange = event => {
    const checked = normalize ? normalize(event.target.checked) : event.target.checked

    if (props.onChange) {
      props.onChange(checked)
    }
  }

  const renderLabel = label => {
    if (label) {
      if (typeof label === 'string') {
        return t(label)
      } else return label
    } else return <></>
  }

  return (
    <div
      className={classNames({
        [wrapClass]: wrapClass,
      })}
    >
      <div
        className={classNames('form-check', {
          'is-invalid': showError,
          [`form-check-${color}`]: color,
          [`form-check_size_${size}`]: size,
        })}
      >
        <div className={classNames('form-check-wrap', { 'is-invalid': showError })}>
          <Input
            id={id}
            type="checkbox"
            className={classNames('form-check-input', {
              '': showError,
            })} // 'is-invalid'
            checked={value}
            onChange={e => onChange(e)}
            disabled={props.disabled}
          />
          {label && (
            <Label className="form-check-label" htmlFor={id}>
              {renderLabel(label)}
            </Label>
          )}
        </div>

        <FieldError showError={showError} error={error} translateParams={props.translateParams} />
      </div>
    </div>
  )
}

CheckboxField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.any,
  reference: PropTypes.any,
  children: PropTypes.any,
  normalize: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  meta: PropTypes.object,
  onChange: PropTypes.func,
  translateParams: PropTypes.object,
  color: PropTypes.string,
  wrapClass: PropTypes.string,
  size: PropTypes.string,
}
