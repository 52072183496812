import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { authAPI } from '../../api'
import { authData } from '../data/slice'
import { AUTH_LOGIN_FAILED, AUTH_LOGIN_REQUEST, AUTH_LOGIN_SUCCESS } from './actionTypes'

export const authLoginPA = createPromiseAction(
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILED
)()

function* login(action) {
  try {
    const response = yield call(authAPI.login, action.payload)
    const { data } = response

    yield put(authLoginPA.success({}))

    yield put(authData.setData({ isLoggedIn: true, token: data?.data?.token }))

    resolvePromiseAction(action, {})
  } catch (error) {
    if (!error.response) {
      throw error
    }
    rejectPromiseAction(action, error.response.data)
  }
}

export function* loginSaga() {
  yield takeEvery(authLoginPA.request, login)
}
