import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { CurrencyFormat } from 'components/Elements'

export const JobPayRangeDisplay = ({ data = {}, className = '' }) => {
  const { t } = useTranslation()

  const { pay_range_min, pay_range_max } = data

  return (
    <span className={className}>
      {pay_range_min !== null || pay_range_max !== null ? (
        <>
          {pay_range_min !== null && (
            <CurrencyFormat value={pay_range_min} suffix={`/${t('yr')}`} />
          )}

          {pay_range_max !== null && (
            <>
              {pay_range_min !== null ? ' - ' : `${t('to')}: `}
              <CurrencyFormat value={pay_range_max} suffix={`/${t('yr')}`} />
            </>
          )}
        </>
      ) : (
        '-'
      )}
    </span>
  )
}

JobPayRangeDisplay.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
}
