import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'

import { Header } from './Header'
import { Sidebar } from './Sidebar'
import { Footer } from './Footer'
import { PanelLayoutProvider } from 'contexts'

export const PanelLayout = () => {
  const [drawerVisible, setDrawerVisible] = useState(false)

  return (
    <PanelLayoutProvider>
      <div id="layout-wrapper">
        <Header onDrawerOpen={() => setDrawerVisible(true)} />
        <Sidebar drawerVisible={drawerVisible} onDrawerClose={() => setDrawerVisible(false)} />
        <div className="main-content">
          <Outlet />
        </div>
        <Footer />
      </div>
    </PanelLayoutProvider>
  )
}

PanelLayout.propTypes = {}
