import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Card, Row, Col } from 'reactstrap'
import classNames from 'classnames'

import { OverlayBlockLoading, ActionIcon } from 'components/Elements'
import { FieldError } from 'components/FormElements'

export const Attachment = ({
  name,
  namePlaceholder = '',
  size,
  remove,
  download,
  onRemove,
  onDownload,
  loading,
  card = true,
  translateParams,
  error,
}) => {
  const { t } = useTranslation()

  const showError = Boolean(error)

  const render = () => (
    <>
      <div
        className={classNames('d-flex', {
          'is-invalid': showError,
        })}
      >
        <Col>
          <Row className={'d-flex align-items-center'}>
            <Col className="col-auto">
              <div className="avatar-sm">
                <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                  <i className="bx bxs-file" />
                </span>
              </div>
            </Col>
            <Col>
              <span className="text-gray font-weight-bold">{name ? name : namePlaceholder}</span>
              {size && (
                <p className="mb-0">
                  <strong>{size}</strong>
                </p>
              )}
            </Col>
          </Row>
        </Col>
        <div className={'ms-2 d-flex flex-column'}>
          {remove && (
            <ActionIcon name={'remove'} onClick={onRemove} disabled={loading} size={'sm'} />
          )}

          {download && (
            <ActionIcon
              name={'download'}
              onClick={onDownload}
              disabled={loading}
              className={'mt-1'}
              size={'sm'}
            />
          )}
        </div>
      </div>

      <FieldError showError={showError} error={error} translateParams={translateParams} />
    </>
  )
  return (
    <>
      <OverlayBlockLoading isLoading={loading}>
        {card ? (
          <Card className="mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
            <div className="p-2">{render()}</div>
          </Card>
        ) : (
          render()
        )}
      </OverlayBlockLoading>
    </>
  )
}

OverlayBlockLoading.propTypes = {
  name: PropTypes.string,
  namePlaceholder: PropTypes.any,
  size: PropTypes.any,
  card: PropTypes.bool,
  remove: PropTypes.bool,
  download: PropTypes.bool,
  onRemove: PropTypes.func,
  onDownload: PropTypes.func,
  loading: PropTypes.bool,
  translateParams: PropTypes.any,
  error: PropTypes.any,
}
