import _ from 'lodash'

import { CURRENCY, GENERAL_CURRENCY } from 'consts/currency'

export const getCurrencies = () => {
  return [
    {
      label: CURRENCY.CAD,
      value: CURRENCY.CAD,
      symbol: '$',
    },
  ]
}

export const getCurrency = value => {
  const options = getCurrencies()
  return _.find(options, { value: value })
}

export const getGeneralCurrency = () => {
  return getCurrency(GENERAL_CURRENCY)
}
