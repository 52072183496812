import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { IconArrowLeft } from '@tabler/icons'

import s from './PageHeader.module.scss'
import { useTranslation } from 'react-i18next'
import { Anchor } from '../../../Elements'

export const PageHeader = ({ title, headerLeft, headerRight, back, onBack, backTitle }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const renderTitle = () => <h4 className="mb-0 font-size-18">{title}</h4>

  const handleBack = e => {
    e.preventDefault()

    if (onBack) {
      onBack()
    } else {
      navigate(-1)
    }
  }

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className={s.pageHeader__left}>
            <div className={'d-flex'}>
              {back && (
                <Anchor
                  to={'#'}
                  className="font-size-18 fw-medium"
                  leftIcon={<IconArrowLeft size={20} />}
                  onClick={handleBack}
                  color={'black'}
                >
                  {t('back')}
                </Anchor>
              )}

              {title && renderTitle()}
            </div>

            {!!headerLeft && headerLeft}
          </div>

          {!!headerRight && <div className={s.pageHeader__right}>{headerRight}</div>}
        </div>
      </Col>
    </Row>
  )
}

PageHeader.propTypes = {
  title: PropTypes.any,
  headerLeft: PropTypes.any,
  headerRight: PropTypes.any,
  back: PropTypes.bool,
  onBack: PropTypes.func,
  backTitle: PropTypes.any,
}
