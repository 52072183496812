import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { JobRecommendControl, JobInviteControl } from './Controls'

export const Action = ({
  user = {},
  config = {},
  jobRecommendControlProps = {},
  jobInviteControlProps = {},
}) => {
  const { t } = useTranslation()

  return (
    <div className={'d-flex gap-2 font-size-14 justify-content-end text-nowrap'}>
      {config.jobRecommend && (
        <JobRecommendControl candidate={user} {...jobRecommendControlProps} />
      )}

      {config.jobInvite && <JobInviteControl candidate={user} {...jobInviteControlProps} />}
    </div>
  )
}

Action.propTypes = {
  user: PropTypes.object,
  config: PropTypes.object,
  jobRecommendControlProps: PropTypes.object,
  jobInviteControlProps: PropTypes.object,
}
