import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FormFeedback } from 'reactstrap'
import classNames from 'classnames'
import { FormUtils } from 'utils'

export const FieldError = ({ showError, error, translateParams, className }) => {
  const { t } = useTranslation()

  return (
    <FormFeedback
      invalid={showError ? error : null}
      className={classNames({ [className]: className })}
    >
      {FormUtils.getErrorMessage(error, {
        t,
        translateParams,
      })}
    </FormFeedback>
  )
}

FieldError.propTypes = {
  showError: PropTypes.bool,
  error: PropTypes.any,
  translateParams: PropTypes.object,
  className: PropTypes.string,
}
