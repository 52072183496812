import React from 'react'
import PropTypes from 'prop-types'

import { TagBadge } from 'components/Elements'
import { tariffHelper } from 'features/billing/helpers/tariff'

export const SubscriptionBadge = ({ alias, size = 'md' }) => {
  const tariff = tariffHelper.general.getTariff(alias)

  return (
    <>
      <TagBadge size={size}>{tariff?.name}</TagBadge>
    </>
  )
}

SubscriptionBadge.propTypes = {
  alias: PropTypes.string,
  size: PropTypes.string,
}
