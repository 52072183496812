import { takeLatest, fork, put, all, call } from 'redux-saga/effects'

import i18n from 'i18n'
import { statisticsDashboardData } from './slice'
import { statisticsAPI } from '../../../api'

function* getData({ payload: { params = {} } }) {
  try {
    const response = yield call(statisticsAPI.getDashboardStatistics, params)

    const { data } = response

    yield put(statisticsDashboardData.getDataSuccess(data))
  } catch (error) {
    const message = error.response?.data?.message || i18n.t('error')
    yield put(statisticsDashboardData.getDataError(message))
  }
}

function* watchGetData() {
  yield takeLatest(statisticsDashboardData.getData, getData)
}

export function* dataSaga() {
  yield all([fork(watchGetData)])
}
