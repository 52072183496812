import React from 'react'
import PropTypes from 'prop-types'
import { FormProvider } from 'react-hook-form'

import { useFilterForm } from 'features/filter/hooks'
import { FilterShell } from 'features/filter/components'
import { JOBS_FILTERS_INITIAL_VALUES } from 'features/job/consts/filters'
import { FilterForm } from '../FilterForm'

export const GeneralJobsFilter = ({ defaultValues, onFiltersChange, layout }) => {
  const { methods, isApplied, onReset } = useFilterForm({
    config: {
      defaultValues: {
        ...defaultValues,
      },
    },
    onFiltersChange,
    initialValues: {
      ...JOBS_FILTERS_INITIAL_VALUES,
    },
  })

  return (
    <FormProvider {...methods}>
      <FilterShell variant={layout} reset={isApplied} onReset={onReset}>
        <FilterForm />
      </FilterShell>
    </FormProvider>
  )
}

GeneralJobsFilter.propTypes = {
  onFiltersChange: PropTypes.func,
  defaultValues: PropTypes.object,
}
