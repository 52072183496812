import React from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'

import { BasicLayout } from '../BasicLayout'

export const AuthLayout = () => {
  const { t } = useTranslation()

  return (
    <BasicLayout>
      <div className="account-pages">
        <Container>
          <Outlet />
        </Container>
      </div>
    </BasicLayout>
  )
}

AuthLayout.propTypes = {}
