import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

export const ButtonLink = ({
  children,
  color = 'primary',
  size = 'lg',
  rounded = true,
  className = '',
  ...props
}) => {
  return (
    <Link
      className={classNames('btn w-lg', {
        [`btn-${color}`]: color,
        [`btn-${size}`]: size,
        [`btn-rounded`]: rounded,
        [className]: className,
      })}
      tabIndex="-1"
      role="button"
      {...props}
    >
      {children}
    </Link>
  )
}

ButtonLink.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  size: PropTypes.string,
  rounded: PropTypes.bool,
  className: PropTypes.string,
}
