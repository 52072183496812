import axios from 'axios'

class ImmigrateCategoryAPI {
  getCategory = async (id, params) => {
    return axios.get(`api/admin/immigrate-categories/${id}`, {
      params,
    })
  }

  updateCategory = async (id, params) => {
    return axios.put(`api/admin/immigrate-categories/${id}`, params)
  }

  deleteCategory = async id => {
    return axios.delete(`api/admin/immigrate-categories/${id}`)
  }
}

export const immigrateCategoryAPI = new ImmigrateCategoryAPI()
