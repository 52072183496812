import React from 'react'
import PropTypes from 'prop-types'
import { FormProvider } from 'react-hook-form'

import { useFilterForm } from 'features/filter/hooks'
import { FilterShell } from 'features/filter/components'
import { CANDIDATES_FILTERS_INITIAL_VALUES } from 'features/candidate/consts/filters'
import { FilterForm } from '../FilterForm'

export const GeneralCandidatesFilter = ({
  defaultValues,
  onFiltersChange,
  withSearch = false,
  layout,
  renderRightSection,
}) => {
  const { methods, isApplied, onReset } = useFilterForm({
    config: {
      defaultValues: {
        ...defaultValues,
      },
    },
    onFiltersChange,
    initialValues: {
      ...CANDIDATES_FILTERS_INITIAL_VALUES,
    },
  })

  return (
    <FormProvider {...methods}>
      <FilterShell
        variant={layout}
        reset={isApplied}
        onReset={onReset}
        rightSection={renderRightSection && renderRightSection({ isApplied })}
      >
        <FilterForm withSearch={withSearch} />
      </FilterShell>
    </FormProvider>
  )
}

GeneralCandidatesFilter.propTypes = {
  onFiltersChange: PropTypes.func,
  defaultValues: PropTypes.object,
  layout: PropTypes.oneOf(['default', 'card']),
  renderRightSection: PropTypes.func,
}
