import { combineReducers } from 'redux'

import data from './data/slice'
import jobs from './jobs/reducers'
import candidates from './candidates/reducers'
import edit from './edit/reducers'
import job from './job/reducers'

const jobReducer = combineReducers({
  data,
  jobs,
  candidates,
  edit,
  job,
})

export default jobReducer
