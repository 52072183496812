import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { UserSubscriptionForm } from '../UserSubscriptionForm'
import { usersSubscriptionUpdatePA } from 'features/users/store'
import { useNotify } from 'hooks'

export const UserSubscriptionUpdate = ({ onSuccess, user }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const onSubmit = async values => {
    const id = user?.id

    const params = {
      ...values,
    }

    const data = await dispatch(usersSubscriptionUpdatePA.request({ id, params }))

    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess(data)
  }

  return (
    <>
      <UserSubscriptionForm
        initialValues={{
          alias: user?.subscription?.tariff?.alias || null,
        }}
        credits={user?.subscription?.credits || null}
        onSubmit={onSubmit}
      />
    </>
  )
}

UserSubscriptionUpdate.propTypes = {
  onSuccess: PropTypes.func,
  user: PropTypes.object,
}
