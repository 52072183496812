import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { EditForm } from './EditForm'
import { usersEditEmployerUpdatePA } from 'features/users/store'
import { useNotify } from 'hooks'
import { FormatUtils } from 'utils'
import { useTariffStaticOptions } from 'features/billing/hooks'
import { CompanyBannerManager } from './CompanyBannerManager'

export const EmployerProfileEdit = () => {
  const { t } = useTranslation()

  const { data: account } = useSelector(state => state.users.edit.data)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { showNotification } = useNotify()

  const { isEnterprise } = useTariffStaticOptions()

  const isEnterpriseTariff = isEnterprise(account?.subscription?.tariff?.alias)

  const onSubmit = async values => {
    await dispatch(usersEditEmployerUpdatePA.request({ id: account?.id, params: values }))
    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })
    navigate(`/users/${account?.id}`)
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    })
  }

  const initialValues = useMemo(() => {
    let result = {}
    if (account) {
      const { employer_profile, first_name, last_name, phone, email } = account

      const {
        profile_position,
        industry,
        company_size,
        filled,
        company_banner,
        company_logo,
        ...otherProfile
      } = employer_profile

      result = {
        first_name,
        last_name,
        email,
        phone: FormatUtils.formatPhone(phone) || '',
        employer_profile: {
          ...otherProfile,
          profile_position_id: profile_position?.id || null,
          industry_id: industry?.id || null,
          company_size_id: company_size?.id || null,
        },
      }
    }

    return result
  }, [])

  return (
    <>
      <Card>
        <CardBody>
          {isEnterpriseTariff && (
            <div className={'mb-3'}>
              <CompanyBannerManager user={account} />
            </div>
          )}

          <EditForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            withCTA={isEnterpriseTariff}
          />
        </CardBody>
      </Card>
    </>
  )
}

EmployerProfileEdit.propTypes = {}
