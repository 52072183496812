import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'

import { FileButtonControl } from 'components/Controls'
import { Attachment } from 'components/Elements'
import { FILE_MAX_SIZE } from 'consts/core'
import { useNotify } from 'hooks'
import { FileUtils, ValidationsUtils } from 'utils'

export const AttachmentManagerControl = ({
  value = null,
  onUpload,
  onRemove,
  onDownload,
  accept = '',
  buttonLabel,
  loading = false,
}) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [pickedFile, setPickedFile] = useState(null)

  const onAdd = file => {
    const isValid = ValidationsUtils.fileMaxSize(file, FILE_MAX_SIZE)

    if (!isValid) {
      const message = t('file.max_size.error', {
        text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
      })
      showNotification({ type: 'error', message: message })

      return
    }

    setPickedFile(file)

    onUpload(file)
  }

  useEffect(() => {
    if (value) {
      setPickedFile(null)
    }
  }, [value])

  const file = useMemo(() => {
    if (value) {
      return {
        name: value?.file_name,
        size: value?.size,
      }
    } else if (pickedFile) {
      return {
        name: pickedFile?.name,
        size: FileUtils.formatBytes(pickedFile?.size),
      }
    }

    return null
  }, [value, pickedFile])

  const handleRemove = () => {
    if (value) {
      onRemove()
    } else {
      setPickedFile(null)
    }
  }

  return (
    <>
      {file && (
        <Row>
          <Col md={6} lg={4}>
            <Attachment
              name={file?.name}
              size={file?.size}
              remove
              download={value}
              onDownload={() => onDownload(value)}
              onRemove={handleRemove}
              loading={loading}
            />
          </Col>
        </Row>
      )}

      {!file && <FileButtonControl label={buttonLabel} accept={accept} onChange={onAdd} />}
    </>
  )
}

FileButtonControl.propTypes = {
  value: PropTypes.any,
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
  onDownload: PropTypes.func,
  buttonLabel: PropTypes.node,
  accept: PropTypes.string,
  loading: PropTypes.bool,
}
