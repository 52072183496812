import React from 'react'
import { ConfirmModal } from 'components/Elements'
import { useConfirmPopup } from 'hooks'
import PropTypes from 'prop-types'

export const ConfirmationControl = ({ onConfirm, render }) => {
  const {
    visible,
    open,
    close,
    onConfirm: onPopupConfirm,
  } = useConfirmPopup({ confirm: onConfirm })

  return (
    <>
      {render({
        onOpen: open,
      })}

      <ConfirmModal visible={visible} onClose={close} onConfirm={onPopupConfirm} />
    </>
  )
}

ConfirmationControl.propTypes = {
  render: PropTypes.func,
  onConfirm: PropTypes.func,
}
