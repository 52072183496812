import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { immigrateQuestionEditSubmitPA } from 'features/immigrate-question/store'
import { useNotify } from 'hooks'
import { QuestionForm } from '../../QuestionForm'

export const QuestionEditContainer = ({ onSuccess }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { showNotification } = useNotify()

  const { data: question } = useSelector(state => state.immigrateQuestion.edit.data)

  const onSubmit = async values => {
    const data = await dispatch(
      immigrateQuestionEditSubmitPA.request({ id: question?.id, params: values })
    )

    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess && onSuccess(data)
  }

  return (
    <>
      <QuestionForm initialValues={{ ...question }} onSubmit={onSubmit} />
    </>
  )
}

QuestionEditContainer.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}
