import React from 'react'
import { useSelector } from 'react-redux'

export const useJob = () => {
  const { data: job } = useSelector(state => state.job.data)

  return {
    job,
  }
}
