import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Alert, OverlayBlockLoading } from 'components/Elements'

export const OverlayDataShower = ({
  isLoading,
  isFailed = false,
  error,
  children,
  errorContainerClass = null,
  ...rest
}) => {
  const { t } = useTranslation()

  if (isFailed) {
    return (
      <div className={errorContainerClass}>
        <Alert type={'error'}>{error || t('error')}</Alert>
      </div>
    )
  }

  return (
    <>
      <OverlayBlockLoading isLoading={isLoading}>{children}</OverlayBlockLoading>
    </>
  )
}

OverlayDataShower.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired,
  children: PropTypes.node,
  errorContainerClass: PropTypes.string,
  error: PropTypes.string,
}
