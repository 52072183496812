import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Action } from './Action'
import { Header } from './Header'
import { Questions } from '../Questions'
import { NoDataTextMessage } from 'components/Elements'

export const Sections = ({
  items,
  children,
  action,
  addControlProps,
  editControlProps,
  removeControlProps,
  onQuestionEditSuccess,
  onQuestionRemoveSuccess,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <div className={'d-flex flex-column gap-5'}>
      {items.map(item => {
        const { id, title, questions } = item

        return (
          <div key={id} className={'d-flex flex-column flex-grow-1'}>
            <div className={'d-flex flex-grow-1 gap-3'}>
              <Header
                title={title}
                rightSection={
                  action ? (
                    <div className={'align-self-start'}>
                      <Action
                        data={item}
                        addControlProps={addControlProps}
                        editControlProps={editControlProps}
                        removeControlProps={removeControlProps}
                      />
                    </div>
                  ) : null
                }
              />
            </div>

            <>
              {questions && !!questions.length ? (
                <Questions
                  items={questions}
                  action
                  editControlProps={{
                    onSuccess: question => onQuestionEditSuccess(id, question),
                  }}
                  removeControlProps={{
                    onSuccess: question => onQuestionRemoveSuccess(id, question),
                  }}
                />
              ) : (
                <NoDataTextMessage variant={'empty'} />
              )}
            </>
          </div>
        )
      })}
    </div>
  )
}

Sections.propTypes = {
  items: PropTypes.array,
  action: PropTypes.bool,
  addControlProps: PropTypes.object,
  editControlProps: PropTypes.object,
  removeControlProps: PropTypes.object,
  onQuestionEditSuccess: PropTypes.func,
  onQuestionRemoveSuccess: PropTypes.func,
}
