import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { IconPencil } from '@tabler/icons'
import { useUserAccount } from 'features/users/hooks'

import { Anchor } from 'components/Elements'

export const Edit = () => {
  const { t } = useTranslation()

  const { account } = useUserAccount()

  return (
    <Anchor
      to={`/users/${account?.id}/edit`}
      className="font-size-18 fw-medium"
      leftIcon={<IconPencil size={20} />}
      color={'primary'}
    >
      {t('edit')}
    </Anchor>
  )
}

Edit.propTypes = {}
