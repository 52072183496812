import React from 'react'
import toastr from 'toastr'

export const useNotify = () => {
  const showNotification = ({ type, message }) => {
    if (type === 'success') {
      toastr.success(message)
    } else if (type === 'error') {
      toastr.error(message)
    }
  }

  return {
    showNotification,
  }
}
