import { all, fork } from 'redux-saga/effects'

import LayoutSaga from './layout/saga'
import { authSaga } from 'features/auth/store/sagas'
import { userSaga } from 'features/user/store/sagas'
import { panelSaga } from 'features/panel/store/sagas'
import { staticSaga } from 'features/static/store/sagas'
import { jobSaga } from 'features/job/store/sagas'
import { usersSaga } from 'features/users/store/sagas'
import { statisticsSaga } from 'features/statistics/store/sagas'
import { immigrateCategorySaga } from 'features/immigrate-category/store/sagas'
import { immigrateQuestionSaga } from 'features/immigrate-question/store/sagas'
import { immigrateSaga } from 'features/immigrate/store/sagas'
import { jobApplicationSaga } from 'features/job-application/store/sagas'

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(authSaga),
    fork(userSaga),
    fork(panelSaga),
    fork(staticSaga),
    fork(jobSaga),
    fork(usersSaga),
    fork(statisticsSaga),
    fork(immigrateCategorySaga),
    fork(immigrateQuestionSaga),
    fork(immigrateSaga),
    fork(jobApplicationSaga),
  ])
}
