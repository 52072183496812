import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'

export const NumberFormat = ({ value = '', ...props }) => {
  const { t } = useTranslation()

  return (
    <NumericFormat
      value={value}
      displayType="text"
      thousandSeparator=","
      decimalSeparator="."
      {...props}
    />
  )
}

NumberFormat.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
