import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Table as StapTable } from 'reactstrap'
import { NumberFormat } from 'components/Elements'

export const Table = ({ items = [] }) => {
  const { t } = useTranslation()

  return (
    <div className="table-responsive mb-4">
      <StapTable className={`table mb-0 table-bordered`}>
        <tbody>
          {items.map(({ label, value }, i) => (
            <tr key={i}>
              <th scope="row">{label}:</th>
              <td>
                <NumberFormat value={value} />
              </td>
            </tr>
          ))}
        </tbody>
      </StapTable>
    </div>
  )
}

Table.propTypes = {
  items: PropTypes.array,
}
