import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

export const Anchor = ({
  children,
  color = 'black',
  className = '',
  leftIcon,
  disabled,
  ...props
}) => {
  let style = null

  if (disabled) {
    style = { pointerEvents: 'none', opacity: 0.65 }
  }

  return (
    <Link
      className={classNames('', {
        [`text-${color}`]: color,
        [className]: className,
      })}
      style={style}
      {...props}
    >
      {leftIcon && <span className={'align-text-bottom me-2'}>{leftIcon}</span>}
      {children}
    </Link>
  )
}

Anchor.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  className: PropTypes.string,
  leftIcon: PropTypes.any,
  disabled: PropTypes.bool,
}
